import instance from ".";

export const getPatients = async (params: {
  projectId: string;
  typeId?: number;
  page?: number;
  display?: number;
  name?: string;
  sort?: string;
  searchCondition?: object;
}) => {
  const result = await instance.get(`/patients`, { params });
  return result.data;
};

export const getPatient = async (id: string) => {
  const result = await instance.get(`/patients/${id}`);
  return result.data;
};

export const createPatient = async (data: {
  projectId: string;
  name: string;
  type: string;
  birthday: string;
  sex: string;
  note: string;
  values: any;
}) => {
  const result = await instance.post(`/patients`, data);
  return result.data;
};
export const deletePatient = async (id: number) => {
  try {
    const result = await instance.delete(`/patients/${id}`);
    return result;
  } catch (err: any) {
    if (err.response) {
      alert(err.response.data.message);
    }
  }
};

export const editPatient = (data: {
  id: number;
  projectId: number;
  name: string;
  type: string;
  birthday: string;
  sex: string;
  note: string;
  values: any;
}) => {
  return instance.put(`/patients/${data.id}`, data);
};

export const checkPatientDuplicated = async (params: {
  projectId: string;
  name: string;
  birthday: string;
}) => {
  const result = await instance.get(`/patients/check`, { params });
  return result.data;
};
