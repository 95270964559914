import { useEffect } from "react";
import { useQuery } from "react-query";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { getSections } from "apis/section";
import { ReactComponent as ThreeDots } from "assets/icons/ThreeDots.svg";

import Modal from "components/organisms/Modal";
import {
  Sections,
  BtnSection,
  SectionLink,
  BtnMore,
} from "components/atoms/section";
import { BtnBlack } from "components/atoms/button";
import Dropdown from "components/organisms/Dropdown";
import MenuSection from "components/templates/admin/Sections/MenuSection";
import ModalSection from "components/templates/admin/Sections/ModalSection";
import { SectionType } from "types";

const AdminSections = () => {
  const navigate = useNavigate();
  const { projectId = "", sectionId } = useParams();
  const { isLoading, data } = useQuery(["getSections", projectId], () =>
    getSections({ projectId })
  );

  useEffect(() => {
    if (!sectionId && data?.length > 0) navigate(`${data[0].id}`);
  }, [sectionId, data]);

  if (isLoading) return <></>;
  return (
    <>
      <Title>섹션 목록</Title>
      <Sections>
        {data.map((section: SectionType) => {
          const { id, name } = section;
          return (
            <SectionLink key={id} to={`${id}`}>
              <BtnSection>
                {name}
                <Dropdown
                  activator={(onToggle) => (
                    <BtnMore onClick={onToggle}>
                      <ThreeDots />
                    </BtnMore>
                  )}
                  content={<MenuSection data={section} />}
                />
              </BtnSection>
            </SectionLink>
          );
        })}
        <Modal
          activator={(onOpen) => (
            <BtnBlack onClick={onOpen}>섹션 추가 +</BtnBlack>
          )}
          content={(onClose) => <ModalSection onClose={onClose} />}
        />
      </Sections>
      <Outlet />
    </>
  );
};

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-top: 45px;
  margin-bottom: 15px;
`;

export default AdminSections;
