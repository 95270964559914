import { BtnPink, BtnWhite } from "components/atoms/button";
import { SpaceBetween } from "components/atoms/common";
import { Item, Items, Value, ItemsTitle } from "components/atoms/item";
import { Input } from "components/atoms/input";
import useInputs from "hooks/useInputs";
import styled from "styled-components";
import ResearcherType from "types/researcher";
import { patchPWResearcher } from "apis/researcher";
const ModalPasswordChange = ({
  onClose,
  data,
}: {
  onClose: () => void;
  data: ResearcherType;
}) => {
  const [state, onChange] = useInputs({ ...data });
  const { id, password } = state;
  const copy = async () => {
    await navigator.clipboard.writeText(state.password);
    alert("복사되었습니다.");
  };
  const handleSubmit = async () => {
    const result = await patchPWResearcher(password, id);

    onClose();
  };
  return (
    <Container>
      <Items>
        <ItemsTitle>비밀번호 변경</ItemsTitle>
        <Item size={1}>
          <Value>
            <Input name="password" onChange={onChange} />
            <BtnCopy onClick={copy}>복사</BtnCopy>
          </Value>
        </Item>
      </Items>
      <SpaceBetween>
        <BtnCancel onClick={onClose}>취소</BtnCancel>
        <BtnPink
          large
          onClick={() => {
            if (window.confirm("비밀번호를 변경하시겠습니까?")) {
              handleSubmit();
            }
          }}
        >
          저장
        </BtnPink>
      </SpaceBetween>
    </Container>
  );
};

const Container = styled.div`
  padding: 30px 40px;
  background: #fff;
  max-width: 400px;
`;

const BtnCopy = styled.button`
  padding: 5px 8px;
  position: absolute;
  right: 18px;
  top: 18px;
  background: #fafafc;
  border: 1px solid #c7c7c7;
  border-radius: 2px;
`;
const BtnCancel = styled(BtnWhite)`
  width: 90px;
`;
export default ModalPasswordChange;
