import PatientType from "types/patient";
import { geteGFRU25_Cr_CysC } from "./common/geteGFRU25";

const eGFRU25CrCystatinC = {
  id: 17,
  name: "eGFR (U25-Cr/CysC)",
  datas: ["height", "blood_creatinine", "CystatinC"],
  formula: (
    { height, blood_creatinine, CystatinC }: any,
    { birthday, sex }: PatientType
  ) => {
    if (!height || !blood_creatinine || !CystatinC || !birthday || !sex) {
      return;
    }
    const age = (Date.now() - +new Date(birthday)) / 31557600000;
    return geteGFRU25_Cr_CysC(
      { height, age, blood_creatinine, CystatinC },
      sex
    );
  },
  help: `eGFR U25 (Cr)과 eGFR (Cystatin C)의 평균값`,
};

export default eGFRU25CrCystatinC;
