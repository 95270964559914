import { Tab, TabConatiner, TabLink, TabLine } from "components/atoms/tab";

const LinkTabs = ({
  data,
}: {
  data: { name: string; to: string; end?: boolean }[];
}) => {
  return (
    <TabConatiner>
      {data.map(({ name, to, end }) => (
        <TabLink to={to} end={end} key={to}>
          <Tab>{name}</Tab>
        </TabLink>
      ))}
      <TabLine />
    </TabConatiner>
  );
};

export default LinkTabs;
