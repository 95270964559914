import styled from "styled-components";
import { useQuery } from "react-query";
import { Link, Outlet, useParams, useLocation } from "react-router-dom";

import Top from "./SideBarTop";
import { ReactComponent as AddProejct } from "assets/icons/AddProject.svg";
import { ReactComponent as Arrow } from "assets/icons/Arrow.svg";
import { getProjects } from "apis/project";
import { ProjectType } from "types";
import { getTokenPayload } from "utils/user";
import { getMyInfo } from "apis/user";

const SideBar = () => {
  const { data, isLoading } = useQuery(["getProjects"], () => getProjects());
  const { projectId } = useParams();
  const location = useLocation();
  const { isSuper, isProjectSuper } = getTokenPayload();
  const isResearcher = !isSuper && !isProjectSuper;

  const { data: myInfo, isLoading: isLoadingMyInfo } = useQuery(
    "getMyInfo",
    getMyInfo
  );

  if (isLoadingMyInfo) return <></>;

  return (
    <Layout>
      <Container>
        <Top />
        {!isResearcher && (
          <Link to="/admin/project">
            <MainItem>
              <ItemTitle>
                <AddProejct />
                프로젝트 관리
              </ItemTitle>
              <Arrow />
            </MainItem>
          </Link>
        )}
        {!isLoading &&
          data.map(({ id, name }: ProjectType) => (
            <Item isActive={projectId === id + ""} key={id}>
              <Link to={`/project/${id}`}>{name}</Link>
            </Item>
          ))}
        {myInfo.project_id === 675 && (
          <Item
            isActive={location.pathname.includes("sample-management")}
            key={676}
          >
            <Link to="/sample-management/list">샘플 재고 관리</Link>
          </Item>
        )}
        {myInfo.project_id === 675 && (
          <Item isActive={location.pathname.includes("notices")} key={1}>
            <Link to="/notices">공지사항</Link>
          </Item>
        )}
        {myInfo.project_id === 675 && (
          <Item isActive={location.pathname.includes("boards")} key={2}>
            <Link to="/boards">자료실/게시판</Link>
          </Item>
        )}
      </Container>
      <Main>
        <Outlet />
      </Main>
    </Layout>
  );
};

const Layout = styled.div``;
const Container = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  flex-shrink: 0;
  width: 260px;
  height: 100vh;
  overflow: auto;
  display: flex;
  background: #fff;
  flex-direction: column;
  border-right: 1px solid #e4e6e6;
  @media (max-width: 1315px) {
    visibility: hidden;
  }
`;
const Item = styled.div<{ isActive: boolean }>`
  padding: 20px;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid #e4e6e6;
  margin: 0 18px;
  a {
    color: ${({ isActive }) => (isActive ? "#000" : "#989899")};
    font-weight: ${({ isActive }) => (isActive ? "500" : "400")};
  }
`;
const MainItem = styled.div`
  padding: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid #e4e6e6;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Main = styled.main`
  margin-left: 260px;
  @media (max-width: 1315px) {
    margin-left: 0px;
  }
`;

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 7px;
  }
`;

export default SideBar;
