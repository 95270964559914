import { QueryClientProvider, QueryClient } from "react-query";
import Router from "../src/components/Router";

function App() {
  const defaultOptions = {
    queries: {
      refetchOnWindowFocus: false,
    },
  };
  const queryClient = new QueryClient({
    defaultOptions,
  });
  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  );
}

export default App;
