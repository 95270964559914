import { OnChangeType, ItemType } from "types";

import { InputUnit } from "components/atoms/input";

const Unit = ({
  data,
  onChange,
  value,
  readOnly,
}: {
  data: ItemType;
  onChange: OnChangeType;
  value: any;
  readOnly?: boolean;
}) => {
  const { id, unit, min, max, is_required } = data;

  return (
    <>
      <InputUnit
        type="number"
        name={id + ""}
        onChange={onChange}
        value={value ?? ""}
        readOnly={readOnly}
        min={min}
        max={max}
        required={is_required === 1}
        step="0.001"
      //onWheel={event => event.currentTarget.blur()}
      />
      {unit}
    </>
  );
};

export default Unit;
