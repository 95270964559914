import styled from "styled-components";

export const TemplateHeader = styled.header`
  padding: 11px;
  color: #0043b7;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  background: #edf1fa;
  border-top: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpaceBetween = styled.div<{ noMargin?: boolean }>`
  margin-top: ${({ noMargin }) => !noMargin && "25px"};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexGrow = styled.div`
  flex: 1;
`;

export const Click = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
