import { FormEvent, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router";

import {
  createTemplateDefault,
  editTemplateDefault,
} from "apis/templateDefault";
import TemplateDefaultType from "types/templateDefault";

import { BtnPink, BtnWhite } from "components/atoms/button";
import { SpaceBetween, TemplateHeader } from "components/atoms/common";
import { Input } from "components/atoms/input";
import { Item, Items, Name, Value } from "components/atoms/item";
import { ModalContainer } from "components/atoms/modal";

const ModalTemplateDefault = ({
  onClose,
  data,
}: {
  onClose: () => void;
  data?: TemplateDefaultType;
}) => {
  const queryClient = useQueryClient();
  const { projectId = "" } = useParams();
  const [name, setName] = useState(data?.name || "");
  const [dayAfter, setDayAfter] = useState(data?.day_after || 0);

  const action = data
    ? () => editTemplateDefault({ id: data.id, name, day_after: dayAfter })
    : () => createTemplateDefault({ projectId, name, day_after: dayAfter });

  const mutation = useMutation(action, {
    onSuccess: () => {
      alert("저장 성공");
      queryClient.invalidateQueries(["getTemplates"]);
      onClose();
    },
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (mutation.isLoading) return;
    if (!window.confirm("저장하시겠습니까?")) return;
    mutation.mutate();
  };

  return (
    <ModalContainer>
      <form onSubmit={handleSubmit}>
        <TemplateHeader>기본 템플릿 {data ? "수정" : "추가"}</TemplateHeader>
        <Items>
          <Item>
            <Name>이름</Name>
            <Value>
              <Input onChange={(e) => setName(e.target.value)} value={name} />
            </Value>
          </Item>
          <Item>
            <Name>환자 등록일로부터</Name>
            <Value>
              <Input
                type="number"
                onChange={(e) => setDayAfter(+e.target.value)}
                value={dayAfter}
                small
              />
              일 후
            </Value>
          </Item>
        </Items>
        <SpaceBetween>
          <BtnWhite onClick={onClose}>닫기</BtnWhite>
          <BtnPink type="submit">저장</BtnPink>
        </SpaceBetween>
      </form>
    </ModalContainer>
  );
};

export default ModalTemplateDefault;
