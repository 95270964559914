export const geteGFRU25_Cr = (
  { height, age, blood_creatinine }: any,
  sex: String
) => {
  age = Math.round(age);
  if (age < 1) {
    age = 1;
  }
  height = height / 100;

  let K1 = 0;
  if (sex === "male") {
    if (age < 12) {
      K1 = 39.0 * 1.008 ** (age - 12);
    } else if (12 <= age && age < 18) {
      K1 = 39.0 * 1.045 ** (age - 12);
    } else if (18 <= age) {
      K1 = 50.8;
    } else {
      return "age out of range";
    }
  } else if (sex === "female") {
    if (age < 12) {
      K1 = 36.1 * 1.008 ** (age - 12);
    } else if (12 <= age && age < 18) {
      K1 = 36.1 * 1.023 ** (age - 12);
    } else if (18 <= age) {
      K1 = 41.4;
    } else {
      return "age out of range";
    }
  } else {
    return "성별 정보 없음";
  }

  return (K1 * (height / blood_creatinine)).toFixed(1);
};

export const geteGFRU25_CysC = ({ age, CystatinC }: any, sex: String) => {
  age = Math.round(age);
  if (age < 1) {
    age = 1;
  }

  let K2 = 0;
  if (sex === "male") {
    if (age < 15) {
      K2 = 87.2 * 1.011 ** (age - 15);
    } else if (15 <= age && age < 18) {
      K2 = 87.2 * 0.96 ** (age - 15);
    } else if (18 <= age) {
      K2 = 77.1;
    } else {
      return "age out of range";
    }
  } else if (sex === "female") {
    if (age < 12) {
      K2 = 79.9 * 1.004 ** (age - 12);
    } else if (12 <= age && age < 18) {
      K2 = 79.9 * 0.974 ** (age - 12);
    } else if (18 <= age) {
      K2 = 68.3;
    } else {
      return "age out of range";
    }
  } else {
    return "성별 정보 없음";
  }

  return (K2 / CystatinC).toFixed(1);
};

export const geteGFRU25_Cr_CysC = (
  { height, age, blood_creatinine, CystatinC }: any,
  sex: String
) => {
  age = Math.round(age);
  if (age < 1) {
    age = 1;
  }
  height = height / 100;

  // eGFR U25-Cr
  let K1 = 0;
  if (sex === "male") {
    if (age < 12) {
      K1 = 39.0 * 1.008 ** (age - 12);
    } else if (12 <= age && age < 18) {
      K1 = 39.0 * 1.045 ** (age - 12);
    } else if (18 <= age) {
      K1 = 50.8;
    } else {
      return "age out of range";
    }
  } else if (sex === "female") {
    if (age < 12) {
      K1 = 36.1 * 1.008 ** (age - 12);
    } else if (12 <= age && age < 18) {
      K1 = 36.1 * 1.023 ** (age - 12);
    } else if (18 <= age) {
      K1 = 41.4;
    } else {
      return "age out of range";
    }
  } else {
    return "성별 정보 없음";
  }
  const Cr = K1 * (height / blood_creatinine);

  // eGFR U25 CysC
  let K2 = 0;
  if (sex === "male") {
    if (age < 15) {
      K2 = 87.2 * 1.011 ** (age - 15);
    } else if (15 <= age && age < 18) {
      K2 = 87.2 * 0.96 ** (age - 15);
    } else if (18 <= age) {
      K2 = 77.1;
    }
  } else {
    if (age < 12) {
      K2 = 79.9 * 1.004 ** (age - 12);
    } else if (12 <= age && age < 18) {
      K2 = 79.9 * 0.974 ** (age - 12);
    } else if (18 <= age) {
      K2 = 68.3;
    }
  }
  const CysC = K2 / CystatinC;

  return ((Cr + CysC) / 2).toFixed(1);
};
