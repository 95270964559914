import PatientType from "types/patient";
import { TemplateType } from "types";
import getLMS from "./common/getLMS";
import getAge from "./common/getAge";
import { NORMSDIST } from "@formulajs/formulajs";

const heightPercentile = {
  id: 19,
  name: "heightPercentile",
  datas: ["height"],
  formula: (
    { height }: any,
    { sex, birthday }: PatientType,
    { reporting_date }: TemplateType
  ) => {
    if (!height || !sex || !birthday || !reporting_date) {
      return;
    }
    const age = getAge(birthday, reporting_date);
    const { L, M, S } = getLMS({ type: "height", sex, age });
    return (
      NORMSDIST((Math.pow(height / M, L) - 1) / (L * S), true) * 100
    ).toFixed(1);
  },
  help: `NORMSDIST((((키/M)^L)-1)/(L*S))*100`,
};

export default heightPercentile;
