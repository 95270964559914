import { atom } from "recoil";
import { ResearcherType } from "types";
import PatientType from "types/patient";

const patientState = atom<PatientType>({
  key: "patientState",
  default: {
    id: 0,
    sex: "",
    name: "",
    birthday: "",
    initial_diagnosis_date: "",
    note: "",
    type: { id: 0, name: "", code: "" },
    hospitalName: "",
    adminName: "",
    code: "",
    hospital_id: 0,
    admin_id: 0,
    patient_type_id: 0,
    lastDefaultTemplateTitle: "",
    admin: { id: 0, name: "" } as ResearcherType,
    query_count: 0,  // 20221212 오토쿼리 관련 추가 by 장희준
  },
});
export default patientState;
