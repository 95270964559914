import instance from '.';


// 샘플 요청 목록.
export const getSampleRequests = async (params: any) => {
  try {
    const result = await instance.get('/sample-request', { params });
    return result.data;
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
  }
};

// 샘플 요청 상세.
export const getSampleRequestAndDetail = async (id: string) => {
  if (!id) return;
  try {
    const result = await instance.get(`/sample-request/${id}`);
    return result.data;
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);

      if (err.response.data.message) {
        alert(err.response.data.message);
      }
    }
  }
};

// 샘플 요청 상세 조건(아이템).
export const getSampleRequestItems = async () => {
  try {
    const result = await instance.get(`/sample-request/items`);
    return result.data;
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
  }
};

// 샘플 요청 상세 저장.
export const postSampleRequestAndDetail = async (data: any) => {
  const result = await instance.post(`/sample-request`, data);
  return result.data;
};

// 샘플 요청 상세 수정.
export const putSampleRequestAndDetail = async (data: any) => {
  if (!data || !data.id) return;
  const result = await instance.put(`/sample-request/${data.id}`, data);
  return result.data;
};

// 샘플 요청 상태 변경.
export const patchSampleRequestStatus = async (id: number, status: string, isPreview?: boolean, noteRequest?: string, patients?: any) => {
  if (!id || !status) return;

  const params: any = { status };

  if (isPreview) {
    params.isPreview = isPreview;
  }

  if (noteRequest) {
    params.noteRequest = noteRequest;
  }

  if (patients) {
    params.patients = patients;
  }

  const result = await instance.patch(`/sample-request/${id}/status`, params);
  return result.data;
};

// 샘플 요청 삭제.
export const deleteSampleRequest = async (id: number) => {
  if (!id) return;
  const result = await instance.delete(`/sample-request/${id}`);
  return result.data;
};

// 샘플 요청 환자 조회.
export const getSampleRequestPatientsByCount = async (id: number, params: any) => {
  if (!id) return;
  const result = await instance.get(`/sample-request/${id}/patients`, { params });
  return result.data;
};
