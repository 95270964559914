import { SectionType } from "types";
import instance from ".";

export const getSections = async (params: {
  projectId: string;
  templateId?: string;
  needValues?: boolean;
  patientId?: string;
}) => {
  const result = await instance.get(`/sections`, { params });
  return result.data;
};

export const getSection = async ({
  sectionId,
  templateId,
  patientId
}: {
  sectionId: string;
  templateId: string;
  patientId?: string;
}) => {
  const result = await instance.get(`/sections/${sectionId}`, {
    params: { templateId, patientId },
  });
  return result.data;
};

export const createSection = async (data: {
  projectId: string;
  name: string;
}) => {
  const result = await instance.post(`/sections`, data);
  return result.data;
};

export const editSection = async (data: SectionType) => {
  const result = await instance.put(`/sections/${data.id}`, data);
  return result.data;
};

export const deleteSection = async (id: number) => {
  return await instance.delete(`/sections/${id}`);
};
