const types = [
  {
    name: "임시저장",
    code: "TEMP"
  },
  {
    name: "요청",
    code: "REQUEST"
  },
  {
    name: "반려",
    code: "REJECT"
  },
  {
    name: "승인",
    code: "APPROVAL"
  },
  {
    name: "수령",
    code: "RECEIVE"
  }
];

export default types;
