import { FormEvent, ReactNode } from "react";
import styled from "styled-components";

import InputItem from "components/molecules/InputItem";
import { SpaceBetween } from "components/atoms/common";

import { ItemType } from "types";

import useInputs from "hooks/useInputs";
import { BtnPink } from "components/atoms/button";
import { Items } from "components/atoms/item";

import { useParams } from "react-router";

import { useQuery } from "react-query";
import { getPatient } from "apis/patient";

const ItemForm = ({
  data,
  btnText,
  onSubmit,
  btnLeft,
}: {
  data: ItemType[];
  btnText?: String | null;
  onSubmit: (e: any) => void;
  btnLeft?: () => ReactNode;
}) => {
  const initialData: { [key: number]: string | number[] } = {};
  data.forEach(
    ({ id, value, type, valueOptions }: ItemType) =>
      (initialData[id] = ["checkbox", "radio"].includes(type)
        ? valueOptions
        : value)
  );

  const { sectionId = "", patientId = "", projectId = "" } = useParams();

  const { isLoading: isLoadingPatient, data: patient } = useQuery(
    ["getPatient", patientId],
    () => getPatient(patientId)
  );

  /** 동의철회 날짜 */
  let consentWithdrawalDate: string | undefined;
  if (projectId === "674") {
    consentWithdrawalDate = patient?.items[3].value;
  }

  const showConsentWithdrawalAlert = (e: any) => {
    if (consentWithdrawalDate) {
      e.preventDefault();
      alert("동의철회된 환자입니다.");
    }
  };

  const startDateItems = [
    3268, 3273, 3278, 3283, 3288, 3293, 3298, 3303, 3308, 3313, 3318, 3323,
    3328, 3333, 3338, 3343, 3348, 3353, 3358, 3363, 3368, 3373, 3378, 3383,
    3389, 3394, 3399, 3404, 3409, 3414, 3419, 3424, 3429, 3434, 3439, 3444,
    3449, 3454, 3459, 3464, 3469, 3474, 3479, 3484, 3489, 3569, 3574, 3579,
    3584, 3589, 3594, 3599, 3604, 3609, 3614, 3619, 3624, 3629, 3634, 3639,
    3644, 3649, 3654, 3659, 3664, 3671, 3676, 3681, 3686, 3691, 3696, 3701,
    3706, 3711, 3716, 3773, 3778, 3783, 3788, 3793, 3798, 3803, 3808, 3813,
    3818, 3875, 3880, 3885, 3890, 3895, 4515, 4518, 4521, 4524, 4527, 4530,
    4533, 4536, 4539, 4542, 4545, 4548, 4551, 4554, 4557, 4561, 4564, 4567,
    4570, 4573, 4576, 4579, 4582, 4585, 4588, 4591, 4594, 4597, 4600, 4603,
    4606, 4609, 4612, 4615, 4618, 4621, 4624, 4627, 4630, 4633, 4636, 4639,
    4642, 4645, 4648, 4651, 4654, 4657, 4660, 4663, 4666, 4669, 4672, 4675,
    4678,
  ];

  const endDateItems = [
    3269, 3274, 3279, 3284, 3289, 3294, 3299, 3304, 3309, 3314, 3319, 3324,
    3329, 3334, 3339, 3344, 3349, 3354, 3359, 3364, 3369, 3374, 3379, 3384,
    3390, 3395, 3400, 3405, 3410, 3415, 3420, 3425, 3430, 3435, 3440, 3445,
    3450, 3455, 3460, 3465, 3470, 3475, 3480, 3485, 3490, 3570, 3575, 3580,
    3585, 3590, 3595, 3600, 3605, 3610, 3615, 3620, 3625, 3630, 3635, 3640,
    3645, 3650, 3655, 3660, 3665, 3672, 3677, 3682, 3687, 3692, 3697, 3702,
    3707, 3712, 3717, 3774, 3779, 3784, 3789, 3794, 3799, 3804, 3809, 3814,
    3819, 3876, 3881, 3886, 3891, 3896, 4516, 4519, 4522, 4525, 4528, 4531,
    4534, 4537, 4540, 4543, 4546, 4549, 4552, 4555, 4558, 4562, 4565, 4568,
    4571, 4574, 4577, 4580, 4583, 4586, 4589, 4592, 4595, 4598, 4601, 4604,
    4607, 4610, 4613, 4616, 4619, 4622, 4625, 4628, 4631, 4634, 4637, 4640,
    4643, 4646, 4649, 4652, 4655, 4658, 4661, 4664, 4667, 4670, 4673, 4676,
    4679,
  ];

  // console.log("startDateItems.length:", startDateItems.length);

  const [values, onChange] = useInputs(initialData);

  const sortedData = [...data].sort(
    (a: ItemType, b: ItemType) => a.position - b.position
  );
  const filteredData = sortedData.filter(
    ({ item_option_id }: ItemType) => !item_option_id
  );

  let diffDate = false;

  const handleSubmit = (e: FormEvent) => {
    if (sectionId === "151") {
      for (const key in values) {
        const id = parseInt(key);
        if (startDateItems.includes(id) && values[id] > values[id + 1]) {
          diffDate = true;
        }
        if (
          startDateItems.includes(id) &&
          id !== 3268 &&
          id !== 3368 &&
          id !== 3469 &&
          id !== 3569
        ) {
          // endDateItems를 내림차순으로 정렬
          const sortedEndDateItems = [...endDateItems].sort((a, b) => b - a);
          // id보다 작은 첫 번째 값을 찾음
          const closestValue = sortedEndDateItems.find((item) => item < id);
          if (
            !!values[id] &&
            closestValue &&
            values[id] <= values[closestValue]
          ) {
            diffDate = true;
          }
        }
      }
    }
    if (diffDate) {
      e.preventDefault();
      onSubmit({ ...values, diffDate });
    } else {
      e.preventDefault();
      onSubmit(values);
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Items>
        {filteredData.map((item: ItemType) => (
          <InputItem
            list={sortedData}
            data={item}
            key={item.id}
            onChange={onChange}
            values={values}
          />
        ))}
      </Items>
      <SpaceBetween>
        <div>{btnLeft && btnLeft()}</div>
        <BtnPink
          onClick={(e) => {
            if (projectId === "674") showConsentWithdrawalAlert(e);
          }}
          type="submit"
          large
        >
          {btnText ?? "저장"}
        </BtnPink>
      </SpaceBetween>
    </Form>
  );
};

export const Form = styled.form``;

export default ItemForm;
