import { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import { getLocaleDate } from 'utils/time';
import { getTokenPayload } from 'utils/user';

import sampleRequestTypes from 'datas/sampleRequestTypes';

import { getSampleStock } from 'apis/sampleStock';
import { getSampleRequestItems } from 'apis/sampleRequest';

import styled from 'styled-components';

import Pagination from 'components/organisms/Pagination';


const display = 15;

const SampleStock = () => {
  const { projectId } = getTokenPayload();
  const { patientId = '', templateDefaultId = '' } = useParams();

  const { isLoading: isLoadingSampleStock, data: sampleStock } = useQuery(
    ['getSampleStock', patientId, { templateDefaultId }],
    () => getSampleStock(patientId, { templateDefaultId })
  );

  const { isLoading: isLoadingSampleRequestItems, data: sampleRequestItems } = useQuery(
    ['getSampleRequestItems'],
    () => getSampleRequestItems()
  );

  const [page, setPage] = useState(1);

  if (isLoadingSampleStock || isLoadingSampleRequestItems) {
    return <></>;
  }

  const { simple, details } = sampleStock;
  const { count } = sampleRequestItems;

  const section = count[templateDefaultId].sections[0];
  const thItems: any = count[templateDefaultId][section.id].items.filter((item: any) => item.type === 'unit');
  const thOptions: any = count[templateDefaultId][section.id].options.map((option: any) => ({
    ...option,
    colSpan: thItems.filter((item: any) => item.item_option_id === option.id).length,
    rowSpan: thItems.filter((item: any) => item.item_option_id === option.id && item.name === '').length + 1
  })).filter((option: any) => option.colSpan > 0);

  return <Items>
    <TemplateHeader>환자 정보</TemplateHeader>
    <Item>
      <Name>이름</Name>
      <Value>
        <Link to={`/project/${projectId}/patient/${simple.id}/template/${simple.template_id}/section/${simple.section_id}`}>
          <Underline isRequest={false}>{simple.name}</Underline>
        </Link>
      </Value>
    </Item>
    <Item>
      <Name>고유번호</Name>
      <Value><Span>{simple.code}</Span></Value>
    </Item>
    <Item>
      <Name>병원</Name>
      <Value><Span>{simple.hospital_name}</Span></Value>
    </Item>
    <Item>
      <Name>주차</Name>
      <Value>
        <Span>{simple.template_default_name}</Span>
      </Value>
    </Item>
    <Table>
      <thead>
        <TR>
          <TH rowSpan={2} width="50px" borderLeft={false}>구분</TH>
          {thOptions.map((option: any, index: number) =>
            <TH key={`key-stock-patient-simple-thead-group-${index}`} colSpan={option.colSpan} rowSpan={option.rowSpan}>{option.value}</TH>
          )}
        </TR>
        <TR>
          {thItems.filter((item: any) => item.name !== '').map((item: any, index: number) =>
            <TH key={`key-stock-patient-simple-thead-${index}`}>{item.name}</TH>
          )}
        </TR>
      </thead>
      <tbody>
        <TR>
          <TH height="31px" borderLeft={false}>수집</TH>
          {thItems.map((thItem: any, index: number) => {
            const find = simple.items.find((item: any) => item.id === thItem.id);

            return <TD key={`key-stock-patient-simple-tbody-init-${index}`} style={{ color: find?.origin_value > 0 ? '' : '#989899' }}>
              {find?.origin_value ?? '-'}
            </TD>;
          })}
        </TR>
        <TR>
          <TH height="31px" borderLeft={false}>분양</TH>
          {thItems.map((thItem: any, index: number) => {
            const find = simple.items.find((item: any) => item.id === thItem.id);

            if (find) {
              const outValue = find.origin_value - find.value - find.hold_value;

              return <TD key={`key-stock-patient-simple-tbody-out-${index}`} style={{ color: find.origin_value > 0 && find.origin_value != find.value ? '' :  '#989899' }}>
                {outValue > 0 || find.hold_value > 0 ? outValue : '-' }
                {find.hold_value > 0
                  && <Hold>({find.hold_value})</Hold>
                }
              </TD>;
            } else {
              return <TD key={`key-stock-patient-simple-tbody-out-${index}`} style={{ color: '#989899' }}>-</TD>;
            }
          })}
        </TR>
        <TR>
          <TH height="31px" borderLeft={false}>재고</TH>
          {thItems.map((thItem: any, index: number) => {
            const find = simple.items.find((item: any) => item.id === thItem.id);

            return <Fragment key={`key-stock-patient-simple-tbody-now-${index}`}>
              {find
                && <TD>
                  <span style={{ color: find.origin_value > 0 ? '' :  '#989899' }}>{find.value}</span>
                  {find.hold_value > 0
                    && <Hold>({find.hold_value})</Hold>
                  }
                </TD>
              }
              {!find
                && <TD style={{ color: '#989899' }}>-</TD>
              }
            </Fragment>;
          })}
        </TR>
      </tbody>
    </Table>
    {details.length > 0
      && <>
        <TemplateHeader borderTop={false}>분양 기록</TemplateHeader>
        <Table>
          <thead>
            <TR>
              <TH rowSpan={2} width="40px" borderLeft={false}>순번</TH>
              <TH rowSpan={2} width="90px">요청자</TH>
              <TH rowSpan={2} width="150px">과제명</TH>
              {thOptions.map((option: any, index: number) =>
                <TH key={`key-stock-patient-detail-thead-group-${index}`} colSpan={option.colSpan} rowSpan={option.rowSpan}>{option.value}</TH>
              )}
              <TH rowSpan={2} width="50px">상태</TH>
              <TH rowSpan={2} width="90px">수령일</TH>
            </TR>
            <TR>
              {thItems.filter((item: any) => item.name !== '').map((item: any, index: number) =>
                <TH key={`key-stock-patient-detail-thead-${index}`}>{item.name}</TH>
              )}
            </TR>
          </thead>
          <tbody>
            {details.map((detail: any, rowIndex: number) => (((page - 1) * display < rowIndex + 1) && (rowIndex + 1 <= page * display))
              && <TR key={`key-stock-patient-detail-tbody-${rowIndex}`} style={{ color: detail.status === 'RECEIVE' ? '' : '#ff0000' }}>
                <TD borderLeft={false}>{details.length - rowIndex}</TD>
                <TD>{detail.admin_name}</TD>
                <TD title={detail.study}>
                  <Link to={`../request/${detail.id}`}>
                    <Underline>{detail.study}</Underline>
                  </Link>
                </TD>
                {thItems.map((item: any, index: number) =>
                  <TD key={`key-stock-patient-detail-tbody-${rowIndex}-${index}`} style={{ color: detail.items[item.id] ? '' : '#989899' }}>
                    {detail.items[item.id] ?? '-'}
                  </TD>
                )}
                <TD>{sampleRequestTypes.find(({ code }) => code === detail.status)?.name}</TD>
                <TD style={{ color: detail.status === 'RECEIVE' ? '' : '#989899' }}>
                  {detail.status === 'RECEIVE' ? getLocaleDate(detail.updated_at) : '-'}
                </TD>
              </TR>
            )}
          </tbody>
        </Table>
        <Footer>
          <Pagination total={details.length} display={display} page={page} onChange={setPage} />
        </Footer>
      </>
    }
  </Items>;
};

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-right: none;
`;

const TemplateHeader = styled.header<{
  borderTop?: boolean;
}>`
  color: #0043b7;
  font-weight: 600;
  font-size: 15px;
  line-height: 35px;
  text-align: center;
  background: #edf1fa;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: ${({ borderTop = true }) => borderTop && '1px solid #dfe0e0'};
`;

const Item = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid #dfe0e0;
  min-height: 30px;
`;

const Name = styled.div`
  background: #fafafc;
  border-right: 1px solid #dfe0e0;
  width: 150px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  word-break: keep-all;
`;

const Value = styled.div`
  display: flex;
  min-height: 20px;
  align-items: center;
  padding: 2px;
  flex: 1;
  font-size: 14px;
  position: relative;
`;

const Span = styled.span`
  margin-left: 5px;
  margin-right: 15px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 0px;
  border-top: 1px solid #dfe0e0;
`;

const TR = styled.tr`
  border-bottom: 1px solid #dfe0e0;
  &:hover { background: #f9f9f8; }
`;

const TH = styled.th<{
  width?: string;
  height?: string;
  borderLeft?: boolean;
}>`
  text-align: center;
  color: #989899;
  background: #fafafc;
  font-size: 14px;
  height: ${({ height }) => height ?? '45px'};
  width: ${({ width }) => width};
  border-left: ${({ borderLeft = true }) => borderLeft && '1px solid #dfe0e0'};
`;

const TD = styled.td<{
  borderLeft?: boolean;
}>`
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  height: 31px;
  border-left: ${({ borderLeft = true }) => borderLeft && '1px solid #dfe0e0'};
  &:hover { background: #f3f3f1; }
`;

const Underline = styled.div<{
  isRequest?: boolean;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: underline;
  width: ${({ isRequest = true }) => isRequest ? '147px' : 'auto'};
  padding: ${({ isRequest = true }) => isRequest ? '0 10px' : '0 5px'};
`;

const Hold = styled.span`
  margin-left: 2px;
  color: #ff0000;
`;

const Footer = styled.footer`
  margin: 30px auto;
  display: flex;
  justify-content: center;
`;

export default SampleStock;
