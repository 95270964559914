import { FormEvent, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { BtnPink, BtnWhite } from "components/atoms/button";
import { SpaceBetween, TemplateHeader } from "components/atoms/common";
import { Item, Items, Name, Value, Required } from "components/atoms/item";
import { Input, Select, Textarea } from "components/atoms/input";
import Modal from "components/organisms/Modal";
import ModalPasswordChange from "./ModalPasswordChange";
import { ModalContainer } from "components/atoms/modal";

import useInputs from "hooks/useInputs";
import { createResearcher, editResearcher } from "apis/researcher";
import ResearcherType from "types/researcher";
import { getHospitals } from "apis/hospital";
import HospitalType from "types/hospital";

const ModalCreateResearcher = ({
  data,
  onClose,
}: {
  data?: ResearcherType;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const { projectId = "" } = useParams();
  const { data: hospitals, isLoading } = useQuery(
    ["getHospitals", { projectId }],
    () => getHospitals({ projectId })
  );

  const defaultState = data ?? {
    name: "",
    user_name: "",
    note: "",
    is_project_super: "1",
    password: "qwerty1234",
    project_id: parseInt(projectId),
    hospital_id: 0,
  };
  const [state, onChange] = useInputs(defaultState);
  const { name, user_name, password, note, is_project_super, hospital_id } =
    state;

  const mutation = useMutation(
    () => (data ? editResearcher(state) : createResearcher(state)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getResearchers"]);
        onClose();
      },
      onError: ({ response: { data } }) => {
        alert(data.message);
      },
    }
  );

  useEffect(() => {
    if (!isLoading) {
      if (hospitals.length === 0) {
        alert("병원을 먼저 생성하세요");
        onClose();
      } else {
        if (!hospital_id) {
          onChange({ target: { name: "hospital_id", value: hospitals[0].id } });
        }
      }
    }
  }, [hospitals]);

  const copy = async () => {
    await navigator.clipboard.writeText(password);
    alert("복사되었습니다.");
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (mutation.isLoading) return;
    if (window.confirm(`연구원을 ${data ? "수정" : "추가"}하시겠습니까?`)) {
      mutation.mutate();
    }
  };

  if (isLoading) return <></>;

  return (
    <ModalContainer>
      <form onSubmit={handleSubmit}>
        <TemplateHeader>연구원 {data ? "수정" : "추가"}</TemplateHeader>
        <Items>
          <Item>
            <Name>
              아이디<Required>*</Required>
            </Name>
            <Value>
              {data ? (
                user_name
              ) : (
                <Input
                  placeholder="로그인 아이디"
                  value={user_name}
                  name="user_name"
                  onChange={onChange}
                  required
                />
              )}
            </Value>
          </Item>
          <Item>
            <Name>
              비밀번호 관리<Required>*</Required>
            </Name>
            <Value>
              {data ? (
                <Modal
                  activator={(onOpen) => (
                    <BtnWhite onClick={onOpen}>변경</BtnWhite>
                  )}
                  content={(onClose) => (
                    <ModalPasswordChange onClose={onClose} data={data} />
                  )}
                />
              ) : (
                <>
                  <Input
                    value={password}
                    name="password"
                    onChange={onChange}
                    required
                  />
                  <BtnCopy onClick={copy}>복사</BtnCopy>
                </>
              )}
            </Value>
          </Item>
          <Item size={2}>
            <Name>소속</Name>
            <Value>
              <Select
                value={hospital_id}
                name="hospital_id"
                onChange={onChange}
              >
                {hospitals.map(({ id, name }: HospitalType) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </Select>
            </Value>
          </Item>
          <Item size={2}>
            <Name>분류</Name>
            <Value>
              <Select
                value={is_project_super}
                name="is_project_super"
                onChange={onChange}
              >
                <option value="1">프로젝트 관리자</option>
                <option value="0">연구원</option>
              </Select>
            </Value>
          </Item>
          <Item size={1}>
            <Name>
              연구원 이름<Required>*</Required>
            </Name>
            <Value>
              <Input value={name} name="name" onChange={onChange} required />
            </Value>
          </Item>
          <Item size={1}>
            <Name>비고</Name>
            <Value>
              <Textarea value={note} name="note" onChange={onChange} />
            </Value>
          </Item>
        </Items>
        <SpaceBetween>
          <BtnWhite large onClick={onClose}>
            취소
          </BtnWhite>
          <BtnPink large type="submit">
            저장
          </BtnPink>
        </SpaceBetween>
      </form>
    </ModalContainer>
  );
};

const BtnCopy = styled.button`
  padding: 5px 8px;
  position: absolute;
  right: 18px;
  top: 18px;
  background: #fafafc;
  border: 1px solid #c7c7c7;
  border-radius: 2px;
`;
export default ModalCreateResearcher;
