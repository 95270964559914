import dateCalculator from "./dateCalculator";
import getUsage from "./getUsage";

export const getPrednisoloneDose = (steroid: any[]) => {
  return steroid.reduce(
    (acc, cur) =>
      acc + (cur[0] * (dateCalculator(cur[1], cur[2]) || 0)) / (getUsage(cur[3]) || 1),
    0
  );
};

export const getDeflazacortDose = (steroid: any[]) => {
  return steroid.reduce(
    (acc, cur) =>
      acc +
      ((cur[0] / 1.2) * (dateCalculator(cur[1], cur[2]) || 0)) / (getUsage(cur[3]) || 1),
    0
  );
};

export const getMethylprednisoloneDose = (steroid: any[]) => {
  return steroid.reduce(
    (acc, cur) =>
      acc +
      ((cur[0] / 0.8) * (dateCalculator(cur[1], cur[2]) || 0)) / (getUsage(cur[3]) || 1),
    0
  );
};

export const getDexamethasoneDose = (steroid: any[]) => {
  return steroid.reduce(
    (acc, cur) =>
      acc +
      ((cur[0] / 0.1) * (dateCalculator(cur[1], cur[2]) || 0)) / (getUsage(cur[3]) || 1),
    0
  );
};
