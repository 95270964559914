import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { createItemOption, editItemOption } from "apis/itemOption";
import { ItemOptionType } from "types";

import { BtnPink } from "components/atoms/button";
import { SpaceBetween } from "components/atoms/common";
import { Item, Items, Name, Value } from "components/atoms/item";
import { ModalContainer, ModalTitle } from "components/atoms/modal";
import { Input, Textarea } from "components/atoms/input";

const ModalOption = ({
  itemId,
  data,
  onClose,
}: {
  itemId?: number;
  data?: ItemOptionType;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const [value, setValue] = useState(data?.value ?? "");
  const [position, setPosition] = useState(data?.position ?? 0);
  const [help, setHelp] = useState(data?.help ?? "");

  const mutation = useMutation(
    () =>
      itemId
        ? createItemOption({ itemId, value, help })
        : editItemOption({ id: data?.id ?? 0, value, position, help }),
    {
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries(["getItemOptions"]);
      },
    }
  );

  const handleSubmit = async () => {
    if (mutation.isLoading) return;
    if (!value) return alert("옵션 값을 입력해 주세요.");

    if (!window.confirm("저장 하시겠습니까?")) return;
    mutation.mutate();
  };

  return (
    <ModalContainer>
      <ModalTitle>옵션 {data ? "수정" : "추가"}</ModalTitle>
      <Items>
        <Item>
          <Name>이름</Name>
          <Value>
            <Input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              autoFocus
            />
          </Value>
        </Item>
        {data && (
          <Item>
            <Name>순서</Name>
            <Value>
              <Input
                small
                type="number"
                value={position}
                onChange={(e) => setPosition(+e.target.value)}
              />
            </Value>
          </Item>
        )}
        <Item>
          <Name>도움말</Name>
          <Value>
            <Textarea onChange={(e) => setHelp(e.target.value)} value={help} />
          </Value>
        </Item>
      </Items>
      <SpaceBetween>
        <div></div>
        <BtnPink onClick={handleSubmit} large>
          저장
        </BtnPink>
      </SpaceBetween>
    </ModalContainer>
  );
};

export default ModalOption;
