import { NORMDIST } from "@formulajs/formulajs";
import { dbpData, sbpData } from "./bpData";

const getBloodPressurePercentile = (
  sex: string,
  BP: number,
  age: number,
  heightPercentile: number,
  isSBP: boolean
) => {
  // 1. height Percentile 근사
  const hp_c = getCloserPercentile(heightPercentile);

  // 2. 테이블에서 검색
  const bloodPressureRange = findBloodPressurePercentile(sex, age, hp_c, isSBP);

  // 3. 표준편차 계산
  const sigma = calStd(bloodPressureRange);

  //
  let bpPercentile = 0;
  try {
    bpPercentile = NORMDIST(BP, bloodPressureRange.bp_s, sigma, true) * 100;
    return bpPercentile.toFixed(1);
  } catch (err) {
    console.log(`[err]: ${err}`);
    return 0;
  }
};

export default getBloodPressurePercentile;

function getCloserPercentile(target: number) {
  const heightPercentiles = [5, 10, 25, 50, 75, 90, 95];
  let bestIdx = heightPercentiles.length - 1;
  for (let index = 0; index < heightPercentiles.length; index++) {
    if (heightPercentiles[index] > target) {
      bestIdx = index;
      break;
    }
  }
  // 양 끝 예외처리
  if (bestIdx === 0) {
    return heightPercentiles[0];
  } else if (bestIdx === heightPercentiles.length) {
    return heightPercentiles[heightPercentiles.length - 1];
  }

  const mean =
    (heightPercentiles[bestIdx] + heightPercentiles[bestIdx - 1]) / 2;

  if (target < mean) {
    return heightPercentiles[bestIdx - 1];
  } else {
    return heightPercentiles[bestIdx];
  }
}

function findBloodPressurePercentile(
  sex: string,
  age: number,
  hp_c: number,
  isSBP: boolean
) {
  // 주어진 고체백분위 값이 위치하는 혈압 백분위 범위
  // (0.5p, 0.9p)를 구한다
  const heightPercentiles = [5, 10, 25, 50, 75, 90, 95];
  const position = heightPercentiles.indexOf(hp_c);

  // SBP or DBP
  if (isSBP) {
    return {
      bp_s: sbpData[sex][age]["p50"][position],
      bp_e: sbpData[sex][age]["p90"][position],
    };
  } else {
    return {
      bp_s: dbpData[sex][age]["p50"][position],
      bp_e: dbpData[sex][age]["p90"][position],
    };
  }
}

function calStd(bpRange: { bp_s: number; bp_e: number }) {
  const sigma = (bpRange.bp_e - bpRange.bp_s) / 1.29;
  return sigma;
}

export function getBpStdAge(age: number): number {
  // n개월 -> k.5세로 변환
  // 일부 예외 있음
  if (age <= 2) {
    return 0.08;
  } else if (age < 7) {
    return 0.3;
  } else if (age < 14) {
    return 0.8;
  }

  return Math.floor(age / 12) + 0.5;
}
