import { TemplateType } from "types";
import PatientType from "types/patient";
import getLMS from "./common/getLMS";
import getAge from "./common/getAge";

const weightLMS = {
  id: 11,
  name: "weightLMS",
  datas: ["weight"],
  formula: (
    { weight }: any,
    { sex, birthday }: PatientType,
    { reporting_date }: TemplateType
  ) => {
    if (!weight || !sex || !birthday || !reporting_date) {
      return;
    }
    const age = getAge(birthday, reporting_date);
    const { L, M, S } = getLMS({ type: "weight", sex, age });
    return ((Math.pow(weight / M, L) - 1) / (L * S)).toFixed(3);
  },
  help: `체중 z score (자동계산) = ((Power (체중/M)^L))-1)/(L*S)`,
};

export default weightLMS;
