import { useState } from 'react';

import styled from 'styled-components';

import { TabConatiner, Tab, TabLine } from 'components/atoms/tab';

import SampleRequestCount from './SampleRequestCount';
import SampleRequestPatient from './SampleRequestPatient';


const SampleRequestCountAndPatient = ({
  count, state, setState,
  isTemp, isRequest, isApproval, isReceive,
  isAdmin,
  getThOptions, getThItems,
  getPatientsSize
}: {
  count: any; state: any; setState: any;
  isTemp: boolean; isRequest: boolean; isApproval: boolean; isReceive: boolean;
  isAdmin: boolean;
  getThOptions: any; getThItems: any;
  getPatientsSize: any;
}) => {
  const [templateDefaultId, setTemplateDefaultId] = useState(count.templates[0].id);

  return <>
    <Space />
    <Title>청구 수량</Title>
    {!isTemp
      && <SubTitle>(총 {getPatientsSize()}명)</SubTitle>
    }
    <TabConatiner>
      {count.templates.map((template: any) =>
        <Tab key={`key-request-tab-${template.id}`} onClick={() => setTemplateDefaultId(template.id)} isActive={template.id === templateDefaultId}
          style={{
            color: template.id === templateDefaultId ? "#0043b7" : "#989899",
            backgroundColor: template.id === templateDefaultId ? "#edf1fa" : "#fafafc"
          }}>{template.name}</Tab>
      )}
      <TabLine />
    </TabConatiner>
    <SampleRequestCount templateDefaultId={templateDefaultId} count={count} state={state} setState={setState} isTemp={isTemp} isRequest={isRequest} isAdmin={isAdmin} getThOptions={getThOptions} getThItems={getThItems} />
    {!isTemp
      && <SampleRequestPatient templateDefaultId={templateDefaultId} count={count} state={state} setState={setState} isRequest={isRequest} isApproval={isApproval} isReceive={isReceive} isAdmin={isAdmin} getThOptions={getThOptions} getThItems={getThItems} />
    }
  </>;
}

const Title = styled.h3`
  margin-left: 10px;
`;

const SubTitle = styled.h4`
  margin-left: 10px;
`;

const Space = styled.div`
  width: 100%;
  height: 25px;
  border-top: 1px solid #dfe0e0;
`;

export default SampleRequestCountAndPatient;
