import styled from "styled-components";

import { Input } from "components/atoms/input";
import { Item, Name, Value } from "components/atoms/item";
import { ItemType, OnChangeType } from "types";

const MinMax = ({
  state,
  onChange,
}: {
  state: ItemType;
  onChange: OnChangeType;
}) => {
  const { min, max, type } = state;
  const handleClick = (name: string) => {
    onChange({ target: { name, value: "" } });
  };
  return (
    <>
      <Item size={2}>
        <Name>최소</Name>
        <Value>
          <Input
            type={type === "unit" ? "number" : type}
            value={min}
            small
            name="min"
            onChange={onChange}
          />
          {min !== "" && (
            <BtnCancel onClick={() => handleClick("min")}>적용 취소</BtnCancel>
          )}
        </Value>
      </Item>
      <Item size={2}>
        <Name>최대</Name>
        <Value>
          <Input
            type={type === "unit" ? "number" : type}
            value={max}
            small
            name="max"
            onChange={onChange}
          />
          {max !== "" && (
            <BtnCancel onClick={() => handleClick("max")}>적용 취소</BtnCancel>
          )}
        </Value>
      </Item>
    </>
  );
};

const BtnCancel = styled.span`
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
`;

export default MinMax;
