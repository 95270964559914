import { useQuery } from "react-query";
import { Outlet, useParams } from "react-router";

import { getItems } from "apis/item";
import EditItemWrapper from "components/organisms/item/EditWrapper";

const Section = () => {
  const { sectionId = "" } = useParams();

  const { data, isLoading } = useQuery(["getItems", { sectionId }], () =>
    getItems({ sectionId })
  );

  if (isLoading) return <></>;

  return (
    <>
      <EditItemWrapper data={data} />
      <Outlet />
    </>
  );
};

export default Section;
