import { Route, Routes } from "react-router-dom";

import AdminCheck from "components/organisms/AdminCheck";

import Projects from "components/pages/admin/Projects";
import Project from "components/pages/admin/Project";
import Sections from "components/pages/admin/Sections";
import Section from "components/pages/admin/Section";
import Patient from "components/pages/admin/Patient";
import Researcher from "components/pages/admin/Researcher";
import Item from "components/pages/admin/Item";
import Hospitals from "./pages/admin/Hospitals";
import Templates from "./pages/admin/Templates";
import Type from "./pages/admin/Type";
import Study from "./pages/admin/Study";

const AdminRouter = () => {
  return (
    <Routes>
      <Route element={<AdminCheck />}>
        <Route path="project" element={<Projects />} />
        <Route path="project/:projectId" element={<Project />}>
          <Route path="section" element={<Sections />}>
            <Route path=":sectionId" element={<Section />}>
              <Route path="item/:itemId" element={<Item />} />
            </Route>
          </Route>
          <Route path="type" element={<Type />} />
          <Route path="patient" element={<Patient />}>
            <Route path="item/:itemId" element={<Item />} />
          </Route>
          <Route path="researcher" element={<Researcher />} />
          <Route path="hospital" element={<Hospitals />} />
          <Route path="template" element={<Templates />} />
          <Route path="casestudy" element={<Study />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AdminRouter;
