import { Backdrop } from "components/atoms/modal";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";

const LoadingBar = ({ show }: { show: boolean; }) => {

    const target = show && (
        <Backdrop>
            <Loading />
            <LoadingText>Loading</LoadingText>
        </Backdrop>
    );

    return createPortal(target, document.body);

};

const Spin = keyframes`
  to { transform: rotate(359deg); }
`;

const Scale = keyframes`
  0%   { transform: scale(1); }
  50%  { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const Loading = styled.div`
  width: 150px;
  height: 150px;
  margin: 5% auto;
  border: 5px solid #fff;
  border-radius: 100%;
  border-top-color: #1bf;
  animation: ${Spin} 1000ms infinite ease-in-out;
`;

const LoadingText = styled.div`
  color: #ffffff;
  font-size: 20px;
  opacity: 1;
  text-align: center;
  position: absolute;
  animation: ${Scale} 1000ms infinite linear;
  animation-delay: 750ms;
`;

export default LoadingBar;