import { Fragment, useState } from 'react';

import styled from 'styled-components';

import Pagination from 'components/organisms/Pagination';

import { ReactComponent as Trash } from 'assets/icons/Trash.svg';


const display = 10;

const SampleRequestFormPatient = ({
  templateDefaultId,
  count, state, setState,
  isRequest, isApproval, isReceive,
  isAdmin,
  getThOptions, getThItems
}: {
  templateDefaultId: number;
  count: any; state: any; setState: any;
  isRequest: boolean; isApproval: boolean; isReceive: boolean;
  isAdmin: boolean;
  getThOptions: any; getThItems: any;
}) => {
  const section = count[templateDefaultId].sections[0];
  const thItems: any = getThItems(count[templateDefaultId][section.id]);
  const thOptions: any = getThOptions(count[templateDefaultId][section.id], thItems);

  const initPage: any = {};

  count.templates.forEach((template: any) => initPage[template.id] = 1);

  const [page, setPage] = useState(initPage);

  const onChange = ({ target, rowIndex }: { target: any; rowIndex: number; }) => {
    const key = target.name.replace('patient-item-', '');

    if (Number(target.value) > 0) {
      state.patients[templateDefaultId][rowIndex].items[key] = target.value.includes('.') ? target.value.replace('.', '') : target.value;
    } else {
      delete state.patients[templateDefaultId][rowIndex].items[key];
    }

    setState({ ...state });
  };

  const onDelete = (rowIndex: number) => {
    state.patients[templateDefaultId].splice(rowIndex, 1);

    setState({ ...state });

    if (page[templateDefaultId] > 1 && (page[templateDefaultId] - 1) * display === state.patients[templateDefaultId].length) {
      setPage(page[templateDefaultId] - 1);
    }
  };

  const onChangePagination = (e: number) => {
    page[templateDefaultId] = e;

    setPage({ ...page });
  };

  return <>
    <TemplateHeader>환자 목록</TemplateHeader>
    <Table>
      <thead>
        <TR>
          <TH rowSpan={2} width="40px" borderLeft={false}>순번</TH>
          <TH rowSpan={2} width="100px">고유번호</TH>
          <TH rowSpan={2} width="180px">병원</TH>
          {!isApproval && !isReceive
            && <TH rowSpan={2} width="60px" whiteSpace="pre-line">{`적용\n우선\n순위`}</TH>
          }
          {thOptions.map((option: any, index: number) =>
            <TH key={`key-request-patient-${section.id}-thead-group-${index}`} colSpan={option.colSpan} rowSpan={option.rowSpan}>{option.value}</TH>
          )}
          {((isRequest && isAdmin) || (isApproval && !isAdmin)) && <TH rowSpan={2} width="45px">삭제</TH>}
        </TR>
        <TR>
          {thItems.filter((item: any) => item.name !== '').map((item: any, index: number) =>
            <TH key={`key-request-patient-${section.id}-thead-${index}`}>{item.name}</TH>
          )}
        </TR>
      </thead>
      <tbody>
        {state.patients[templateDefaultId]?.map((patient: any, rowIndex: number) => (((page[templateDefaultId] - 1) * display < rowIndex + 1) && (rowIndex + 1 <= page[templateDefaultId] * display))
          && <TR key={`key-request-patient-${section.id}-tbody-${rowIndex}`}>
            <TD borderLeft={false}>{state.patients[templateDefaultId].length - rowIndex}</TD>
            <TD>{patient.code}</TD>
            <TD>{patient.hospital_name}</TD>
            {!isApproval && !isReceive
              && <TD>{patient.detail_count_position + 1}</TD>
            }
            {thItems.map((item: any, index: number) =>
              <TD key={`key-request-patient-${section.id}-tbody-${rowIndex}-${index}`}>
                {((isRequest && isAdmin) || (isApproval && !isAdmin))
                  ? <Input type="number" name={`patient-item-${item.id}`} value={patient.items[item.id] || ''} onChange={({ target }) => onChange({ target, rowIndex })} min="0" />
                  : patient.items[item.id]
                }
              </TD>
            )}
            {((isRequest && isAdmin) || (isApproval && !isAdmin))
              && <TD>
                <BtnCorrection onClick={() => onDelete(rowIndex)}><Trash /></BtnCorrection>
              </TD>
            }
          </TR>
        )}
      </tbody>
    </Table>
    <Footer>
      <Pagination total={state.patients[templateDefaultId]?.length || 0} display={display} page={page[templateDefaultId]} onChange={onChangePagination} />
    </Footer>
  </>;
};

const TemplateHeader = styled.header`
  color: #0043b7;
  font-weight: 600;
  font-size: 15px;
  line-height: 35px;
  text-align: center;
  background: #edf1fa;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 0px;
  border-top: 1px solid #dfe0e0;
`;

const TR = styled.tr`
  border-bottom: 1px solid #dfe0e0;
  &:hover { background: #f9f9f8; }
`;

const TH = styled.th<{
  width?: string;
  whiteSpace?: string;
  borderLeft?: boolean;
}>`
  text-align: center;
  color: #989899;
  background: #fafafc;
  font-size: 14px;
  width: ${({ width }) => width};
  white-space: ${({ whiteSpace }) => whiteSpace};
  border-left: ${({ borderLeft = true }) => borderLeft && '1px solid #dfe0e0'};
`;

const TD = styled.td<{
  borderLeft?: boolean;
}>`
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  height: 31px;
  border-left: ${({ borderLeft = true }) => borderLeft && '1px solid #dfe0e0'};
  &:hover { background: #f3f3f1; }
`;

const Input = styled.input`
  padding: 5px;
  background: unset;
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  width: 100%;
  height: 30px;
`;

const BtnCorrection = styled.button`
  background-color: #fff;
  outline: none;
  border: none;
`;

const Footer = styled.footer`
  margin: 30px auto;
  display: flex;
  justify-content: center;
`;

export default SampleRequestFormPatient;
