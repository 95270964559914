import PatientType from "types/patient";
import { geteGFRU25_CysC } from "./common/geteGFRU25";

const eGFRU25CystatinC = {
  id: 16,
  name: "eGFR (U25-CysC)",
  datas: ["CystatinC"],
  formula: ({ CystatinC }: any, { birthday, sex }: PatientType) => {
    if (!CystatinC || !birthday || !sex) {
      return;
    }
    const age = (Date.now() - +new Date(birthday)) / 31557600000;
    return geteGFRU25_CysC({ age, CystatinC }, sex);
  },
  help: `K2 ÷ Cystatin C
  
  *남자 K2
  15세 미만: 87.2 x 1.011^(age-15)
  15세 이상 ~ 18세 미만: 87.2 x 0.960^(age-15)
  18세 이상: 77.1
  -----------------------------------
  *여자 K2
  12세 미만: 79.9 x 1.004^(age-12)
  12세 이상 ~ 18세 미만: 79.9 x 0.974^(age-12)
  18세 이상: 68.3
  `,
};

export default eGFRU25CystatinC;
