import { Input } from "components/atoms/input";
import { ItemType, OnChangeType } from "types";

const Date = ({
  data,
  onChange,
  value,
  readOnly,
}: {
  data: ItemType;
  onChange: OnChangeType;
  value: any;
  readOnly?: boolean;
}) => {
  const { id, min, max, is_required } = data;

  if (id === 4285 || id === 4294) {
    readOnly = true;
  }

  return (
    <Input
      type="date"
      name={id + ""}
      onChange={onChange}
      value={value ?? ""}
      readOnly={readOnly}
      min={min}
      max={max || "2999-12-31"}
      small
      required={is_required === 1}
    />
  );
};

export default Date;
