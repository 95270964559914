import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useSearchParams } from 'react-router-dom';

import { getTemplate } from 'apis/template';
import { getSections } from "apis/section";

import { Sections, BtnSection, SectionLink } from "components/atoms/section";
import { SectionType } from "types";

const SectionList = () => {
  const { projectId = "", templateId = "" } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const reportingDT = searchParams.get('reportingDT');

  const { isLoading, data } = useQuery(
    ["getSections", { projectId, templateId, needValues: true }],
    () => getSections({ projectId, templateId, needValues: true })
  );

  const { isLoading: isLoadingTemplate, data: template } = useQuery(
    ["getTemplate", { templateId }],
    () => getTemplate({ templateId }),
  );

  if (isLoading || isLoadingTemplate) return <></>;
  const { name } = template;

  return (
    <Sections>
      {data.length === 0 ? (
        <p>섹션을 등록하거나 템플릿에 섹션을 추가해 주세요</p>
      ) : (
        <>
          {data.map(({ id, name, items }: SectionType) => {
            const isEmpty = items?.every(
              ({ value, valueOptions }) => !value && !valueOptions
            );
            return (
              //  신증후군 경우 진단 제외하고 섹션 클릭할때마다 이전방문일 나오게 하기 
              projectId == "674" && name !== "진단" && reportingDT ? (
                < SectionLink key={id} to={{
                  pathname: `section/${id}`,
                  search: 'reportingDT=' + reportingDT
                }}>
                  <BtnSection>
                    {isEmpty && "• "}
                    {name}
                  </BtnSection>
                </SectionLink>)
                :
                projectId === "674" && reportingDT === "null" ? (
                  < SectionLink key={id} to={{
                    pathname: `section/${id}`,
                    search: 'reportingDT=' + reportingDT
                  }}>
                    {/*  < SectionLink key={id} to={`section/${id}`}>*/}
                    <BtnSection>
                      {isEmpty && "• "}
                      {name}
                    </BtnSection>
                  </SectionLink>
                ) : (
                  < SectionLink key={id} to={`section/${id}`}>
                    <BtnSection>
                      {isEmpty && "• "}
                      {name}
                    </BtnSection>
                  </SectionLink>
                )
            );
          })}
          {projectId == "674" && name !== "진단" && reportingDT ? (
            <SectionLink to={{ pathname: "", search: 'reportingDT=' + reportingDT }} end>
              <BtnSection>전체보기</BtnSection>
            </SectionLink>
          ) :
            projectId === "674" && reportingDT === "null" ? (
              <SectionLink to="" end>
                <BtnSection>전체보기</BtnSection>
              </SectionLink>
            ) :
              (
                <SectionLink to="" end>
                  <BtnSection>전체보기</BtnSection>
                </SectionLink>
              )
          }
        </>
      )}
    </Sections>
  );
};

export default SectionList;
