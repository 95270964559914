const types = [
  {
    name: "입력칸 (단어)",
    code: "text",
  },
  {
    name: "입력칸 (문장)",
    code: "textarea",
  },
  {
    name: "숫자 + 단위",
    code: "unit",
  },
  {
    name: "라디오 버튼 (택 1)",
    code: "radio",
  },
  {
    name: "옵션 (택 1)",
    code: "select",
  },
  {
    name: "체크박스 (복수 선택)",
    code: "checkbox",
  },
  {
    name: "날짜",
    code: "date",
  },
  {
    name: "파일",
    code: "file",
  },
  {
    name: "구분선",
    code: "division",
  },
  {
    name: "계산식",
    code: "formula",
  },
];

export default types;
