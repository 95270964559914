import { TemplateHeader } from "components/atoms/common";
import { Item, Items, Name, Value } from "components/atoms/item";
import ItemHelp from "components/molecules/ItemHelp";
import { useParams } from "react-router-dom";
import PatientType from "types/patient";
import { getLocaleDate } from "utils/time";

const ViewBasic = ({ data }: { data: PatientType }) => {
  const {
    name,
    type,
    birthday,
    sex,
    note,
    hospitalName,
    code,
    adminName,
    initial_diagnosis_date,
  } = data;
  const { projectId = "" } = useParams();

  return (
    <>
      <TemplateHeader>기본 정보</TemplateHeader>
      <Items>
        <Item size={2}>
          <Name>{projectId !== ''+675 ? `환자명 (Initial)` : `닉네임`}
            {projectId === ''+675 && <ItemHelp data="서울대 등은 빈칸" />}
          </Name>
          <Value>{name}</Value>
        </Item>
        <Item size={2}>
          <Name>고유번호</Name>
          <Value>{code}</Value>
        </Item>
        <Item size={2}>
          <Name>소속병원</Name>
          <Value>{hospitalName}</Value>
        </Item>
        <Item size={2}>
          <Name>담당 연구원</Name>
          <Value>{adminName}</Value>
        </Item>
        <Item size={2}>
          <Name>타입</Name>
          <Value>{type.name}</Value>
        </Item>
        <Item size={2}>
          <Name>등록일</Name>
          <Value>
            {initial_diagnosis_date && getLocaleDate(initial_diagnosis_date)}
          </Value>
        </Item>
        <Item size={2}>
          <Name>성별</Name>
          <Value>{sex === "male" ? "남자" : "여자"}</Value>
        </Item>
        <Item size={2}>
          <Name>생년월일
          {projectId === ''+675 && <ItemHelp data="서울대 등은 일은 1일로 통일" />}
          </Name>
          <Value>{getLocaleDate(birthday)}</Value>
        </Item>
        <Item size={1}>
          <Name>비고</Name>
          <Value>{note}</Value>
        </Item>
      </Items>
    </>
  );
};

export default ViewBasic;
