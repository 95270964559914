import { OnChangeType, ItemType } from "types";

import { Textarea } from "components/atoms/input";

const ItemTextarea = ({
  data,
  onChange,
  value = "",
  readOnly,
}: {
  data: ItemType;
  onChange: OnChangeType;
  value: any;
  readOnly?: boolean;
}) => {
  const { id, is_required } = data;

  return (
    <Textarea
      name={id + ""}
      onChange={onChange}
      value={value ?? ""}
      readOnly={readOnly}
      required={is_required === 1}
    />
  );
};

export default ItemTextarea;
