import ItemForm from "./Form";
import { ItemType } from "types";
import { ModalContainer } from "components/atoms/modal";

const ModalItemPreview = ({
  onClose,
  data,
}: {
  onClose: () => void;
  data: ItemType[];
}) => {
  return (
    <ModalContainer large>
      <ItemForm data={data} onSubmit={onClose} />
    </ModalContainer>
  );
};

export default ModalItemPreview;
