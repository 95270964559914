import PatientType from "types/patient";
import getK from "./common/getK";

const eGFRNone = {
  id: 6,
  name: "estimated GFR(non-IDMS)",
  datas: ["height", "Cr_non_IDMS"],
  formula: ({ height, Cr_non_IDMS }: any, { birthday, sex }: PatientType) => {
    return (
      Math.round(((getK(birthday, sex) * height) / Cr_non_IDMS) * 100) / 100
    );
  },
  help: `eGFR=k*height(cm)÷Cr(non-IDMS)
  
  K=0.33 in preterm infants to 1.99 year of age
  K=0.45 in term infants to 1.99 year of age
  K=0.55 in children to 13.99 years of age
  K=0.55 in adolescent females
  K=0.70 in adolescent males`,
};

export default eGFRNone;
