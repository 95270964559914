import { useState } from "react";
import styled from "styled-components";

import useInputs from "hooks/useInputs";
import { login } from "apis/user";

import { Input } from "components/atoms/input";
import { ReactComponent as Id } from "assets/icons/Id.svg";
import { ReactComponent as Password } from "assets/icons/Password.svg";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [state, onChange] = useInputs({
    user_name: "",
    password: "",
  });
  const handleSubmit = async () => {
    const { token, message } = await login(state);
  if (message) return alert(message);

    localStorage.setItem("userToken", token);
    window.location.href = "/";
  };
  const onCheckEnter = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <Layout>
      <Container onKeyPress={onCheckEnter}>
        <Title>eCRF</Title>
        <InputWrap>
          <InputLogin
            placeholder="아이디"
            name="user_name"
            onChange={onChange}
          />
          <Id />
        </InputWrap>
        <InputWrap>
          <InputLogin
            placeholder="비밀번호"
            type={showPassword ? "text" : "password"}
            name="password"
            onChange={onChange}
          />
          <Password />
        </InputWrap>
        <ShowPassword>
          <input
            type="checkbox"
            onChange={({ target: { checked } }) => setShowPassword(checked)}
          />
          비밀번호 표시
        </ShowPassword>
        <BtnLogin onClick={handleSubmit}>로그인</BtnLogin>
        <AdviceNumber>문의 02-1234-1234</AdviceNumber>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Container = styled.div`
  width: 403px;
  height: 475px;
  background: #ffffff;
  border: 1px solid #dfe0e0;
  padding: 95px 61px 72px 61px;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 55px;
`;
const InputWrap = styled.div`
  position: relative;
  svg {
    position: absolute;
    left: 10px;
    top: 12.5px;
  }
`;
const InputLogin = styled(Input)`
  padding-left: 34px;
  margin-bottom: 10px;
  padding-top: 10px;
`;
const BtnLogin = styled.button`
  height: 40px;
  background: #0043b7;
  border-radius: 2px;
  border: none;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 10px;
`;
const AdviceNumber = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
`;

const ShowPassword = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
`;
export default Login;
