import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { ReactComponent as Trash } from "assets/icons/Trash.svg";
import { ReactComponent as Correction } from "assets/icons/Correction.svg";
import { deleteHospital, getHospitals } from "apis/hospital";

import { Table, TR, TH, TD } from "components/atoms/table";
import { SpaceBetween } from "components/atoms/common";
import { BtnBlue } from "components/atoms/button";
import Modal from "components/organisms/Modal";
import ModalHospital from "components/templates/admin/Hospitals/Modal";
import HospitalType from "types/hospital";

const Hospitals = () => {
  const queryClient = useQueryClient();
  const { projectId = "" } = useParams();
  const { data, isLoading } = useQuery(["getHospitals", { projectId }], () =>
    getHospitals({ projectId })
  );
  const mutation = useMutation((id: number) => deleteHospital(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getHospitals"]);
    },
  });

  const handleDelete = (id: number) => {
    if (!window.confirm("정말 삭제하시겠습니까?")) return;

    mutation.mutate(id);
  };

  if (isLoading) return <></>;

  return (
    <Container>
      <SpaceBetween>
        <Title>총 {data.length} 개</Title>
        <Modal
          activator={(onOpen) => (
            <BtnBlue onClick={onOpen}>병원 추가 +</BtnBlue>
          )}
          content={(onClose) => <ModalHospital onClose={onClose} />}
        />
      </SpaceBetween>
      <Table>
        <thead>
          <TR>
            <TH>순번</TH>
            <TH>이름</TH>
            <TH>고유번호</TH>
            <TH>next code</TH>
            <TH>관리</TH>
          </TR>
        </thead>
        <tbody>
          {data.map((hospital: HospitalType, i: number) => (
            <TR key={hospital.id}>
              <TD>{i + 1}</TD>
              <TD>{hospital.name}</TD>
              <TD>{hospital.code}</TD>
              <TD>{hospital.next_code}</TD>
              <TD>
                <Modal
                  activator={(onOpen) => (
                    <BtnCorrection>
                      <Correction onClick={onOpen} />
                    </BtnCorrection>
                  )}
                  content={(onClose) => (
                    <ModalHospital onClose={onClose} data={hospital} />
                  )}
                />
                <BtnCorrection>
                  <Trash onClick={() => handleDelete(hospital.id)} />
                </BtnCorrection>
              </TD>
            </TR>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 27px;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  flex: 1;
`;
const BtnCorrection = styled.button`
  background-color: #fff;
  outline: none;
  border: none;
`;
export default Hospitals;
