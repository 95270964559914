import PatientType from "types/patient";
import getK from "./common/getK";

const k = {
  id: 9,
  name: "k",
  datas: [],
  formula: ({}: any, { birthday, sex }: PatientType) => {
    return Math.round(getK(birthday, sex) * 100) / 100;
  },
  help: `K=0.33 in preterm infants to 1.99 year of age
K=0.45 in term infants to 1.99 year of age
K=0.55 in children to 13.99 years of age
K=0.55 in adolescent females
K=0.70 in adolescent males`,
};

export default k;
