import ResearcherType from "types/researcher";
import instance from ".";

export const getResearchers = async (params: {
  projectId: string;
  page?: number;
  display?: number;
}) => {
  const result = await instance.get(`/admins`, { params });
  return result.data;
};

export const createResearcher = async (data: ResearcherType) => {
  await instance.post(`/admins`, data);
};

export const editResearcher = async (data: ResearcherType) => {
  await instance.put(`/admins/${data.id}`, data);
};
export const patchPWResearcher = async (password: string, id: string) => {
  await instance.patch(`/admins/${id}/password`, {
    password,
  });
};
export const deleteResearcher = async (id: number) => {
  await instance.delete(`/admins/${id}`);
};
