const afterOneYear = {
  id: 37,
  name: "after 1 year",
  datas: ["start_date"],
  formula: ({ start_date }: any) => {
    if (!start_date) {
      return;
    }
    let calculatedDate = new Date(start_date);
    calculatedDate.setFullYear(calculatedDate.getFullYear() + 1);
    const year = calculatedDate.getFullYear();
    const month = calculatedDate.getMonth() + 1;
    const date = calculatedDate.getDate();
    return `${year}-${month >= 10 ? month : "0" + month}-${
      date >= 10 ? date : "0" + date
    }`;
  },
  help: `1년 후 날짜`,
};

export default afterOneYear;
