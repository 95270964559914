import { getPrednisoloneDose as getTotalDose } from "./common/getCumulativeDose";

const totalDose20 = {
  id: 42,
  name: "Total Dose (20)",
  datas: [
    "daily_dose_1",
    "daily_dose_2",
    "daily_dose_3",
    "daily_dose_4",
    "daily_dose_5",
    "daily_dose_6",
    "daily_dose_7",
    "daily_dose_8",
    "daily_dose_9",
    "daily_dose_10",
    "daily_dose_11",
    "daily_dose_12",
    "daily_dose_13",
    "daily_dose_14",
    "daily_dose_15",
    "daily_dose_16",
    "daily_dose_17",
    "daily_dose_18",
    "daily_dose_19",
    "daily_dose_20",
    "start_date_1",
    "end_date_1",
    "start_date_2",
    "end_date_2",
    "start_date_3",
    "end_date_3",
    "start_date_4",
    "end_date_4",
    "start_date_5",
    "end_date_5",
    "start_date_6",
    "end_date_6",
    "start_date_7",
    "end_date_7",
    "start_date_8",
    "end_date_8",
    "start_date_9",
    "end_date_9",
    "start_date_10",
    "end_date_10",
    "start_date_11",
    "end_date_11",
    "start_date_12",
    "end_date_12",
    "start_date_13",
    "end_date_13",
    "start_date_14",
    "end_date_14",
    "start_date_15",
    "end_date_15",
    "start_date_16",
    "end_date_16",
    "start_date_17",
    "end_date_17",
    "start_date_18",
    "end_date_18",
    "start_date_19",
    "end_date_19",
    "start_date_20",
    "end_date_20",
  ],
  formula: ({
    daily_dose_1,
    daily_dose_2,
    daily_dose_3,
    daily_dose_4,
    daily_dose_5,
    daily_dose_6,
    daily_dose_7,
    daily_dose_8,
    daily_dose_9,
    daily_dose_10,
    daily_dose_11,
    daily_dose_12,
    daily_dose_13,
    daily_dose_14,
    daily_dose_15,
    daily_dose_16,
    daily_dose_17,
    daily_dose_18,
    daily_dose_19,
    daily_dose_20,
    start_date_1,
    end_date_1,
    start_date_2,
    end_date_2,
    start_date_3,
    end_date_3,
    start_date_4,
    end_date_4,
    start_date_5,
    end_date_5,
    start_date_6,
    end_date_6,
    start_date_7,
    end_date_7,
    start_date_8,
    end_date_8,
    start_date_9,
    end_date_9,
    start_date_10,
    end_date_10,
    start_date_11,
    end_date_11,
    start_date_12,
    end_date_12,
    start_date_13,
    end_date_13,
    start_date_14,
    end_date_14,
    start_date_15,
    end_date_15,
    start_date_16,
    end_date_16,
    start_date_17,
    end_date_17,
    start_date_18,
    end_date_18,
    start_date_19,
    end_date_19,
    start_date_20,
    end_date_20,
  }: any) => {
    if (!daily_dose_1) {
      return;
    }
    return getTotalDose([
      [daily_dose_1, start_date_1, end_date_1],
      [daily_dose_2, start_date_2, end_date_2],
      [daily_dose_3, start_date_3, end_date_3],
      [daily_dose_4, start_date_4, end_date_4],
      [daily_dose_5, start_date_5, end_date_5],
      [daily_dose_6, start_date_6, end_date_6],
      [daily_dose_7, start_date_7, end_date_7],
      [daily_dose_8, start_date_8, end_date_8],
      [daily_dose_9, start_date_9, end_date_9],
      [daily_dose_10, start_date_10, end_date_10],
      [daily_dose_11, start_date_11, end_date_11],
      [daily_dose_12, start_date_12, end_date_12],
      [daily_dose_13, start_date_13, end_date_13],
      [daily_dose_14, start_date_14, end_date_14],
      [daily_dose_15, start_date_15, end_date_15],
      [daily_dose_16, start_date_16, end_date_16],
      [daily_dose_17, start_date_17, end_date_17],
      [daily_dose_18, start_date_18, end_date_18],
      [daily_dose_19, start_date_19, end_date_19],
      [daily_dose_20, start_date_20, end_date_20],
    ]);
  },
  help: `누적용량`,
};

export default totalDose20;
