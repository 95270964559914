import styled from "styled-components";
import { ReactComponent as SingleArrow } from "assets/icons/SingleArrow.svg";
import { ReactComponent as DoubleArrow } from "assets/icons/DoubleArrow.svg";

const pageLimit = 5; // 페이지 목록 당 갯수
const Pagination = ({
  total,
  display,
  page,
  onChange,
}: {
  total: number;
  display: number;
  page: number;
  onChange: (e: number) => void;
}) => {
  // TODO: 특정 페이지에서 환자 정보 조회 휘 뒤로가기 했을 때, 그 페이지로 그대로 돌아오도록

  const startPage = Math.ceil(page / pageLimit) * pageLimit - (pageLimit - 1);
  const last = Math.ceil(total / display); // page 총 갯수
  const endPage =
    startPage + pageLimit - 1 > last ? last : startPage + pageLimit - 1; // 페이지 목록의 마지막 페이지

  const pageArray = []; // 페이지 목록 배열
  for (let i = startPage; i <= endPage; i++) {
    pageArray.push(i);
  }

  // pageLimit 만큼 전으로 이동.
  const handleMorePrev = () => {
    let next = page - pageLimit;
    if (next < 1) next = 1;
    onChange(next);
  };

  // pageLimit 만큼 뒤로 이동. 마지막 페이지보다 뒤로 가개되면 마지막페이지로 이동.
  const handleMoreNext = () => {
    let next = page + pageLimit;
    if (next > last) next = last;
    onChange(next);
  };

  return (
    <Container>
      <ArrowButtonWrap left>
        <BtnArrow onClick={() => onChange(1)} disabled={page === 1}>
          <DoubleArrow />
        </BtnArrow>
        <BtnArrow onClick={handleMorePrev} disabled={page === 1}>
          <SingleArrow />
        </BtnArrow>
      </ArrowButtonWrap>
      <ButtonWrap>
        {pageArray.map((i) => (
          <Button key={i} onClick={() => onChange(i)} current={page === i}>
            {i}
          </Button>
        ))}
      </ButtonWrap>
      <ArrowButtonWrap>
        <BtnArrow
          right
          onClick={handleMoreNext}
          disabled={page === last || total === 0}
        >
          <SingleArrow />
        </BtnArrow>
        <BtnArrow
          right
          onClick={() => onChange(last)}
          disabled={page === last || total === 0}
        >
          <DoubleArrow />
        </BtnArrow>
      </ArrowButtonWrap>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 350px;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-around;
  width: 200px;
  margin: 0 auto;
`;
const ArrowButtonWrap = styled.div`
  position: absolute;
  left: ${({ left }: { left?: boolean }) => left && "0"};
  right: ${({ left }: { left?: boolean }) => !left && "0"};
`;
const Button = styled.button`
  border: none;
  background-color: #fff;
  font-size: 14px;
  color: ${({ current }: { current?: boolean }) => current && "#FF4090"};
  text-decoration-line: ${({ current }: { current?: boolean }) =>
    current && "underline"};
`;
const BtnArrow = styled.button`
  width: 22px;
  height: 22px;
  background: #f2f3f5;
  border-radius: 1px;
  border: none;
  margin: ${({ right }: { right?: boolean }) =>
    right ? "0 0 0 15px" : "0 15px 0 0"};
  &[disabled] {
    display: none;
  }
  svg {
    ${({ right }: { right?: boolean }) => right && "transform: rotate(180deg)"}
  }
`;

export default Pagination;
