type lmsType = {
  [key: string]: {
    [key: string]: ({ L: number; M: number; S: number } | null)[];
  };
};

const height = {
  male: [
    { L: 1.0, M: 49.8842, S: 0.038 },
    { L: 1.0, M: 54.7244, S: 0.0356 },
    { L: 1.0, M: 58.4249, S: 0.0342 },
    { L: 1.0, M: 61.4292, S: 0.0333 },
    { L: 1.0, M: 63.886, S: 0.0326 },
    { L: 1.0, M: 65.9026, S: 0.032 },
    { L: 1.0, M: 67.6236, S: 0.0317 },
    { L: 1.0, M: 69.1645, S: 0.0314 },
    { L: 1.0, M: 70.5994, S: 0.0312 },
    { L: 1.0, M: 71.9687, S: 0.0312 },
    { L: 1.0, M: 73.2812, S: 0.0312 },
    { L: 1.0, M: 74.5388, S: 0.0313 },
    { L: 1.0, M: 75.7488, S: 0.0314 },
    { L: 1.0, M: 76.9186, S: 0.0315 },
    { L: 1.0, M: 78.0497, S: 0.0317 },
    { L: 1.0, M: 79.1458, S: 0.032 },
    { L: 1.0, M: 80.2113, S: 0.0322 },
    { L: 1.0, M: 81.2487, S: 0.0325 },
    { L: 1.0, M: 82.2587, S: 0.0328 },
    { L: 1.0, M: 83.2418, S: 0.0331 },
    { L: 1.0, M: 84.1996, S: 0.0334 },
    { L: 1.0, M: 85.1348, S: 0.0338 },
    { L: 1.0, M: 86.0477, S: 0.0341 },
    { L: 1.0, M: 86.941, S: 0.0345 },
    { L: 1.0, M: 87.1161, S: 0.0351 },
    { L: 1.0, M: 87.972, S: 0.0354 },
    { L: 1.0, M: 88.8065, S: 0.0358 },
    { L: 1.0, M: 89.6197, S: 0.0361 },
    { L: 1.0, M: 90.412, S: 0.0364 },
    { L: 1.0, M: 91.1828, S: 0.0367 },
    { L: 1.0, M: 91.9327, S: 0.037 },
    { L: 1.0, M: 92.6631, S: 0.0373 },
    { L: 1.0, M: 93.3753, S: 0.0376 },
    { L: 1.0, M: 94.0711, S: 0.0379 },
    { L: 1.0, M: 94.7532, S: 0.0381 },
    { L: 1.0, M: 95.4236, S: 0.0384 },
    { L: -1.0915, M: 96.4961, S: 0.0403 },
    { L: -1.0011, M: 97.0464, S: 0.0403 },
    { L: -0.9126, M: 97.5965, S: 0.0402 },
    { L: -0.8262, M: 98.1463, S: 0.0402 },
    { L: -0.7418, M: 98.6959, S: 0.0402 },
    { L: -0.6592, M: 99.2452, S: 0.0402 },
    { L: -0.5827, M: 99.793, S: 0.0401 },
    { L: -0.5077, M: 100.3406, S: 0.0401 },
    { L: -0.4343, M: 100.8881, S: 0.0401 },
    { L: -0.3625, M: 101.4353, S: 0.0401 },
    { L: -0.2921, M: 101.9824, S: 0.0401 },
    { L: -0.2232, M: 102.5294, S: 0.04 },
    { L: -0.1597, M: 103.0749, S: 0.04 },
    { L: -0.0975, M: 103.6204, S: 0.04 },
    { L: -0.0364, M: 104.1657, S: 0.04 },
    { L: 0.0234, M: 104.7109, S: 0.0399 },
    { L: 0.0822, M: 105.256, S: 0.0399 },
    { L: 0.1398, M: 105.8009, S: 0.0399 },
    { L: 0.1897, M: 106.344, S: 0.0399 },
    { L: 0.2387, M: 106.8869, S: 0.0399 },
    { L: 0.2868, M: 107.4298, S: 0.0398 },
    { L: 0.3341, M: 107.9726, S: 0.0398 },
    { L: 0.3805, M: 108.5153, S: 0.0398 },
    { L: 0.4261, M: 109.0579, S: 0.0398 },
    { L: 0.4242, M: 109.5896, S: 0.0398 },
    { L: 0.4223, M: 110.1212, S: 0.0399 },
    { L: 0.4205, M: 110.6529, S: 0.0399 },
    { L: 0.4187, M: 111.1846, S: 0.0399 },
    { L: 0.4169, M: 111.7162, S: 0.04 },
    { L: 0.4151, M: 112.2479, S: 0.04 },
    { L: 0.3787, M: 112.7735, S: 0.04 },
    { L: 0.3429, M: 113.299, S: 0.0401 },
    { L: 0.3074, M: 113.8245, S: 0.0401 },
    { L: 0.2724, M: 114.3501, S: 0.0402 },
    { L: 0.2378, M: 114.8756, S: 0.0402 },
    { L: 0.2036, M: 115.401, S: 0.0403 },
    { L: 0.1783, M: 115.9183, S: 0.0403 },
    { L: 0.1533, M: 116.4356, S: 0.0404 },
    { L: 0.1287, M: 116.9528, S: 0.0404 },
    { L: 0.1044, M: 117.4701, S: 0.0405 },
    { L: 0.0805, M: 117.9874, S: 0.0405 },
    { L: 0.0569, M: 118.5047, S: 0.0406 },
    { L: 0.0563, M: 119.0136, S: 0.0406 },
    { L: 0.0557, M: 119.5225, S: 0.0406 },
    { L: 0.0552, M: 120.0314, S: 0.0406 },
    { L: 0.0546, M: 120.5404, S: 0.0406 },
    { L: 0.0541, M: 121.0493, S: 0.0406 },
    { L: 0.0536, M: 121.5582, S: 0.0406 },
    { L: 0.0492, M: 122.0537, S: 0.0406 },
    { L: 0.0448, M: 122.5492, S: 0.0406 },
    { L: 0.0405, M: 123.0447, S: 0.0406 },
    { L: 0.0363, M: 123.5402, S: 0.0406 },
    { L: 0.0321, M: 124.0357, S: 0.0406 },
    { L: 0.028, M: 124.5313, S: 0.0406 },
    { L: 0.0397, M: 125.0114, S: 0.0406 },
    { L: 0.0514, M: 125.4914, S: 0.0405 },
    { L: 0.0628, M: 125.9715, S: 0.0405 },
    { L: 0.0741, M: 126.4515, S: 0.0405 },
    { L: 0.0853, M: 126.9316, S: 0.0405 },
    { L: 0.0964, M: 127.4116, S: 0.0405 },
    { L: 0.1205, M: 127.8793, S: 0.0405 },
    { L: 0.1443, M: 128.3469, S: 0.0404 },
    { L: 0.1679, M: 128.8145, S: 0.0404 },
    { L: 0.1912, M: 129.2821, S: 0.0404 },
    { L: 0.2142, M: 129.7497, S: 0.0404 },
    { L: 0.237, M: 130.2172, S: 0.0404 },
    { L: 0.2339, M: 130.6754, S: 0.0404 },
    { L: 0.2308, M: 131.1336, S: 0.0404 },
    { L: 0.2278, M: 131.5919, S: 0.0404 },
    { L: 0.2248, M: 132.0501, S: 0.0404 },
    { L: 0.2218, M: 132.5084, S: 0.0404 },
    { L: 0.2188, M: 132.9666, S: 0.0404 },
    { L: 0.1885, M: 133.4136, S: 0.0405 },
    { L: 0.1585, M: 133.8606, S: 0.0406 },
    { L: 0.1288, M: 134.3076, S: 0.0406 },
    { L: 0.0994, M: 134.7545, S: 0.0407 },
    { L: 0.0704, M: 135.2014, S: 0.0408 },
    { L: 0.0416, M: 135.6482, S: 0.0409 },
    { L: 0.021, M: 136.1026, S: 0.041 },
    { L: 0.0008, M: 136.5569, S: 0.0411 },
    { L: -0.0191, M: 137.0113, S: 0.0412 },
    { L: -0.0386, M: 137.4656, S: 0.0414 },
    { L: -0.0579, M: 137.9199, S: 0.0415 },
    { L: -0.0768, M: 138.3742, S: 0.0416 },
    { L: -0.0752, M: 138.8473, S: 0.0417 },
    { L: -0.0736, M: 139.3205, S: 0.0419 },
    { L: -0.072, M: 139.7936, S: 0.042 },
    { L: -0.0705, M: 140.2667, S: 0.0422 },
    { L: -0.0689, M: 140.7398, S: 0.0423 },
    { L: -0.0674, M: 141.213, S: 0.0424 },
    { L: -0.0489, M: 141.7059, S: 0.0426 },
    { L: -0.0308, M: 142.1987, S: 0.0428 },
    { L: -0.0131, M: 142.6916, S: 0.043 },
    { L: 0.0041, M: 143.1844, S: 0.0432 },
    { L: 0.0209, M: 143.6773, S: 0.0434 },
    { L: 0.0373, M: 144.1701, S: 0.0436 },
    { L: 0.0886, M: 144.701, S: 0.0438 },
    { L: 0.1383, M: 145.2319, S: 0.0441 },
    { L: 0.1864, M: 145.7627, S: 0.0443 },
    { L: 0.2331, M: 146.2935, S: 0.0445 },
    { L: 0.2784, M: 146.8242, S: 0.0448 },
    { L: 0.3223, M: 147.3549, S: 0.045 },
    { L: 0.4064, M: 147.9321, S: 0.0453 },
    { L: 0.4882, M: 148.5094, S: 0.0455 },
    { L: 0.5676, M: 149.0866, S: 0.0457 },
    { L: 0.6448, M: 149.6638, S: 0.0459 },
    { L: 0.7199, M: 150.2409, S: 0.0462 },
    { L: 0.7929, M: 150.818, S: 0.0464 },
    { L: 0.8928, M: 151.4223, S: 0.0465 },
    { L: 0.991, M: 152.0268, S: 0.0466 },
    { L: 1.0877, M: 152.6312, S: 0.0467 },
    { L: 1.1829, M: 153.2358, S: 0.0468 },
    { L: 1.2764, M: 153.8404, S: 0.0469 },
    { L: 1.3684, M: 154.445, S: 0.047 },
    { L: 1.458, M: 155.0459, S: 0.0469 },
    { L: 1.5475, M: 155.6468, S: 0.0469 },
    { L: 1.637, M: 156.2477, S: 0.0468 },
    { L: 1.7263, M: 156.8487, S: 0.0467 },
    { L: 1.8155, M: 157.4497, S: 0.0466 },
    { L: 1.9045, M: 158.0507, S: 0.0465 },
    { L: 2.0111, M: 158.6245, S: 0.0463 },
    { L: 2.1194, M: 159.1984, S: 0.046 },
    { L: 2.2294, M: 159.7724, S: 0.0457 },
    { L: 2.341, M: 160.3465, S: 0.0454 },
    { L: 2.4544, M: 160.9207, S: 0.0452 },
    { L: 2.5694, M: 161.4949, S: 0.0449 },
    { L: 2.6754, M: 162.0038, S: 0.0445 },
    { L: 2.7844, M: 162.5128, S: 0.044 },
    { L: 2.8966, M: 163.0218, S: 0.0436 },
    { L: 3.0121, M: 163.5308, S: 0.0431 },
    { L: 3.1309, M: 164.0398, S: 0.0427 },
    { L: 3.2531, M: 164.5488, S: 0.0423 },
    { L: 3.3119, M: 164.965, S: 0.0417 },
    { L: 3.3725, M: 165.3813, S: 0.0412 },
    { L: 3.4351, M: 165.7975, S: 0.0407 },
    { L: 3.4998, M: 166.2138, S: 0.0402 },
    { L: 3.5666, M: 166.6301, S: 0.0397 },
    { L: 3.6357, M: 167.0464, S: 0.0392 },
    { L: 3.6315, M: 167.3647, S: 0.0388 },
    { L: 3.6262, M: 167.6831, S: 0.0384 },
    { L: 3.6198, M: 168.0013, S: 0.0379 },
    { L: 3.6121, M: 168.3195, S: 0.0375 },
    { L: 3.6031, M: 168.6377, S: 0.037 },
    { L: 3.5927, M: 168.9558, S: 0.0366 },
    { L: 3.5208, M: 169.1812, S: 0.0363 },
    { L: 3.4457, M: 169.4066, S: 0.036 },
    { L: 3.3672, M: 169.6319, S: 0.0357 },
    { L: 3.2853, M: 169.8571, S: 0.0353 },
    { L: 3.1997, M: 170.0824, S: 0.035 },
    { L: 3.1104, M: 170.3076, S: 0.0347 },
    { L: 2.9809, M: 170.4684, S: 0.0345 },
    { L: 2.8483, M: 170.6294, S: 0.0342 },
    { L: 2.7127, M: 170.7906, S: 0.034 },
    { L: 2.574, M: 170.952, S: 0.0338 },
    { L: 2.4321, M: 171.1137, S: 0.0335 },
    { L: 2.2872, M: 171.2756, S: 0.0333 },
    { L: 2.154, M: 171.3949, S: 0.0332 },
    { L: 2.0196, M: 171.5145, S: 0.033 },
    { L: 1.8841, M: 171.6344, S: 0.0328 },
    { L: 1.7474, M: 171.7546, S: 0.0327 },
    { L: 1.6096, M: 171.8752, S: 0.0325 },
    { L: 1.4707, M: 171.996, S: 0.0324 },
    { L: 1.3966, M: 172.0897, S: 0.0323 },
    { L: 1.3226, M: 172.1836, S: 0.0323 },
    { L: 1.2488, M: 172.2775, S: 0.0322 },
    { L: 1.1751, M: 172.3716, S: 0.0322 },
    { L: 1.1016, M: 172.4658, S: 0.0321 },
    { L: 1.0282, M: 172.56, S: 0.0321 },
    { L: 0.9751, M: 172.6404, S: 0.0321 },
    { L: 0.9218, M: 172.7207, S: 0.0321 },
    { L: 0.8685, M: 172.8009, S: 0.0321 },
    { L: 0.815, M: 172.8812, S: 0.0321 },
    { L: 0.7614, M: 172.9614, S: 0.0321 },
    { L: 0.7077, M: 173.0416, S: 0.0321 },
    { L: 0.6595, M: 173.1222, S: 0.0321 },
    { L: 0.611, M: 173.2027, S: 0.0321 },
    { L: 0.5623, M: 173.2832, S: 0.0321 },
    { L: 0.5134, M: 173.3636, S: 0.032 },
    { L: 0.4642, M: 173.444, S: 0.032 },
    { L: 0.4148, M: 173.5244, S: 0.032 },
    { L: 0.3638, M: 173.6037, S: 0.032 },
    { L: 0.3126, M: 173.683, S: 0.032 },
    { L: 0.2611, M: 173.7622, S: 0.032 },
    { L: 0.2094, M: 173.8413, S: 0.032 },
    { L: 0.1573, M: 173.9204, S: 0.032 },
    { L: 0.105, M: 173.9995, S: 0.032 },
    { L: 0.0449, M: 174.0764, S: 0.032 },
    { L: -0.0155, M: 174.1532, S: 0.032 },
    { L: -0.0764, M: 174.23, S: 0.032 },
    { L: -0.1377, M: 174.3067, S: 0.0319 },
    { L: -0.1994, M: 174.3833, S: 0.0319 },
    { L: -0.2615, M: 174.4598, S: 0.0319 },
  ],
  female: [
    { L: 1.0, M: 49.1477, S: 0.0379 },
    { L: 1.0, M: 53.6872, S: 0.0364 },
    { L: 1.0, M: 57.0673, S: 0.0357 },
    { L: 1.0, M: 59.8029, S: 0.0352 },
    { L: 1.0, M: 62.0899, S: 0.0349 },
    { L: 1.0, M: 64.0301, S: 0.0346 },
    { L: 1.0, M: 65.7311, S: 0.0345 },
    { L: 1.0, M: 67.2873, S: 0.0344 },
    { L: 1.0, M: 68.7498, S: 0.0344 },
    { L: 1.0, M: 70.1435, S: 0.0344 },
    { L: 1.0, M: 71.4818, S: 0.0345 },
    { L: 1.0, M: 72.771, S: 0.0346 },
    { L: 1.0, M: 74.015, S: 0.0348 },
    { L: 1.0, M: 75.2176, S: 0.035 },
    { L: 1.0, M: 76.3817, S: 0.0351 },
    { L: 1.0, M: 77.5099, S: 0.0353 },
    { L: 1.0, M: 78.6055, S: 0.0356 },
    { L: 1.0, M: 79.671, S: 0.0358 },
    { L: 1.0, M: 80.7079, S: 0.036 },
    { L: 1.0, M: 81.7182, S: 0.0362 },
    { L: 1.0, M: 82.7036, S: 0.0364 },
    { L: 1.0, M: 83.6654, S: 0.0367 },
    { L: 1.0, M: 84.604, S: 0.0369 },
    { L: 1.0, M: 85.5202, S: 0.0371 },
    { L: 1.0, M: 85.7153, S: 0.0376 },
    { L: 1.0, M: 86.5904, S: 0.0379 },
    { L: 1.0, M: 87.4462, S: 0.0381 },
    { L: 1.0, M: 88.283, S: 0.0383 },
    { L: 1.0, M: 89.1004, S: 0.0385 },
    { L: 1.0, M: 89.8991, S: 0.0387 },
    { L: 1.0, M: 90.6797, S: 0.0389 },
    { L: 1.0, M: 91.443, S: 0.0391 },
    { L: 1.0, M: 92.1906, S: 0.0393 },
    { L: 1.0, M: 92.9239, S: 0.0395 },
    { L: 1.0, M: 93.6444, S: 0.0397 },
    { L: 1.0, M: 94.3533, S: 0.0399 },
    { L: 0.5472, M: 95.4078, S: 0.0413 },
    { L: 0.4997, M: 95.9472, S: 0.0412 },
    { L: 0.4525, M: 96.4867, S: 0.0411 },
    { L: 0.4056, M: 97.0262, S: 0.041 },
    { L: 0.359, M: 97.5658, S: 0.0409 },
    { L: 0.3126, M: 98.1054, S: 0.0408 },
    { L: 0.2825, M: 98.6465, S: 0.0407 },
    { L: 0.2526, M: 99.1877, S: 0.0406 },
    { L: 0.2229, M: 99.7288, S: 0.0405 },
    { L: 0.1934, M: 100.27, S: 0.0404 },
    { L: 0.164, M: 100.8113, S: 0.0403 },
    { L: 0.1348, M: 101.3525, S: 0.0402 },
    { L: 0.1129, M: 101.8943, S: 0.0401 },
    { L: 0.0912, M: 102.4361, S: 0.04 },
    { L: 0.0695, M: 102.9779, S: 0.0399 },
    { L: 0.048, M: 103.5197, S: 0.0398 },
    { L: 0.0267, M: 104.0616, S: 0.0397 },
    { L: 0.0054, M: 104.6034, S: 0.0396 },
    { L: -0.0216, M: 105.1425, S: 0.0395 },
    { L: -0.0484, M: 105.6816, S: 0.0395 },
    { L: -0.0751, M: 106.2208, S: 0.0394 },
    { L: -0.1016, M: 106.76, S: 0.0393 },
    { L: -0.128, M: 107.2992, S: 0.0392 },
    { L: -0.1543, M: 107.8384, S: 0.0391 },
    { L: -0.1404, M: 108.3714, S: 0.039 },
    { L: -0.1266, M: 108.9045, S: 0.039 },
    { L: -0.113, M: 109.4375, S: 0.0389 },
    { L: -0.0994, M: 109.9706, S: 0.0389 },
    { L: -0.086, M: 110.5036, S: 0.0388 },
    { L: -0.0726, M: 111.0366, S: 0.0388 },
    { L: -0.0272, M: 111.5656, S: 0.0388 },
    { L: 0.0175, M: 112.0946, S: 0.0388 },
    { L: 0.0614, M: 112.6235, S: 0.0388 },
    { L: 0.1047, M: 113.1523, S: 0.0388 },
    { L: 0.1472, M: 113.6811, S: 0.0388 },
    { L: 0.1891, M: 114.2098, S: 0.0388 },
    { L: 0.2115, M: 114.7289, S: 0.0388 },
    { L: 0.2335, M: 115.2479, S: 0.0388 },
    { L: 0.255, M: 115.767, S: 0.0389 },
    { L: 0.2762, M: 116.286, S: 0.0389 },
    { L: 0.297, M: 116.805, S: 0.039 },
    { L: 0.3174, M: 117.324, S: 0.039 },
    { L: 0.2769, M: 117.8257, S: 0.0391 },
    { L: 0.2373, M: 118.3274, S: 0.0391 },
    { L: 0.1986, M: 118.8292, S: 0.0392 },
    { L: 0.1606, M: 119.331, S: 0.0393 },
    { L: 0.1235, M: 119.8329, S: 0.0394 },
    { L: 0.0872, M: 120.3348, S: 0.0394 },
    { L: 0.0163, M: 120.8229, S: 0.0396 },
    { L: -0.0528, M: 121.311, S: 0.0397 },
    { L: -0.1201, M: 121.7993, S: 0.0398 },
    { L: -0.1857, M: 122.2876, S: 0.0399 },
    { L: -0.2496, M: 122.7761, S: 0.04 },
    { L: -0.3119, M: 123.2646, S: 0.0401 },
    { L: -0.3571, M: 123.7505, S: 0.0402 },
    { L: -0.4012, M: 124.2364, S: 0.0404 },
    { L: -0.4442, M: 124.7224, S: 0.0405 },
    { L: -0.4862, M: 125.2084, S: 0.0406 },
    { L: -0.5271, M: 125.6944, S: 0.0407 },
    { L: -0.567, M: 126.1805, S: 0.0408 },
    { L: -0.5993, M: 126.6703, S: 0.041 },
    { L: -0.6308, M: 127.1601, S: 0.0411 },
    { L: -0.6616, M: 127.65, S: 0.0412 },
    { L: -0.6918, M: 128.1399, S: 0.0413 },
    { L: -0.7213, M: 128.6297, S: 0.0414 },
    { L: -0.7502, M: 129.1197, S: 0.0415 },
    { L: -0.779, M: 129.6197, S: 0.0416 },
    { L: -0.807, M: 130.1199, S: 0.0417 },
    { L: -0.8342, M: 130.62, S: 0.0418 },
    { L: -0.8608, M: 131.1202, S: 0.042 },
    { L: -0.8868, M: 131.6204, S: 0.0421 },
    { L: -0.9121, M: 132.1207, S: 0.0422 },
    { L: -0.8812, M: 132.6442, S: 0.0423 },
    { L: -0.851, M: 133.1677, S: 0.0425 },
    { L: -0.8214, M: 133.6912, S: 0.0426 },
    { L: -0.7924, M: 134.2147, S: 0.0428 },
    { L: -0.764, M: 134.7382, S: 0.0429 },
    { L: -0.7362, M: 135.2617, S: 0.043 },
    { L: -0.6545, M: 135.8116, S: 0.0432 },
    { L: -0.574, M: 136.3617, S: 0.0433 },
    { L: -0.4946, M: 136.9119, S: 0.0434 },
    { L: -0.4162, M: 137.4622, S: 0.0435 },
    { L: -0.3389, M: 138.0126, S: 0.0436 },
    { L: -0.2627, M: 138.5631, S: 0.0438 },
    { L: -0.1573, M: 139.1218, S: 0.0438 },
    { L: -0.0528, M: 139.6805, S: 0.0439 },
    { L: 0.0509, M: 140.2395, S: 0.0439 },
    { L: 0.1538, M: 140.7986, S: 0.044 },
    { L: 0.2558, M: 141.3578, S: 0.044 },
    { L: 0.3569, M: 141.9172, S: 0.0441 },
    { L: 0.4653, M: 142.4689, S: 0.044 },
    { L: 0.5738, M: 143.0207, S: 0.044 },
    { L: 0.6821, M: 143.5726, S: 0.0439 },
    { L: 0.7904, M: 144.1246, S: 0.0438 },
    { L: 0.8986, M: 144.6765, S: 0.0438 },
    { L: 1.0066, M: 145.2285, S: 0.0437 },
    { L: 1.1242, M: 145.7568, S: 0.0435 },
    { L: 1.2433, M: 146.2852, S: 0.0433 },
    { L: 1.3638, M: 146.8136, S: 0.0431 },
    { L: 1.4857, M: 147.3421, S: 0.0429 },
    { L: 1.609, M: 147.8706, S: 0.0427 },
    { L: 1.7336, M: 148.399, S: 0.0425 },
    { L: 1.8239, M: 148.8746, S: 0.0421 },
    { L: 1.9161, M: 149.3501, S: 0.0418 },
    { L: 2.0103, M: 149.8256, S: 0.0415 },
    { L: 2.1065, M: 150.3011, S: 0.0412 },
    { L: 2.2047, M: 150.7766, S: 0.0409 },
    { L: 2.3049, M: 151.2521, S: 0.0406 },
    { L: 2.3447, M: 151.6571, S: 0.0402 },
    { L: 2.3855, M: 152.0621, S: 0.0399 },
    { L: 2.4272, M: 152.4672, S: 0.0395 },
    { L: 2.4699, M: 152.8722, S: 0.0392 },
    { L: 2.5136, M: 153.2772, S: 0.0388 },
    { L: 2.5584, M: 153.6821, S: 0.0385 },
    { L: 2.5648, M: 154.0138, S: 0.0382 },
    { L: 2.5712, M: 154.3455, S: 0.0378 },
    { L: 2.5775, M: 154.6772, S: 0.0375 },
    { L: 2.5837, M: 155.0089, S: 0.0372 },
    { L: 2.5899, M: 155.3405, S: 0.0368 },
    { L: 2.5959, M: 155.6722, S: 0.0365 },
    { L: 2.5607, M: 155.9198, S: 0.0362 },
    { L: 2.5239, M: 156.1672, S: 0.036 },
    { L: 2.4856, M: 156.4146, S: 0.0357 },
    { L: 2.4458, M: 156.6619, S: 0.0355 },
    { L: 2.4042, M: 156.9091, S: 0.0352 },
    { L: 2.3609, M: 157.1562, S: 0.035 },
    { L: 2.3149, M: 157.3292, S: 0.0348 },
    { L: 2.2677, M: 157.5021, S: 0.0346 },
    { L: 2.2195, M: 157.675, S: 0.0345 },
    { L: 2.1701, M: 157.8478, S: 0.0343 },
    { L: 2.1196, M: 158.0205, S: 0.0342 },
    { L: 2.0679, M: 158.1932, S: 0.034 },
    { L: 2.0549, M: 158.3159, S: 0.0339 },
    { L: 2.0417, M: 158.4387, S: 0.0338 },
    { L: 2.0284, M: 158.5614, S: 0.0337 },
    { L: 2.015, M: 158.6842, S: 0.0336 },
    { L: 2.0014, M: 158.8069, S: 0.0335 },
    { L: 1.9877, M: 158.9297, S: 0.0335 },
    { L: 1.967, M: 159.0139, S: 0.0334 },
    { L: 1.9462, M: 159.0981, S: 0.0333 },
    { L: 1.9254, M: 159.1823, S: 0.0332 },
    { L: 1.9045, M: 159.2666, S: 0.0332 },
    { L: 1.8835, M: 159.3508, S: 0.0331 },
    { L: 1.8625, M: 159.4351, S: 0.033 },
    { L: 1.79, M: 159.4917, S: 0.033 },
    { L: 1.7178, M: 159.5483, S: 0.033 },
    { L: 1.6457, M: 159.6051, S: 0.0329 },
    { L: 1.5739, M: 159.6619, S: 0.0329 },
    { L: 1.5022, M: 159.7189, S: 0.0328 },
    { L: 1.4308, M: 159.776, S: 0.0328 },
    { L: 1.3515, M: 159.8149, S: 0.0328 },
    { L: 1.2721, M: 159.8539, S: 0.0327 },
    { L: 1.1928, M: 159.893, S: 0.0327 },
    { L: 1.1134, M: 159.9321, S: 0.0326 },
    { L: 1.034, M: 159.9714, S: 0.0326 },
    { L: 0.9547, M: 160.0107, S: 0.0325 },
    { L: 0.8678, M: 160.0286, S: 0.0325 },
    { L: 0.7803, M: 160.0465, S: 0.0324 },
    { L: 0.6921, M: 160.0644, S: 0.0323 },
    { L: 0.6032, M: 160.0823, S: 0.0322 },
    { L: 0.5137, M: 160.1002, S: 0.0322 },
    { L: 0.4236, M: 160.1182, S: 0.0321 },
    { L: 0.332, M: 160.1342, S: 0.032 },
    { L: 0.2396, M: 160.1502, S: 0.032 },
    { L: 0.1464, M: 160.1661, S: 0.0319 },
    { L: 0.0522, M: 160.1821, S: 0.0318 },
    { L: -0.0428, M: 160.198, S: 0.0317 },
    { L: -0.1387, M: 160.214, S: 0.0317 },
    { L: -0.164, M: 160.2483, S: 0.0316 },
    { L: -0.1896, M: 160.2827, S: 0.0316 },
    { L: -0.2154, M: 160.317, S: 0.0315 },
    { L: -0.2414, M: 160.3514, S: 0.0315 },
    { L: -0.2675, M: 160.3857, S: 0.0314 },
    { L: -0.294, M: 160.42, S: 0.0314 },
    { L: -0.311, M: 160.4524, S: 0.0313 },
    { L: -0.3282, M: 160.4847, S: 0.0313 },
    { L: -0.3456, M: 160.5171, S: 0.0313 },
    { L: -0.3631, M: 160.5494, S: 0.0312 },
    { L: -0.3808, M: 160.5817, S: 0.0312 },
    { L: -0.3985, M: 160.614, S: 0.0311 },
    { L: -0.4107, M: 160.6484, S: 0.0311 },
    { L: -0.4229, M: 160.6827, S: 0.031 },
    { L: -0.4352, M: 160.7171, S: 0.031 },
    { L: -0.4476, M: 160.7514, S: 0.031 },
    { L: -0.4601, M: 160.7857, S: 0.0309 },
    { L: -0.4727, M: 160.82, S: 0.0309 },
    { L: -0.4804, M: 160.8591, S: 0.0309 },
    { L: -0.4881, M: 160.8981, S: 0.0308 },
    { L: -0.4959, M: 160.9372, S: 0.0308 },
    { L: -0.5037, M: 160.9762, S: 0.0307 },
    { L: -0.5116, M: 161.0153, S: 0.0307 },
    { L: -0.5195, M: 161.0543, S: 0.0307 },
  ],
};

const weight = {
  male: [
    { L: 0.3487, M: 3.3464, S: 0.146 },
    { L: 0.2297, M: 4.4709, S: 0.134 },
    { L: 0.197, M: 5.5675, S: 0.1239 },
    { L: 0.1738, M: 6.3762, S: 0.1173 },
    { L: 0.1553, M: 7.0023, S: 0.1132 },
    { L: 0.1395, M: 7.5105, S: 0.1108 },
    { L: 0.1257, M: 7.934, S: 0.1096 },
    { L: 0.1134, M: 8.297, S: 0.109 },
    { L: 0.1021, M: 8.6151, S: 0.1088 },
    { L: 0.0917, M: 8.9014, S: 0.1088 },
    { L: 0.082, M: 9.1649, S: 0.1089 },
    { L: 0.073, M: 9.4122, S: 0.1091 },
    { L: 0.0644, M: 9.6479, S: 0.1093 },
    { L: 0.0563, M: 9.8749, S: 0.1095 },
    { L: 0.0487, M: 10.0953, S: 0.1098 },
    { L: 0.0413, M: 10.3108, S: 0.1101 },
    { L: 0.0343, M: 10.5228, S: 0.1104 },
    { L: 0.0275, M: 10.7319, S: 0.1108 },
    { L: 0.0211, M: 10.9385, S: 0.1112 },
    { L: 0.0148, M: 11.143, S: 0.1116 },
    { L: 0.0087, M: 11.3462, S: 0.1121 },
    { L: 0.0029, M: 11.5486, S: 0.1126 },
    { L: -0.0028, M: 11.7504, S: 0.1131 },
    { L: -0.0083, M: 11.9514, S: 0.1137 },
    { L: -0.0137, M: 12.1515, S: 0.1143 },
    { L: -0.0189, M: 12.3502, S: 0.1149 },
    { L: -0.024, M: 12.5466, S: 0.1154 },
    { L: -0.0289, M: 12.7401, S: 0.116 },
    { L: -0.0337, M: 12.9303, S: 0.1166 },
    { L: -0.0385, M: 13.1169, S: 0.1172 },
    { L: -0.0431, M: 13.3, S: 0.1178 },
    { L: -0.0476, M: 13.4798, S: 0.1184 },
    { L: -0.052, M: 13.6567, S: 0.119 },
    { L: -0.0564, M: 13.8309, S: 0.1195 },
    { L: -0.0606, M: 14.0031, S: 0.1201 },
    { L: -0.0648, M: 14.1736, S: 0.1206 },
    { L: 0.0567, M: 14.7381, S: 0.0969 },
    { L: -0.0147, M: 14.911, S: 0.0981 },
    { L: -0.0804, M: 15.0839, S: 0.0993 },
    { L: -0.1409, M: 15.2569, S: 0.1005 },
    { L: -0.1966, M: 15.4299, S: 0.1016 },
    { L: -0.248, M: 15.603, S: 0.1027 },
    { L: -0.299, M: 15.7775, S: 0.1038 },
    { L: -0.3461, M: 15.9521, S: 0.1049 },
    { L: -0.3895, M: 16.1268, S: 0.106 },
    { L: -0.4297, M: 16.3015, S: 0.107 },
    { L: -0.4669, M: 16.4763, S: 0.108 },
    { L: -0.5013, M: 16.6512, S: 0.1089 },
    { L: -0.5361, M: 16.8276, S: 0.1099 },
    { L: -0.5684, M: 17.0041, S: 0.1108 },
    { L: -0.5983, M: 17.1807, S: 0.1118 },
    { L: -0.6261, M: 17.3573, S: 0.1127 },
    { L: -0.6518, M: 17.534, S: 0.1136 },
    { L: -0.6758, M: 17.7108, S: 0.1144 },
    { L: -0.6998, M: 17.8888, S: 0.1153 },
    { L: -0.7222, M: 18.067, S: 0.1161 },
    { L: -0.743, M: 18.2452, S: 0.117 },
    { L: -0.7623, M: 18.4235, S: 0.1178 },
    { L: -0.7803, M: 18.6018, S: 0.1186 },
    { L: -0.7971, M: 18.7802, S: 0.1194 },
    { L: -0.8162, M: 18.9625, S: 0.1202 },
    { L: -0.8339, M: 19.1449, S: 0.1211 },
    { L: -0.8503, M: 19.3274, S: 0.1219 },
    { L: -0.8655, M: 19.51, S: 0.1227 },
    { L: -0.8797, M: 19.6926, S: 0.1235 },
    { L: -0.893, M: 19.8753, S: 0.1242 },
    { L: -0.9106, M: 20.0814, S: 0.1254 },
    { L: -0.9264, M: 20.2877, S: 0.1265 },
    { L: -0.9407, M: 20.4941, S: 0.1275 },
    { L: -0.9536, M: 20.7007, S: 0.1286 },
    { L: -0.9653, M: 20.9073, S: 0.1296 },
    { L: -0.9757, M: 21.1141, S: 0.1306 },
    { L: -0.9753, M: 21.3417, S: 0.1318 },
    { L: -0.9743, M: 21.5693, S: 0.133 },
    { L: -0.973, M: 21.797, S: 0.1342 },
    { L: -0.9714, M: 22.0247, S: 0.1353 },
    { L: -0.9694, M: 22.2524, S: 0.1364 },
    { L: -0.9672, M: 22.4802, S: 0.1375 },
    { L: -0.9576, M: 22.7267, S: 0.1388 },
    { L: -0.9481, M: 22.9732, S: 0.14 },
    { L: -0.9388, M: 23.2196, S: 0.1412 },
    { L: -0.9298, M: 23.4661, S: 0.1424 },
    { L: -0.921, M: 23.7126, S: 0.1435 },
    { L: -0.9124, M: 23.9591, S: 0.1447 },
    { L: -0.8993, M: 24.2214, S: 0.146 },
    { L: -0.8867, M: 24.4837, S: 0.1474 },
    { L: -0.8745, M: 24.7461, S: 0.1486 },
    { L: -0.8628, M: 25.0084, S: 0.1499 },
    { L: -0.8514, M: 25.2707, S: 0.1511 },
    { L: -0.8404, M: 25.5329, S: 0.1524 },
    { L: -0.8208, M: 25.8155, S: 0.1537 },
    { L: -0.802, M: 26.0979, S: 0.155 },
    { L: -0.7841, M: 26.3804, S: 0.1563 },
    { L: -0.7669, M: 26.6629, S: 0.1576 },
    { L: -0.7503, M: 26.9453, S: 0.1588 },
    { L: -0.7344, M: 27.2277, S: 0.16 },
    { L: -0.7076, M: 27.5321, S: 0.1613 },
    { L: -0.682, M: 27.8365, S: 0.1625 },
    { L: -0.6574, M: 28.1409, S: 0.1638 },
    { L: -0.6339, M: 28.4453, S: 0.165 },
    { L: -0.6113, M: 28.7497, S: 0.1661 },
    { L: -0.5896, M: 29.054, S: 0.1673 },
    { L: -0.5621, M: 29.3761, S: 0.1685 },
    { L: -0.5357, M: 29.6982, S: 0.1698 },
    { L: -0.5103, M: 30.0203, S: 0.171 },
    { L: -0.486, M: 30.3424, S: 0.1721 },
    { L: -0.4627, M: 30.6645, S: 0.1733 },
    { L: -0.4402, M: 30.9867, S: 0.1744 },
    { L: -0.416, M: 31.3251, S: 0.1756 },
    { L: -0.3928, M: 31.6635, S: 0.1768 },
    { L: -0.3705, M: 32.0019, S: 0.1779 },
    { L: -0.3491, M: 32.3404, S: 0.179 },
    { L: -0.3284, M: 32.6788, S: 0.1801 },
    { L: -0.3085, M: 33.0173, S: 0.1812 },
    { L: -0.2869, M: 33.3742, S: 0.1823 },
    { L: -0.2661, M: 33.7312, S: 0.1833 },
    { L: -0.2461, M: 34.0882, S: 0.1844 },
    { L: -0.2267, M: 34.4452, S: 0.1854 },
    { L: -0.2081, M: 34.8022, S: 0.1864 },
    { L: -0.1902, M: 35.1592, S: 0.1873 },
    { L: -0.17, M: 35.5349, S: 0.1882 },
    { L: -0.1505, M: 35.9105, S: 0.189 },
    { L: -0.1317, M: 36.2862, S: 0.1899 },
    { L: -0.1134, M: 36.6619, S: 0.1907 },
    { L: -0.0958, M: 37.0376, S: 0.1914 },
    { L: -0.0788, M: 37.4134, S: 0.1922 },
    { L: -0.0612, M: 37.8103, S: 0.1929 },
    { L: -0.0441, M: 38.2074, S: 0.1936 },
    { L: -0.0275, M: 38.6045, S: 0.1942 },
    { L: -0.0113, M: 39.0017, S: 0.1949 },
    { L: 0.0043, M: 39.3989, S: 0.1955 },
    { L: 0.0195, M: 39.7961, S: 0.1961 },
    { L: 0.0333, M: 40.2146, S: 0.1966 },
    { L: 0.0467, M: 40.6331, S: 0.1971 },
    { L: 0.0599, M: 41.0518, S: 0.1976 },
    { L: 0.0726, M: 41.4706, S: 0.1981 },
    { L: 0.0851, M: 41.8894, S: 0.1986 },
    { L: 0.0973, M: 42.3083, S: 0.1991 },
    { L: 0.1071, M: 42.7515, S: 0.1992 },
    { L: 0.1168, M: 43.1947, S: 0.1994 },
    { L: 0.1263, M: 43.638, S: 0.1996 },
    { L: 0.1356, M: 44.0813, S: 0.1997 },
    { L: 0.1448, M: 44.5248, S: 0.1999 },
    { L: 0.1538, M: 44.9684, S: 0.2 },
    { L: 0.1618, M: 45.4253, S: 0.1998 },
    { L: 0.1698, M: 45.8824, S: 0.1995 },
    { L: 0.1777, M: 46.3396, S: 0.1993 },
    { L: 0.1855, M: 46.7968, S: 0.1991 },
    { L: 0.1932, M: 47.2542, S: 0.1988 },
    { L: 0.2009, M: 47.7116, S: 0.1986 },
    { L: 0.2081, M: 48.1693, S: 0.198 },
    { L: 0.2154, M: 48.6271, S: 0.1974 },
    { L: 0.2226, M: 49.085, S: 0.1969 },
    { L: 0.2299, M: 49.5431, S: 0.1963 },
    { L: 0.2372, M: 50.0012, S: 0.1957 },
    { L: 0.2445, M: 50.4595, S: 0.1952 },
    { L: 0.2503, M: 50.9021, S: 0.1942 },
    { L: 0.2562, M: 51.3448, S: 0.1932 },
    { L: 0.2622, M: 51.7875, S: 0.1923 },
    { L: 0.2683, M: 52.2304, S: 0.1914 },
    { L: 0.2745, M: 52.6733, S: 0.1904 },
    { L: 0.2808, M: 53.1164, S: 0.1895 },
    { L: 0.2841, M: 53.5329, S: 0.1882 },
    { L: 0.2876, M: 53.9495, S: 0.1869 },
    { L: 0.2911, M: 54.366, S: 0.1856 },
    { L: 0.2948, M: 54.7826, S: 0.1843 },
    { L: 0.2986, M: 55.1993, S: 0.183 },
    { L: 0.3026, M: 55.6159, S: 0.1818 },
    { L: 0.3023, M: 55.9927, S: 0.1802 },
    { L: 0.302, M: 56.3694, S: 0.1787 },
    { L: 0.3019, M: 56.7462, S: 0.1772 },
    { L: 0.3019, M: 57.1229, S: 0.1757 },
    { L: 0.3019, M: 57.4995, S: 0.1742 },
    { L: 0.3021, M: 57.8762, S: 0.1727 },
    { L: 0.2993, M: 58.2029, S: 0.1711 },
    { L: 0.2965, M: 58.5296, S: 0.1696 },
    { L: 0.2938, M: 58.8563, S: 0.168 },
    { L: 0.2911, M: 59.1829, S: 0.1665 },
    { L: 0.2885, M: 59.5096, S: 0.165 },
    { L: 0.2859, M: 59.8362, S: 0.1635 },
    { L: 0.2818, M: 60.1106, S: 0.162 },
    { L: 0.2777, M: 60.385, S: 0.1606 },
    { L: 0.2736, M: 60.6594, S: 0.1591 },
    { L: 0.2695, M: 60.9338, S: 0.1577 },
    { L: 0.2654, M: 61.2081, S: 0.1563 },
    { L: 0.2613, M: 61.4825, S: 0.1549 },
    { L: 0.2514, M: 61.7129, S: 0.1537 },
    { L: 0.2412, M: 61.9434, S: 0.1525 },
    { L: 0.231, M: 62.1739, S: 0.1513 },
    { L: 0.2207, M: 62.4045, S: 0.1501 },
    { L: 0.2102, M: 62.6351, S: 0.1489 },
    { L: 0.1996, M: 62.8658, S: 0.1477 },
    { L: 0.1829, M: 63.055, S: 0.1468 },
    { L: 0.166, M: 63.2442, S: 0.1459 },
    { L: 0.1489, M: 63.4335, S: 0.145 },
    { L: 0.1316, M: 63.6229, S: 0.1441 },
    { L: 0.1141, M: 63.8123, S: 0.1432 },
    { L: 0.0964, M: 64.0018, S: 0.1424 },
    { L: 0.0862, M: 64.1526, S: 0.1418 },
    { L: 0.0759, M: 64.3033, S: 0.1413 },
    { L: 0.0655, M: 64.4539, S: 0.1407 },
    { L: 0.055, M: 64.6045, S: 0.1402 },
    { L: 0.0444, M: 64.7551, S: 0.1397 },
    { L: 0.0337, M: 64.9057, S: 0.1391 },
    { L: 0.0282, M: 65.0469, S: 0.1387 },
    { L: 0.0226, M: 65.188, S: 0.1382 },
    { L: 0.0169, M: 65.3291, S: 0.1377 },
    { L: 0.0113, M: 65.4702, S: 0.1373 },
    { L: 0.0055, M: 65.6113, S: 0.1368 },
    { L: -0.0003, M: 65.7523, S: 0.1364 },
    { L: -0.0068, M: 65.8907, S: 0.1359 },
    { L: -0.0134, M: 66.0291, S: 0.1355 },
    { L: -0.02, M: 66.1674, S: 0.1351 },
    { L: -0.0267, M: 66.3057, S: 0.1346 },
    { L: -0.0335, M: 66.4439, S: 0.1342 },
    { L: -0.0403, M: 66.5821, S: 0.1338 },
    { L: -0.0469, M: 66.7186, S: 0.1334 },
    { L: -0.0535, M: 66.8551, S: 0.133 },
    { L: -0.0602, M: 66.9915, S: 0.1326 },
    { L: -0.0669, M: 67.1278, S: 0.1322 },
    { L: -0.0738, M: 67.2641, S: 0.1318 },
    { L: -0.0807, M: 67.4004, S: 0.1314 },
    { L: -0.0859, M: 67.5362, S: 0.1311 },
    { L: -0.0911, M: 67.6719, S: 0.1307 },
    { L: -0.0965, M: 67.8077, S: 0.1303 },
    { L: -0.1018, M: 67.9434, S: 0.1299 },
    { L: -0.1072, M: 68.079, S: 0.1296 },
    { L: -0.1127, M: 68.2146, S: 0.1292 },
  ],
  female: [
    { L: 0.3809, M: 3.2322, S: 0.1417 },
    { L: 0.1714, M: 4.1873, S: 0.1372 },
    { L: 0.0962, M: 5.1282, S: 0.13 },
    { L: 0.0402, M: 5.8458, S: 0.1262 },
    { L: -0.005, M: 6.4237, S: 0.124 },
    { L: -0.043, M: 6.8985, S: 0.1227 },
    { L: -0.0756, M: 7.297, S: 0.122 },
    { L: -0.1039, M: 7.6422, S: 0.1218 },
    { L: -0.1288, M: 7.9487, S: 0.1218 },
    { L: -0.1507, M: 8.2254, S: 0.122 },
    { L: -0.17, M: 8.48, S: 0.1222 },
    { L: -0.1872, M: 8.7192, S: 0.1225 },
    { L: -0.2024, M: 8.9481, S: 0.1227 },
    { L: -0.2158, M: 9.1699, S: 0.1228 },
    { L: -0.2278, M: 9.387, S: 0.1229 },
    { L: -0.2384, M: 9.6008, S: 0.123 },
    { L: -0.2478, M: 9.8124, S: 0.123 },
    { L: -0.2562, M: 10.0226, S: 0.1231 },
    { L: -0.2637, M: 10.2315, S: 0.1231 },
    { L: -0.2703, M: 10.4393, S: 0.1232 },
    { L: -0.2762, M: 10.6464, S: 0.1232 },
    { L: -0.2815, M: 10.8534, S: 0.1234 },
    { L: -0.2862, M: 11.0608, S: 0.1235 },
    { L: -0.2903, M: 11.2688, S: 0.1237 },
    { L: -0.2941, M: 11.4775, S: 0.1239 },
    { L: -0.2975, M: 11.6864, S: 0.1241 },
    { L: -0.3005, M: 11.8947, S: 0.1244 },
    { L: -0.3032, M: 12.1015, S: 0.1247 },
    { L: -0.3057, M: 12.3059, S: 0.1251 },
    { L: -0.308, M: 12.5073, S: 0.1255 },
    { L: -0.3101, M: 12.7055, S: 0.1259 },
    { L: -0.312, M: 12.9006, S: 0.1263 },
    { L: -0.3138, M: 13.093, S: 0.1268 },
    { L: -0.3155, M: 13.2837, S: 0.1274 },
    { L: -0.3171, M: 13.4731, S: 0.1279 },
    { L: -0.3186, M: 13.6618, S: 0.1286 },
    { L: 0.5656, M: 14.1998, S: 0.0991 },
    { L: 0.4441, M: 14.3701, S: 0.1004 },
    { L: 0.3317, M: 14.5405, S: 0.1017 },
    { L: 0.2276, M: 14.7108, S: 0.103 },
    { L: 0.1312, M: 14.8813, S: 0.1042 },
    { L: 0.0419, M: 15.0518, S: 0.1053 },
    { L: -0.0428, M: 15.2236, S: 0.1064 },
    { L: -0.1212, M: 15.3956, S: 0.1075 },
    { L: -0.1938, M: 15.5676, S: 0.1085 },
    { L: -0.2611, M: 15.7399, S: 0.1095 },
    { L: -0.3236, M: 15.9123, S: 0.1105 },
    { L: -0.3816, M: 16.0848, S: 0.1114 },
    { L: -0.4372, M: 16.2585, S: 0.1124 },
    { L: -0.4888, M: 16.4324, S: 0.1133 },
    { L: -0.5367, M: 16.6064, S: 0.1142 },
    { L: -0.5812, M: 16.7806, S: 0.115 },
    { L: -0.6227, M: 16.9549, S: 0.1159 },
    { L: -0.6613, M: 17.1294, S: 0.1167 },
    { L: -0.6987, M: 17.3046, S: 0.1175 },
    { L: -0.7335, M: 17.48, S: 0.1183 },
    { L: -0.766, M: 17.6555, S: 0.119 },
    { L: -0.7962, M: 17.8311, S: 0.1198 },
    { L: -0.8245, M: 18.0069, S: 0.1205 },
    { L: -0.8509, M: 18.1827, S: 0.1212 },
    { L: -0.876, M: 18.3616, S: 0.122 },
    { L: -0.8993, M: 18.5405, S: 0.1228 },
    { L: -0.921, M: 18.7196, S: 0.1236 },
    { L: -0.9412, M: 18.8988, S: 0.1243 },
    { L: -0.96, M: 19.078, S: 0.125 },
    { L: -0.9776, M: 19.2574, S: 0.1257 },
    { L: -0.984, M: 19.4555, S: 0.127 },
    { L: -0.9894, M: 19.6537, S: 0.1281 },
    { L: -0.9941, M: 19.8519, S: 0.1293 },
    { L: -0.9981, M: 20.0502, S: 0.1304 },
    { L: -1.0014, M: 20.2485, S: 0.1315 },
    { L: -1.0041, M: 20.4468, S: 0.1326 },
    { L: -0.9954, M: 20.6619, S: 0.134 },
    { L: -0.9868, M: 20.8769, S: 0.1353 },
    { L: -0.9784, M: 21.092, S: 0.1367 },
    { L: -0.97, M: 21.3071, S: 0.138 },
    { L: -0.9618, M: 21.5221, S: 0.1392 },
    { L: -0.9537, M: 21.7372, S: 0.1405 },
    { L: -0.9411, M: 21.9702, S: 0.1418 },
    { L: -0.9288, M: 22.2032, S: 0.1432 },
    { L: -0.9169, M: 22.4363, S: 0.1445 },
    { L: -0.9054, M: 22.6693, S: 0.1458 },
    { L: -0.8942, M: 22.9023, S: 0.1471 },
    { L: -0.8833, M: 23.1353, S: 0.1484 },
    { L: -0.8685, M: 23.387, S: 0.1497 },
    { L: -0.8541, M: 23.6386, S: 0.1511 },
    { L: -0.8403, M: 23.8903, S: 0.1524 },
    { L: -0.8271, M: 24.1419, S: 0.1536 },
    { L: -0.8142, M: 24.3935, S: 0.1549 },
    { L: -0.8019, M: 24.6451, S: 0.1561 },
    { L: -0.7852, M: 24.9161, S: 0.1574 },
    { L: -0.7693, M: 25.1871, S: 0.1586 },
    { L: -0.7539, M: 25.4581, S: 0.1598 },
    { L: -0.7392, M: 25.7291, S: 0.1609 },
    { L: -0.725, M: 26.0001, S: 0.1621 },
    { L: -0.7114, M: 26.271, S: 0.1632 },
    { L: -0.6971, M: 26.5602, S: 0.1643 },
    { L: -0.6834, M: 26.8493, S: 0.1654 },
    { L: -0.6702, M: 27.1385, S: 0.1665 },
    { L: -0.6574, M: 27.4276, S: 0.1675 },
    { L: -0.6451, M: 27.7167, S: 0.1685 },
    { L: -0.6333, M: 28.0058, S: 0.1695 },
    { L: -0.6214, M: 28.3163, S: 0.1705 },
    { L: -0.6098, M: 28.6269, S: 0.1714 },
    { L: -0.5987, M: 28.9374, S: 0.1723 },
    { L: -0.5879, M: 29.248, S: 0.1732 },
    { L: -0.5775, M: 29.5585, S: 0.1741 },
    { L: -0.5675, M: 29.869, S: 0.1749 },
    { L: -0.5521, M: 30.2033, S: 0.1758 },
    { L: -0.5373, M: 30.5375, S: 0.1766 },
    { L: -0.5229, M: 30.8718, S: 0.1774 },
    { L: -0.509, M: 31.2061, S: 0.1782 },
    { L: -0.4956, M: 31.5404, S: 0.179 },
    { L: -0.4825, M: 31.8748, S: 0.1798 },
    { L: -0.4623, M: 32.2323, S: 0.1806 },
    { L: -0.4427, M: 32.59, S: 0.1815 },
    { L: -0.4237, M: 32.9478, S: 0.1823 },
    { L: -0.4052, M: 33.3056, S: 0.1831 },
    { L: -0.3874, M: 33.6636, S: 0.1838 },
    { L: -0.37, M: 34.0216, S: 0.1846 },
    { L: -0.3496, M: 34.4014, S: 0.1852 },
    { L: -0.3297, M: 34.7815, S: 0.1858 },
    { L: -0.3103, M: 35.1617, S: 0.1864 },
    { L: -0.2914, M: 35.5421, S: 0.1869 },
    { L: -0.2729, M: 35.9226, S: 0.1875 },
    { L: -0.2548, M: 36.3033, S: 0.188 },
    { L: -0.2377, M: 36.7011, S: 0.1882 },
    { L: -0.2209, M: 37.0992, S: 0.1883 },
    { L: -0.2043, M: 37.4974, S: 0.1884 },
    { L: -0.188, M: 37.8958, S: 0.1885 },
    { L: -0.1719, M: 38.2944, S: 0.1886 },
    { L: -0.156, M: 38.6931, S: 0.1887 },
    { L: -0.1419, M: 39.0908, S: 0.1884 },
    { L: -0.1278, M: 39.4887, S: 0.1881 },
    { L: -0.1138, M: 39.8868, S: 0.1878 },
    { L: -0.0998, M: 40.2851, S: 0.1875 },
    { L: -0.0859, M: 40.6835, S: 0.1872 },
    { L: -0.072, M: 41.0821, S: 0.1869 },
    { L: -0.0641, M: 41.4657, S: 0.1862 },
    { L: -0.0562, M: 41.8495, S: 0.1855 },
    { L: -0.0481, M: 42.2333, S: 0.1848 },
    { L: -0.04, M: 42.6173, S: 0.1842 },
    { L: -0.0318, M: 43.0013, S: 0.1835 },
    { L: -0.0235, M: 43.3855, S: 0.1829 },
    { L: -0.0241, M: 43.74, S: 0.1818 },
    { L: -0.0246, M: 44.0946, S: 0.1808 },
    { L: -0.0251, M: 44.4491, S: 0.1798 },
    { L: -0.0255, M: 44.8037, S: 0.1788 },
    { L: -0.0258, M: 45.1584, S: 0.1778 },
    { L: -0.026, M: 45.513, S: 0.1769 },
    { L: -0.0326, M: 45.8261, S: 0.1756 },
    { L: -0.0391, M: 46.1391, S: 0.1743 },
    { L: -0.0457, M: 46.4522, S: 0.173 },
    { L: -0.0523, M: 46.7652, S: 0.1717 },
    { L: -0.0589, M: 47.0782, S: 0.1705 },
    { L: -0.0655, M: 47.3912, S: 0.1693 },
    { L: -0.0745, M: 47.6564, S: 0.1679 },
    { L: -0.0837, M: 47.9216, S: 0.1666 },
    { L: -0.0929, M: 48.1868, S: 0.1653 },
    { L: -0.1021, M: 48.4519, S: 0.164 },
    { L: -0.1115, M: 48.7171, S: 0.1627 },
    { L: -0.1208, M: 48.9822, S: 0.1614 },
    { L: -0.129, M: 49.2069, S: 0.1603 },
    { L: -0.1372, M: 49.4315, S: 0.1591 },
    { L: -0.1455, M: 49.6562, S: 0.158 },
    { L: -0.1538, M: 49.8809, S: 0.1568 },
    { L: -0.1621, M: 50.1055, S: 0.1557 },
    { L: -0.1705, M: 50.3302, S: 0.1546 },
    { L: -0.1734, M: 50.5233, S: 0.1536 },
    { L: -0.1763, M: 50.7164, S: 0.1525 },
    { L: -0.1791, M: 50.9095, S: 0.1515 },
    { L: -0.1819, M: 51.1026, S: 0.1505 },
    { L: -0.1846, M: 51.2957, S: 0.1495 },
    { L: -0.1873, M: 51.4888, S: 0.1485 },
    { L: -0.1869, M: 51.6479, S: 0.1476 },
    { L: -0.1865, M: 51.807, S: 0.1467 },
    { L: -0.186, M: 51.966, S: 0.1458 },
    { L: -0.1854, M: 52.125, S: 0.1449 },
    { L: -0.1847, M: 52.284, S: 0.144 },
    { L: -0.1839, M: 52.443, S: 0.1431 },
    { L: -0.1846, M: 52.5631, S: 0.1424 },
    { L: -0.1852, M: 52.6832, S: 0.1417 },
    { L: -0.1857, M: 52.8033, S: 0.1409 },
    { L: -0.1862, M: 52.9233, S: 0.1402 },
    { L: -0.1867, M: 53.0434, S: 0.1395 },
    { L: -0.1871, M: 53.1634, S: 0.1388 },
    { L: -0.1901, M: 53.2482, S: 0.1382 },
    { L: -0.193, M: 53.3331, S: 0.1377 },
    { L: -0.196, M: 53.4179, S: 0.1371 },
    { L: -0.199, M: 53.5027, S: 0.1366 },
    { L: -0.2019, M: 53.5875, S: 0.1361 },
    { L: -0.2049, M: 53.6723, S: 0.1355 },
    { L: -0.21, M: 53.7253, S: 0.1351 },
    { L: -0.2151, M: 53.7783, S: 0.1346 },
    { L: -0.2202, M: 53.8313, S: 0.1342 },
    { L: -0.2253, M: 53.8843, S: 0.1338 },
    { L: -0.2305, M: 53.9373, S: 0.1334 },
    { L: -0.2357, M: 53.9903, S: 0.1329 },
    { L: -0.2499, M: 54.0055, S: 0.1326 },
    { L: -0.2643, M: 54.0208, S: 0.1323 },
    { L: -0.2788, M: 54.036, S: 0.1319 },
    { L: -0.2934, M: 54.0512, S: 0.1316 },
    { L: -0.3081, M: 54.0663, S: 0.1312 },
    { L: -0.323, M: 54.0815, S: 0.1309 },
    { L: -0.3423, M: 54.0797, S: 0.1306 },
    { L: -0.3619, M: 54.0778, S: 0.1303 },
    { L: -0.3816, M: 54.0759, S: 0.13 },
    { L: -0.4016, M: 54.0739, S: 0.1298 },
    { L: -0.4217, M: 54.0719, S: 0.1295 },
    { L: -0.4421, M: 54.0698, S: 0.1292 },
    { L: -0.4627, M: 54.0634, S: 0.1289 },
    { L: -0.4836, M: 54.057, S: 0.1286 },
    { L: -0.5046, M: 54.0505, S: 0.1284 },
    { L: -0.5259, M: 54.0439, S: 0.1281 },
    { L: -0.5474, M: 54.0373, S: 0.1278 },
    { L: -0.5691, M: 54.0306, S: 0.1275 },
    { L: -0.5908, M: 54.0217, S: 0.1273 },
    { L: -0.6128, M: 54.0126, S: 0.127 },
    { L: -0.6351, M: 54.0036, S: 0.1267 },
    { L: -0.6575, M: 53.9944, S: 0.1264 },
    { L: -0.6801, M: 53.9852, S: 0.1261 },
    { L: -0.703, M: 53.976, S: 0.1258 },
    { L: -0.7262, M: 53.9649, S: 0.1256 },
    { L: -0.7497, M: 53.9539, S: 0.1253 },
    { L: -0.7734, M: 53.9427, S: 0.125 },
    { L: -0.7973, M: 53.9315, S: 0.1247 },
    { L: -0.8215, M: 53.9203, S: 0.1244 },
    { L: -0.8458, M: 53.909, S: 0.1241 },
  ],
};

const bmi = {
  male: [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    { L: -0.6187, M: 16.0189, S: 0.0779 },
    { L: -0.584, M: 15.98, S: 0.0779 },
    { L: -0.5497, M: 15.9414, S: 0.078 },
    { L: -0.5166, M: 15.9036, S: 0.0781 },
    { L: -0.485, M: 15.8667, S: 0.0782 },
    { L: -0.4552, M: 15.8306, S: 0.0783 },
    { L: -0.4274, M: 15.7953, S: 0.0784 },
    { L: -0.4016, M: 15.7606, S: 0.0785 },
    { L: -0.3782, M: 15.7267, S: 0.0787 },
    { L: -0.3572, M: 15.6934, S: 0.0788 },
    { L: -0.3388, M: 15.661, S: 0.079 },
    { L: -0.3231, M: 15.6294, S: 0.0791 },
    { L: 1.8637, M: 15.9455, S: 0.0665 },
    { L: 1.7284, M: 15.9431, S: 0.0672 },
    { L: 1.5969, M: 15.9406, S: 0.0679 },
    { L: 1.4694, M: 15.9381, S: 0.0686 },
    { L: 1.3456, M: 15.9355, S: 0.0692 },
    { L: 1.2257, M: 15.9328, S: 0.0699 },
    { L: 1.1096, M: 15.9302, S: 0.0706 },
    { L: 0.9972, M: 15.9275, S: 0.0712 },
    { L: 0.8886, M: 15.9248, S: 0.0719 },
    { L: 0.7835, M: 15.9222, S: 0.0725 },
    { L: 0.6821, M: 15.9195, S: 0.0731 },
    { L: 0.5841, M: 15.9169, S: 0.0737 },
    { L: 0.4885, M: 15.9165, S: 0.0744 },
    { L: 0.3966, M: 15.9162, S: 0.075 },
    { L: 0.3084, M: 15.916, S: 0.0757 },
    { L: 0.2237, M: 15.9158, S: 0.0763 },
    { L: 0.1425, M: 15.9156, S: 0.0769 },
    { L: 0.0647, M: 15.9156, S: 0.0775 },
    { L: -0.01, M: 15.9156, S: 0.0781 },
    { L: -0.0815, M: 15.9156, S: 0.0788 },
    { L: -0.15, M: 15.9158, S: 0.0793 },
    { L: -0.2156, M: 15.916, S: 0.0799 },
    { L: -0.2784, M: 15.9163, S: 0.0805 },
    { L: -0.3385, M: 15.9167, S: 0.0811 },
    { L: -0.3931, M: 15.9217, S: 0.0818 },
    { L: -0.4449, M: 15.9268, S: 0.0824 },
    { L: -0.4942, M: 15.932, S: 0.0831 },
    { L: -0.5409, M: 15.9373, S: 0.0838 },
    { L: -0.5853, M: 15.9426, S: 0.0844 },
    { L: -0.6274, M: 15.948, S: 0.0851 },
    { L: -0.6673, M: 15.9535, S: 0.0857 },
    { L: -0.7053, M: 15.9591, S: 0.0863 },
    { L: -0.7413, M: 15.9647, S: 0.087 },
    { L: -0.7754, M: 15.9704, S: 0.0876 },
    { L: -0.8078, M: 15.9762, S: 0.0883 },
    { L: -0.8385, M: 15.982, S: 0.0889 },
    { L: -0.8494, M: 16.01, S: 0.0899 },
    { L: -0.859, M: 16.038, S: 0.0909 },
    { L: -0.8675, M: 16.0661, S: 0.0919 },
    { L: -0.8749, M: 16.0942, S: 0.0929 },
    { L: -0.8814, M: 16.1223, S: 0.0939 },
    { L: -0.8869, M: 16.1504, S: 0.0948 },
    { L: -0.8916, M: 16.1786, S: 0.0958 },
    { L: -0.8955, M: 16.2068, S: 0.0968 },
    { L: -0.8987, M: 16.235, S: 0.0978 },
    { L: -0.9013, M: 16.2633, S: 0.0987 },
    { L: -0.9032, M: 16.2916, S: 0.0997 },
    { L: -0.9046, M: 16.3199, S: 0.1007 },
    { L: -0.8944, M: 16.3645, S: 0.1018 },
    { L: -0.8842, M: 16.4092, S: 0.1029 },
    { L: -0.8741, M: 16.4538, S: 0.104 },
    { L: -0.8639, M: 16.4985, S: 0.1051 },
    { L: -0.8538, M: 16.5431, S: 0.1062 },
    { L: -0.8438, M: 16.5878, S: 0.1073 },
    { L: -0.8339, M: 16.6324, S: 0.1084 },
    { L: -0.824, M: 16.6771, S: 0.1095 },
    { L: -0.8142, M: 16.7218, S: 0.1105 },
    { L: -0.8044, M: 16.7664, S: 0.1116 },
    { L: -0.7948, M: 16.8111, S: 0.1127 },
    { L: -0.7852, M: 16.8558, S: 0.1137 },
    { L: -0.7555, M: 16.9147, S: 0.1148 },
    { L: -0.7269, M: 16.9736, S: 0.1159 },
    { L: -0.6992, M: 17.0325, S: 0.117 },
    { L: -0.6725, M: 17.0914, S: 0.118 },
    { L: -0.6466, M: 17.1503, S: 0.1191 },
    { L: -0.6216, M: 17.2092, S: 0.1201 },
    { L: -0.5974, M: 17.268, S: 0.1212 },
    { L: -0.574, M: 17.3268, S: 0.1222 },
    { L: -0.5513, M: 17.3856, S: 0.1232 },
    { L: -0.5293, M: 17.4444, S: 0.1242 },
    { L: -0.508, M: 17.5032, S: 0.1252 },
    { L: -0.4874, M: 17.562, S: 0.1262 },
    { L: -0.4597, M: 17.6255, S: 0.1271 },
    { L: -0.4327, M: 17.689, S: 0.128 },
    { L: -0.4066, M: 17.7524, S: 0.1289 },
    { L: -0.3812, M: 17.8159, S: 0.1297 },
    { L: -0.3566, M: 17.8793, S: 0.1306 },
    { L: -0.3326, M: 17.9428, S: 0.1314 },
    { L: -0.3093, M: 18.0062, S: 0.1323 },
    { L: -0.2867, M: 18.0696, S: 0.1331 },
    { L: -0.2647, M: 18.133, S: 0.1339 },
    { L: -0.2433, M: 18.1964, S: 0.1348 },
    { L: -0.2225, M: 18.2598, S: 0.1356 },
    { L: -0.2022, M: 18.3231, S: 0.1364 },
    { L: -0.1828, M: 18.3862, S: 0.137 },
    { L: -0.1638, M: 18.4493, S: 0.1375 },
    { L: -0.1452, M: 18.5123, S: 0.1381 },
    { L: -0.127, M: 18.5754, S: 0.1387 },
    { L: -0.1092, M: 18.6385, S: 0.1392 },
    { L: -0.0918, M: 18.7015, S: 0.1398 },
    { L: -0.0747, M: 18.7645, S: 0.1403 },
    { L: -0.058, M: 18.8276, S: 0.1409 },
    { L: -0.0416, M: 18.8906, S: 0.1414 },
    { L: -0.0256, M: 18.9536, S: 0.142 },
    { L: -0.0099, M: 19.0166, S: 0.1425 },
    { L: 0.0055, M: 19.0796, S: 0.143 },
    { L: 0.014, M: 19.1356, S: 0.1432 },
    { L: 0.0224, M: 19.1917, S: 0.1435 },
    { L: 0.0307, M: 19.2478, S: 0.1437 },
    { L: 0.039, M: 19.3039, S: 0.1439 },
    { L: 0.0471, M: 19.3599, S: 0.1441 },
    { L: 0.0552, M: 19.416, S: 0.1443 },
    { L: 0.0631, M: 19.4721, S: 0.1445 },
    { L: 0.071, M: 19.5282, S: 0.1447 },
    { L: 0.0789, M: 19.5842, S: 0.1449 },
    { L: 0.0866, M: 19.6403, S: 0.1451 },
    { L: 0.0942, M: 19.6964, S: 0.1453 },
    { L: 0.1018, M: 19.7525, S: 0.1455 },
    { L: 0.1009, M: 19.7966, S: 0.1454 },
    { L: 0.1, M: 19.8408, S: 0.1453 },
    { L: 0.0991, M: 19.8849, S: 0.1451 },
    { L: 0.0981, M: 19.9291, S: 0.145 },
    { L: 0.0972, M: 19.9732, S: 0.1449 },
    { L: 0.0963, M: 20.0173, S: 0.1447 },
    { L: 0.0954, M: 20.0615, S: 0.1446 },
    { L: 0.0945, M: 20.1056, S: 0.1445 },
    { L: 0.0936, M: 20.1498, S: 0.1443 },
    { L: 0.0927, M: 20.1939, S: 0.1442 },
    { L: 0.0918, M: 20.2381, S: 0.1441 },
    { L: 0.0909, M: 20.2822, S: 0.1439 },
    { L: 0.0879, M: 20.3193, S: 0.1435 },
    { L: 0.0849, M: 20.3564, S: 0.143 },
    { L: 0.0819, M: 20.3934, S: 0.1426 },
    { L: 0.0789, M: 20.4305, S: 0.1422 },
    { L: 0.0759, M: 20.4676, S: 0.1417 },
    { L: 0.0729, M: 20.5047, S: 0.1413 },
    { L: 0.0698, M: 20.5417, S: 0.1408 },
    { L: 0.0668, M: 20.5788, S: 0.1404 },
    { L: 0.0637, M: 20.6159, S: 0.14 },
    { L: 0.0607, M: 20.6529, S: 0.1395 },
    { L: 0.0576, M: 20.69, S: 0.1391 },
    { L: 0.0545, M: 20.7271, S: 0.1387 },
    { L: 0.0551, M: 20.7616, S: 0.1381 },
    { L: 0.0558, M: 20.7961, S: 0.1375 },
    { L: 0.0564, M: 20.8306, S: 0.1369 },
    { L: 0.0572, M: 20.865, S: 0.1363 },
    { L: 0.0579, M: 20.8995, S: 0.1357 },
    { L: 0.0587, M: 20.934, S: 0.1351 },
    { L: 0.0596, M: 20.9685, S: 0.1345 },
    { L: 0.0605, M: 21.003, S: 0.1339 },
    { L: 0.0614, M: 21.0375, S: 0.1333 },
    { L: 0.0624, M: 21.0719, S: 0.1327 },
    { L: 0.0635, M: 21.1064, S: 0.1321 },
    { L: 0.0645, M: 21.1409, S: 0.1315 },
    { L: 0.0686, M: 21.174, S: 0.131 },
    { L: 0.0728, M: 21.2071, S: 0.1305 },
    { L: 0.077, M: 21.2402, S: 0.1299 },
    { L: 0.0814, M: 21.2733, S: 0.1294 },
    { L: 0.0858, M: 21.3064, S: 0.1289 },
    { L: 0.0904, M: 21.3395, S: 0.1284 },
    { L: 0.095, M: 21.3726, S: 0.1279 },
    { L: 0.0997, M: 21.4057, S: 0.1274 },
    { L: 0.1046, M: 21.4389, S: 0.1269 },
    { L: 0.1095, M: 21.472, S: 0.1263 },
    { L: 0.1146, M: 21.5051, S: 0.1258 },
    { L: 0.1198, M: 21.5383, S: 0.1253 },
    { L: 0.1283, M: 21.5696, S: 0.125 },
    { L: 0.1369, M: 21.6009, S: 0.1246 },
    { L: 0.1457, M: 21.6322, S: 0.1242 },
    { L: 0.1546, M: 21.6636, S: 0.1239 },
    { L: 0.1637, M: 21.6949, S: 0.1235 },
    { L: 0.1729, M: 21.7263, S: 0.1232 },
    { L: 0.1822, M: 21.7577, S: 0.1228 },
    { L: 0.1916, M: 21.7891, S: 0.1225 },
    { L: 0.2013, M: 21.8205, S: 0.1221 },
    { L: 0.211, M: 21.852, S: 0.1217 },
    { L: 0.2209, M: 21.8834, S: 0.1214 },
    { L: 0.2309, M: 21.9149, S: 0.121 },
    { L: 0.2417, M: 21.9445, S: 0.1207 },
    { L: 0.2526, M: 21.9741, S: 0.1204 },
    { L: 0.2637, M: 22.0038, S: 0.1201 },
    { L: 0.2749, M: 22.0334, S: 0.1198 },
    { L: 0.2863, M: 22.0631, S: 0.1195 },
    { L: 0.2978, M: 22.0928, S: 0.1192 },
    { L: 0.3095, M: 22.1225, S: 0.1189 },
    { L: 0.3213, M: 22.1523, S: 0.1186 },
    { L: 0.3334, M: 22.1821, S: 0.1183 },
    { L: 0.3455, M: 22.2119, S: 0.1179 },
    { L: 0.3579, M: 22.2417, S: 0.1176 },
    { L: 0.3704, M: 22.2715, S: 0.1173 },
    { L: 0.3824, M: 22.3005, S: 0.117 },
    { L: 0.3944, M: 22.3295, S: 0.1168 },
    { L: 0.4067, M: 22.3585, S: 0.1165 },
    { L: 0.4191, M: 22.3875, S: 0.1162 },
    { L: 0.4317, M: 22.4166, S: 0.1159 },
    { L: 0.4445, M: 22.4457, S: 0.1156 },
    { L: 0.4574, M: 22.4748, S: 0.1153 },
    { L: 0.4705, M: 22.5039, S: 0.115 },
    { L: 0.4837, M: 22.533, S: 0.1147 },
    { L: 0.4971, M: 22.5622, S: 0.1144 },
    { L: 0.5107, M: 22.5914, S: 0.1142 },
    { L: 0.5245, M: 22.6206, S: 0.1139 },
  ],
  female: [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    { L: -0.5684, M: 15.6881, S: 0.0845 },
    { L: -0.5684, M: 15.659, S: 0.0845 },
    { L: -0.5684, M: 15.6308, S: 0.0845 },
    { L: -0.5684, M: 15.6037, S: 0.0845 },
    { L: -0.5684, M: 15.5777, S: 0.0844 },
    { L: -0.5684, M: 15.5523, S: 0.0844 },
    { L: -0.5684, M: 15.5276, S: 0.0844 },
    { L: -0.5684, M: 15.5034, S: 0.0845 },
    { L: -0.5684, M: 15.4798, S: 0.0846 },
    { L: -0.5684, M: 15.4572, S: 0.0847 },
    { L: -0.5684, M: 15.4356, S: 0.0848 },
    { L: -0.5684, M: 15.4155, S: 0.0851 },
    { L: 1.7658, M: 15.7543, S: 0.0677 },
    { L: 1.6248, M: 15.7529, S: 0.0685 },
    { L: 1.4878, M: 15.7514, S: 0.0694 },
    { L: 1.3549, M: 15.7497, S: 0.0702 },
    { L: 1.2261, M: 15.7478, S: 0.071 },
    { L: 1.1013, M: 15.7459, S: 0.0718 },
    { L: 0.9805, M: 15.7438, S: 0.0726 },
    { L: 0.8636, M: 15.7417, S: 0.0734 },
    { L: 0.7507, M: 15.7395, S: 0.0741 },
    { L: 0.6416, M: 15.7373, S: 0.0749 },
    { L: 0.5363, M: 15.735, S: 0.0757 },
    { L: 0.4348, M: 15.7327, S: 0.0764 },
    { L: 0.3375, M: 15.7321, S: 0.0772 },
    { L: 0.2441, M: 15.7315, S: 0.0779 },
    { L: 0.1545, M: 15.7309, S: 0.0787 },
    { L: 0.0685, M: 15.7303, S: 0.0794 },
    { L: -0.0139, M: 15.7298, S: 0.0801 },
    { L: -0.0928, M: 15.7293, S: 0.0808 },
    { L: -0.1684, M: 15.7288, S: 0.0815 },
    { L: -0.2408, M: 15.7284, S: 0.0822 },
    { L: -0.31, M: 15.728, S: 0.0829 },
    { L: -0.3762, M: 15.7277, S: 0.0836 },
    { L: -0.4395, M: 15.7275, S: 0.0843 },
    { L: -0.5, M: 15.7273, S: 0.085 },
    { L: -0.5537, M: 15.7308, S: 0.0857 },
    { L: -0.6047, M: 15.7343, S: 0.0865 },
    { L: -0.6531, M: 15.7379, S: 0.0872 },
    { L: -0.699, M: 15.7416, S: 0.0879 },
    { L: -0.7425, M: 15.7453, S: 0.0886 },
    { L: -0.7838, M: 15.7491, S: 0.0894 },
    { L: -0.8229, M: 15.753, S: 0.0901 },
    { L: -0.86, M: 15.757, S: 0.0908 },
    { L: -0.8951, M: 15.761, S: 0.0915 },
    { L: -0.9283, M: 15.7651, S: 0.0922 },
    { L: -0.9598, M: 15.7692, S: 0.0929 },
    { L: -0.9896, M: 15.7734, S: 0.0936 },
    { L: -0.9929, M: 15.7972, S: 0.0946 },
    { L: -0.9953, M: 15.8211, S: 0.0956 },
    { L: -0.997, M: 15.845, S: 0.0966 },
    { L: -0.998, M: 15.8689, S: 0.0976 },
    { L: -0.9984, M: 15.8928, S: 0.0986 },
    { L: -0.9983, M: 15.9168, S: 0.0995 },
    { L: -0.9976, M: 15.9408, S: 0.1005 },
    { L: -0.9964, M: 15.9648, S: 0.1015 },
    { L: -0.9948, M: 15.9888, S: 0.1025 },
    { L: -0.9927, M: 16.0129, S: 0.1034 },
    { L: -0.9903, M: 16.0369, S: 0.1044 },
    { L: -0.9876, M: 16.061, S: 0.1054 },
    { L: -0.9677, M: 16.1003, S: 0.1064 },
    { L: -0.9484, M: 16.1396, S: 0.1073 },
    { L: -0.9296, M: 16.1788, S: 0.1083 },
    { L: -0.9112, M: 16.2181, S: 0.1093 },
    { L: -0.8933, M: 16.2573, S: 0.1103 },
    { L: -0.8759, M: 16.2966, S: 0.1113 },
    { L: -0.8589, M: 16.3358, S: 0.1122 },
    { L: -0.8423, M: 16.375, S: 0.1132 },
    { L: -0.8261, M: 16.4142, S: 0.1142 },
    { L: -0.8103, M: 16.4534, S: 0.1151 },
    { L: -0.7948, M: 16.4926, S: 0.1161 },
    { L: -0.7797, M: 16.5318, S: 0.117 },
    { L: -0.7556, M: 16.5815, S: 0.1179 },
    { L: -0.7322, M: 16.6312, S: 0.1187 },
    { L: -0.7095, M: 16.6809, S: 0.1196 },
    { L: -0.6873, M: 16.7305, S: 0.1204 },
    { L: -0.6657, M: 16.7802, S: 0.1212 },
    { L: -0.6447, M: 16.8298, S: 0.1221 },
    { L: -0.6242, M: 16.8795, S: 0.1229 },
    { L: -0.6042, M: 16.9291, S: 0.1237 },
    { L: -0.5848, M: 16.9787, S: 0.1245 },
    { L: -0.5658, M: 17.0283, S: 0.1253 },
    { L: -0.5474, M: 17.0779, S: 0.1261 },
    { L: -0.5293, M: 17.1275, S: 0.1269 },
    { L: -0.5108, M: 17.1807, S: 0.1276 },
    { L: -0.4927, M: 17.2339, S: 0.1282 },
    { L: -0.4749, M: 17.287, S: 0.1289 },
    { L: -0.4575, M: 17.3402, S: 0.1295 },
    { L: -0.4405, M: 17.3934, S: 0.1302 },
    { L: -0.4238, M: 17.4466, S: 0.1308 },
    { L: -0.4075, M: 17.4998, S: 0.1314 },
    { L: -0.3915, M: 17.5529, S: 0.1321 },
    { L: -0.3758, M: 17.6061, S: 0.1327 },
    { L: -0.3604, M: 17.6593, S: 0.1333 },
    { L: -0.3453, M: 17.7125, S: 0.1339 },
    { L: -0.3305, M: 17.7657, S: 0.1346 },
    { L: -0.3196, M: 17.8202, S: 0.1349 },
    { L: -0.3087, M: 17.8747, S: 0.1353 },
    { L: -0.2981, M: 17.9293, S: 0.1356 },
    { L: -0.2875, M: 17.9838, S: 0.136 },
    { L: -0.2771, M: 18.0383, S: 0.1363 },
    { L: -0.2668, M: 18.0929, S: 0.1367 },
    { L: -0.2566, M: 18.1475, S: 0.137 },
    { L: -0.2465, M: 18.202, S: 0.1374 },
    { L: -0.2366, M: 18.2566, S: 0.1377 },
    { L: -0.2268, M: 18.3112, S: 0.138 },
    { L: -0.217, M: 18.3658, S: 0.1384 },
    { L: -0.2074, M: 18.4204, S: 0.1387 },
    { L: -0.2055, M: 18.4741, S: 0.1388 },
    { L: -0.2036, M: 18.5278, S: 0.1388 },
    { L: -0.2018, M: 18.5815, S: 0.1388 },
    { L: -0.1999, M: 18.6352, S: 0.1388 },
    { L: -0.198, M: 18.6889, S: 0.1389 },
    { L: -0.1962, M: 18.7426, S: 0.1389 },
    { L: -0.1943, M: 18.7963, S: 0.1389 },
    { L: -0.1925, M: 18.8499, S: 0.139 },
    { L: -0.1906, M: 18.9036, S: 0.139 },
    { L: -0.1888, M: 18.9573, S: 0.139 },
    { L: -0.187, M: 19.011, S: 0.1391 },
    { L: -0.1852, M: 19.0648, S: 0.1391 },
    { L: -0.1841, M: 19.1168, S: 0.1388 },
    { L: -0.1829, M: 19.1689, S: 0.1386 },
    { L: -0.1817, M: 19.221, S: 0.1383 },
    { L: -0.1805, M: 19.273, S: 0.138 },
    { L: -0.1793, M: 19.3251, S: 0.1378 },
    { L: -0.1781, M: 19.3772, S: 0.1375 },
    { L: -0.1769, M: 19.4293, S: 0.1372 },
    { L: -0.1756, M: 19.4814, S: 0.137 },
    { L: -0.1743, M: 19.5335, S: 0.1367 },
    { L: -0.173, M: 19.5856, S: 0.1364 },
    { L: -0.1717, M: 19.6377, S: 0.1362 },
    { L: -0.1703, M: 19.6898, S: 0.1359 },
    { L: -0.1641, M: 19.7377, S: 0.1355 },
    { L: -0.1578, M: 19.7856, S: 0.135 },
    { L: -0.1514, M: 19.8335, S: 0.1346 },
    { L: -0.1449, M: 19.8814, S: 0.1341 },
    { L: -0.1382, M: 19.9293, S: 0.1337 },
    { L: -0.1314, M: 19.9772, S: 0.1332 },
    { L: -0.1246, M: 20.0252, S: 0.1328 },
    { L: -0.1176, M: 20.0731, S: 0.1323 },
    { L: -0.1104, M: 20.1211, S: 0.1319 },
    { L: -0.1032, M: 20.1691, S: 0.1314 },
    { L: -0.0958, M: 20.2171, S: 0.131 },
    { L: -0.0884, M: 20.2651, S: 0.1305 },
    { L: -0.0802, M: 20.3049, S: 0.13 },
    { L: -0.0719, M: 20.3446, S: 0.1295 },
    { L: -0.0635, M: 20.3844, S: 0.1289 },
    { L: -0.0549, M: 20.4242, S: 0.1284 },
    { L: -0.0461, M: 20.464, S: 0.1278 },
    { L: -0.0371, M: 20.5038, S: 0.1273 },
    { L: -0.028, M: 20.5436, S: 0.1268 },
    { L: -0.0187, M: 20.5834, S: 0.1262 },
    { L: -0.0092, M: 20.6232, S: 0.1257 },
    { L: 0.0004, M: 20.663, S: 0.1252 },
    { L: 0.0102, M: 20.7029, S: 0.1246 },
    { L: 0.0202, M: 20.7427, S: 0.1241 },
    { L: 0.023, M: 20.7675, S: 0.1236 },
    { L: 0.0259, M: 20.7923, S: 0.1232 },
    { L: 0.0288, M: 20.817, S: 0.1227 },
    { L: 0.0318, M: 20.8418, S: 0.1222 },
    { L: 0.0349, M: 20.8666, S: 0.1218 },
    { L: 0.038, M: 20.8914, S: 0.1213 },
    { L: 0.0412, M: 20.9162, S: 0.1208 },
    { L: 0.0445, M: 20.941, S: 0.1204 },
    { L: 0.0478, M: 20.9657, S: 0.1199 },
    { L: 0.0512, M: 20.9905, S: 0.1195 },
    { L: 0.0547, M: 21.0153, S: 0.119 },
    { L: 0.0583, M: 21.0401, S: 0.1186 },
    { L: 0.045, M: 21.0433, S: 0.1183 },
    { L: 0.0315, M: 21.0465, S: 0.118 },
    { L: 0.018, M: 21.0498, S: 0.1177 },
    { L: 0.0043, M: 21.053, S: 0.1175 },
    { L: -0.0095, M: 21.0562, S: 0.1172 },
    { L: -0.0235, M: 21.0593, S: 0.1169 },
    { L: -0.0375, M: 21.0625, S: 0.1166 },
    { L: -0.0517, M: 21.0657, S: 0.1164 },
    { L: -0.0661, M: 21.0689, S: 0.1161 },
    { L: -0.0806, M: 21.072, S: 0.1158 },
    { L: -0.0952, M: 21.0752, S: 0.1155 },
    { L: -0.1099, M: 21.0783, S: 0.1152 },
    { L: -0.1287, M: 21.0754, S: 0.115 },
    { L: -0.1476, M: 21.0724, S: 0.1148 },
    { L: -0.1667, M: 21.0694, S: 0.1146 },
    { L: -0.1859, M: 21.0664, S: 0.1144 },
    { L: -0.2054, M: 21.0634, S: 0.1142 },
    { L: -0.225, M: 21.0603, S: 0.114 },
    { L: -0.2448, M: 21.0573, S: 0.1138 },
    { L: -0.2648, M: 21.0542, S: 0.1136 },
    { L: -0.285, M: 21.0511, S: 0.1134 },
    { L: -0.3053, M: 21.048, S: 0.1132 },
    { L: -0.3258, M: 21.0449, S: 0.113 },
    { L: -0.3465, M: 21.0417, S: 0.1128 },
    { L: -0.368, M: 21.0362, S: 0.1126 },
    { L: -0.3897, M: 21.0307, S: 0.1124 },
    { L: -0.4115, M: 21.0251, S: 0.1122 },
    { L: -0.4336, M: 21.0196, S: 0.112 },
    { L: -0.4558, M: 21.014, S: 0.1119 },
    { L: -0.4781, M: 21.0084, S: 0.1117 },
    { L: -0.5007, M: 21.0028, S: 0.1115 },
    { L: -0.5234, M: 20.9972, S: 0.1113 },
    { L: -0.5463, M: 20.9916, S: 0.1111 },
    { L: -0.5694, M: 20.986, S: 0.1109 },
    { L: -0.5927, M: 20.9803, S: 0.1107 },
    { L: -0.6161, M: 20.9747, S: 0.1105 },
    { L: -0.1846, M: 52.5631, S: 0.1424 },
    { L: -0.1852, M: 52.6832, S: 0.1417 },
    { L: -0.1857, M: 52.8033, S: 0.1409 },
    { L: -0.1862, M: 52.9233, S: 0.1402 },
    { L: -0.1867, M: 53.0434, S: 0.1395 },
    { L: -0.1871, M: 53.1634, S: 0.1388 },
    { L: -0.1901, M: 53.2482, S: 0.1382 },
    { L: -0.193, M: 53.3331, S: 0.1377 },
    { L: -0.196, M: 53.4179, S: 0.1371 },
    { L: -0.199, M: 53.5027, S: 0.1366 },
    { L: -0.2019, M: 53.5875, S: 0.1361 },
    { L: -0.2049, M: 53.6723, S: 0.1355 },
    { L: -0.21, M: 53.7253, S: 0.1351 },
    { L: -0.2151, M: 53.7783, S: 0.1346 },
    { L: -0.2202, M: 53.8313, S: 0.1342 },
    { L: -0.2253, M: 53.8843, S: 0.1338 },
    { L: -0.2305, M: 53.9373, S: 0.1334 },
    { L: -0.2357, M: 53.9903, S: 0.1329 },
    { L: -0.2499, M: 54.0055, S: 0.1326 },
    { L: -0.2643, M: 54.0208, S: 0.1323 },
    { L: -0.2788, M: 54.036, S: 0.1319 },
    { L: -0.2934, M: 54.0512, S: 0.1316 },
    { L: -0.3081, M: 54.0663, S: 0.1312 },
    { L: -0.323, M: 54.0815, S: 0.1309 },
    { L: -0.3423, M: 54.0797, S: 0.1306 },
    { L: -0.3619, M: 54.0778, S: 0.1303 },
    { L: -0.3816, M: 54.0759, S: 0.13 },
    { L: -0.4016, M: 54.0739, S: 0.1298 },
    { L: -0.4217, M: 54.0719, S: 0.1295 },
    { L: -0.4421, M: 54.0698, S: 0.1292 },
    { L: -0.4627, M: 54.0634, S: 0.1289 },
    { L: -0.4836, M: 54.057, S: 0.1286 },
    { L: -0.5046, M: 54.0505, S: 0.1284 },
    { L: -0.5259, M: 54.0439, S: 0.1281 },
    { L: -0.5474, M: 54.0373, S: 0.1278 },
    { L: -0.5691, M: 54.0306, S: 0.1275 },
    { L: -0.5908, M: 54.0217, S: 0.1273 },
    { L: -0.6128, M: 54.0126, S: 0.127 },
    { L: -0.6351, M: 54.0036, S: 0.1267 },
    { L: -0.6575, M: 53.9944, S: 0.1264 },
    { L: -0.6801, M: 53.9852, S: 0.1261 },
    { L: -0.703, M: 53.976, S: 0.1258 },
    { L: -0.7262, M: 53.9649, S: 0.1256 },
    { L: -0.7497, M: 53.9539, S: 0.1253 },
    { L: -0.7734, M: 53.9427, S: 0.125 },
    { L: -0.7973, M: 53.9315, S: 0.1247 },
    { L: -0.8215, M: 53.9203, S: 0.1244 },
    { L: -0.8458, M: 53.909, S: 0.1241 },
  ],
};

const datas: lmsType = { height, weight, bmi };

export default datas;
