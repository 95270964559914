import styled from "styled-components";

import { getLocaleDate } from "utils/time";

import imgCode from "assets/icons/patient/code.png";
import imgHospital from "assets/icons/patient/hospital.png";
import imgSex from "assets/icons/patient/sex.png";
import imgBirthday from "assets/icons/patient/birthday.png";
import imgStudy from "assets/icons/patient/study.png";

interface Props {
  data: {
    name: string;
    type: { name: string };
    code: string;
    hospitalName: string;
    sex: string;
    birthday: string;
    study: { title: string; value: string };
  };
}

const Info = ({ data }: Props) => {
  const { name, type, code, hospitalName, sex, birthday, study } = data;

  return (
    <Container>
      <Top>
        <Name>{name}</Name>
        <Type>{type.name}</Type>
      </Top>
      <Main>
        <Item>
          <Icon src={imgCode} />
          <Right>
            <Title>고유번호</Title>
            <Content>{code}</Content>
          </Right>
        </Item>
        <Item>
          <Icon src={imgHospital} />
          <Right>
            <Title>기관명</Title>
            <Content>{hospitalName}</Content>
          </Right>
        </Item>
        <Item>
          <Icon src={imgSex} />
          <Right>
            <Title>성별</Title>
            <Content>{sex === "male" ? "남성" : "여성"}</Content>
          </Right>
        </Item>
        <Item>
          <Icon src={imgBirthday} />
          <Right>
            <Title>생년월일</Title>
            <Content>{getLocaleDate(birthday)}</Content>
          </Right>
        </Item>
        <Item>
          <Icon src={imgStudy} />
          <Right>
            <Title>임상 배정</Title>
            <Content>
              {study.title} - {study.value}
            </Content>
          </Right>
        </Item>
      </Main>
    </Container>
  );
};

const Container = styled.div`
  background: #f7f8fa;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Name = styled.span`
  font-weight: 700;
  font-size: 24px;
`;
const Type = styled.div`
  border: 1px solid #c7c7c7;
  border-radius: 18px;
  color: #989899;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  line-height: 17px;
  padding: 0 10px;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
`;
const Main = styled.div`
  margin-top: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(22, 49, 103, 0.07);
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(5, minmax(190px, 1fr));
  padding: 20px;
`;
const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;
  & + & {
    :before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 35px;
      background: #e4e6e6;
      width: 1px;
    }
  }
`;
const Icon = styled.img``;
const Right = styled.div`
  margin-left: 12px;
`;
const Title = styled.div`
  color: #989899;
  font-size: 12px;
`;
const Content = styled.div`
  margin-top: 6px;
`;

export default Info;
