import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { editSection, createSection } from "apis/section";

import { BtnPink } from "components/atoms/button";
import { SpaceBetween } from "components/atoms/common";
import { Item, Items, Name, Value } from "components/atoms/item";
import { ModalContainer } from "components/atoms/modal";
import { Input } from "components/atoms/input";
import { useMutation, useQueryClient } from "react-query";
import { SectionType } from "types";

const ModalSection = ({
  data,
  onClose,
}: {
  data?: SectionType;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { projectId = "" } = useParams();
  const [name, setName] = useState(data?.name ?? "");
  const [position, setPosition] = useState(data?.position ?? 0);
  const mutation = useMutation(
    () =>
      data
        ? editSection({ id: data.id, name, position })
        : createSection({ projectId, name }),
    {
      onSuccess: ({ id }) => {
        queryClient.invalidateQueries(["getSections"]);
        onClose();
        if (id) {
          navigate(`/admin/project/${projectId}/section/${id}`);
        }
      },
    }
  );

  const handleSubmit = async () => {
    if (mutation.isLoading) return;
    if (!name) return alert("이름을 입력하세요.");
    mutation.mutate();
  };

  return (
    <ModalContainer>
      <Title>섹션 {data ? "수정" : "생성"}</Title>
      <Items>
        <Item>
          <Name>이름</Name>
          <Value>
            <Input
              placeholder="이름"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Value>
        </Item>
        {data && (
          <Item>
            <Name>순서</Name>
            <Value>
              <Input
                small
                type="number"
                placeholder="순서"
                value={position}
                onChange={(e) => setPosition(+e.target.value)}
              />
            </Value>
          </Item>
        )}
      </Items>
      <SpaceBetween>
        <div></div>
        <BtnPink onClick={handleSubmit} large>
          저장
        </BtnPink>
      </SpaceBetween>
    </ModalContainer>
  );
};

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
`;
export default ModalSection;
