const afterSixMonths = {
  id: 36,
  name: "after 6 months",
  datas: ["start_date"],
  formula: ({ start_date }: any) => {
    if (!start_date) {
      return;
    }
    let calculatedDate = new Date(start_date);
    calculatedDate.setMonth(calculatedDate.getMonth() + 6);
    const year = calculatedDate.getFullYear();
    const month = calculatedDate.getMonth() + 1;
    const date = calculatedDate.getDate();
    return `${year}-${month >= 10 ? month : "0" + month}-${
      date >= 10 ? date : "0" + date
    }`;
  },
  help: `6개월 후 날짜`,
};

export default afterSixMonths;
