import getPeriod from "./common/dateCalculator";

const totalDaysofUse = {
  id: 25,
  name: "Total days of use",
  datas: [
    "start_date_1",
    "end_date_1",
    "start_date_2",
    "end_date_2",
    "start_date_3",
    "end_date_3",
    "start_date_4",
    "end_date_4",
    "start_date_5",
    "end_date_5",
    "start_date_6",
    "end_date_6",
    "start_date_7",
    "end_date_7",
    "start_date_8",
    "end_date_8",
    "start_date_9",
    "end_date_9",
    "start_date_10",
    "end_date_10",
    "start_date_11",
    "end_date_11",
    "start_date_12",
    "end_date_12",
    "start_date_13",
    "end_date_13",
    "start_date_14",
    "end_date_14",
    "start_date_15",
    "end_date_15",
    "start_date_16",
    "end_date_16",
    "start_date_17",
    "end_date_17",
    "start_date_18",
    "end_date_18",
    "start_date_19",
    "end_date_19",
    "start_date_20",
    "end_date_20",
    "start_date_21",
    "end_date_21",
    "start_date_22",
    "end_date_22",
    "start_date_23",
    "end_date_23",
    "start_date_24",
    "end_date_24",
    "start_date_25",
    "end_date_25",
    "start_date_26",
    "end_date_26",
    "start_date_27",
    "end_date_27",
    "start_date_28",
    "end_date_28",
    "start_date_29",
    "end_date_29",
    "start_date_30",
    "end_date_30",
    "start_date_31",
    "end_date_31",
    "start_date_32",
    "end_date_32",
    "start_date_33",
    "end_date_33",
    "start_date_34",
    "end_date_34",
    "start_date_35",
    "end_date_35",
    "start_date_36",
    "end_date_36",
    "start_date_37",
    "end_date_37",
    "start_date_38",
    "end_date_38",
    "start_date_39",
    "end_date_39",
    "start_date_40",
    "end_date_40",
    "start_date_41",
    "end_date_41",
    "start_date_42",
    "end_date_42",
    "start_date_43",
    "end_date_43",
    "start_date_44",
    "end_date_44",
    "start_date_45",
    "end_date_45",
    "start_date_46",
    "end_date_46",
    "start_date_47",
    "end_date_47",
    "start_date_48",
    "end_date_48",
    "start_date_49",
    "end_date_49",
    "start_date_50",
    "end_date_50",
    "start_date_51",
    "end_date_51",
    "start_date_52",
    "end_date_52",
    "start_date_53",
    "end_date_53",
    "start_date_54",
    "end_date_54",
    "start_date_55",
    "end_date_55",
    "start_date_56",
    "end_date_56",
    "start_date_57",
    "end_date_57",
    "start_date_58",
    "end_date_58",
    "start_date_59",
    "end_date_59",
    "start_date_60",
    "end_date_60",
    "start_date_61",
    "end_date_61",
    "start_date_62",
    "end_date_62",
    "start_date_63",
    "end_date_63",
    "start_date_64",
    "end_date_64",
    "start_date_65",
    "end_date_65",
    "start_date_21_Prednisolone",
    "end_date_21_Prednisolone",
    "start_date_22_Prednisolone",
    "end_date_22_Prednisolone",
    "start_date_23_Prednisolone",
    "end_date_23_Prednisolone",
    "start_date_24_Prednisolone",
    "end_date_24_Prednisolone",
    "start_date_25_Prednisolone",
    "end_date_25_Prednisolone",
    "start_date_26_Prednisolone",
    "end_date_26_Prednisolone",
    "start_date_27_Prednisolone",
    "end_date_27_Prednisolone",
    "start_date_28_Prednisolone",
    "end_date_28_Prednisolone",
    "start_date_29_Prednisolone",
    "end_date_29_Prednisolone",
    "start_date_30_Prednisolone",
    "end_date_30_Prednisolone",
    "start_date_21_Deflazacort",
    "end_date_21_Deflazacort",
    "start_date_22_Deflazacort",
    "end_date_22_Deflazacort",
    "start_date_23_Deflazacort",
    "end_date_23_Deflazacort",
    "start_date_24_Deflazacort",
    "end_date_24_Deflazacort",
    "start_date_25_Deflazacort",
    "end_date_25_Deflazacort",
    "start_date_26_Deflazacort",
    "end_date_26_Deflazacort",
    "start_date_27_Deflazacort",
    "end_date_27_Deflazacort",
    "start_date_28_Deflazacort",
    "end_date_28_Deflazacort",
    "start_date_29_Deflazacort",
    "end_date_29_Deflazacort",
    "start_date_30_Deflazacort",
    "end_date_30_Deflazacort",
    "start_date_21_Dexamethasone",
    "end_date_21_Dexamethasone",
    "start_date_22_Dexamethasone",
    "end_date_22_Dexamethasone",
    "start_date_23_Dexamethasone",
    "end_date_23_Dexamethasone",
    "start_date_24_Dexamethasone",
    "end_date_24_Dexamethasone",
    "start_date_25_Dexamethasone",
    "end_date_25_Dexamethasone",
    "start_date_26_Dexamethasone",
    "end_date_26_Dexamethasone",
    "start_date_27_Dexamethasone",
    "end_date_27_Dexamethasone",
    "start_date_28_Dexamethasone",
    "end_date_28_Dexamethasone",
    "start_date_29_Dexamethasone",
    "end_date_29_Dexamethasone",
    "start_date_30_Dexamethasone",
    "end_date_30_Dexamethasone",
    "start_date_6_Methylprednisolone",
    "end_date_6_Methylprednisolone",
    "start_date_7_Methylprednisolone",
    "end_date_7_Methylprednisolone",
    "start_date_8_Methylprednisolone",
    "end_date_8_Methylprednisolone",
    "start_date_9_Methylprednisolone",
    "end_date_9_Methylprednisolone",
    "start_date_10_Methylprednisolone",
    "end_date_10_Methylprednisolone",
  ],
  formula: ({
    start_date_1,
    end_date_1,
    start_date_2,
    end_date_2,
    start_date_3,
    end_date_3,
    start_date_4,
    end_date_4,
    start_date_5,
    end_date_5,
    start_date_6,
    end_date_6,
    start_date_7,
    end_date_7,
    start_date_8,
    end_date_8,
    start_date_9,
    end_date_9,
    start_date_10,
    end_date_10,
    start_date_11,
    end_date_11,
    start_date_12,
    end_date_12,
    start_date_13,
    end_date_13,
    start_date_14,
    end_date_14,
    start_date_15,
    end_date_15,
    start_date_16,
    end_date_16,
    start_date_17,
    end_date_17,
    start_date_18,
    end_date_18,
    start_date_19,
    end_date_19,
    start_date_20,
    end_date_20,
    start_date_21,
    end_date_21,
    start_date_22,
    end_date_22,
    start_date_23,
    end_date_23,
    start_date_24,
    end_date_24,
    start_date_25,
    end_date_25,
    start_date_26,
    end_date_26,
    start_date_27,
    end_date_27,
    start_date_28,
    end_date_28,
    start_date_29,
    end_date_29,
    start_date_30,
    end_date_30,
    start_date_31,
    end_date_31,
    start_date_32,
    end_date_32,
    start_date_33,
    end_date_33,
    start_date_34,
    end_date_34,
    start_date_35,
    end_date_35,
    start_date_36,
    end_date_36,
    start_date_37,
    end_date_37,
    start_date_38,
    end_date_38,
    start_date_39,
    end_date_39,
    start_date_40,
    end_date_40,
    start_date_41,
    end_date_41,
    start_date_42,
    end_date_42,
    start_date_43,
    end_date_43,
    start_date_44,
    end_date_44,
    start_date_45,
    end_date_45,
    start_date_46,
    end_date_46,
    start_date_47,
    end_date_47,
    start_date_48,
    end_date_48,
    start_date_49,
    end_date_49,
    start_date_50,
    end_date_50,
    start_date_51,
    end_date_51,
    start_date_52,
    end_date_52,
    start_date_53,
    end_date_53,
    start_date_54,
    end_date_54,
    start_date_55,
    end_date_55,
    start_date_56,
    end_date_56,
    start_date_57,
    end_date_57,
    start_date_58,
    end_date_58,
    start_date_59,
    end_date_59,
    start_date_60,
    end_date_60,
    start_date_61,
    end_date_61,
    start_date_62,
    end_date_62,
    start_date_63,
    end_date_63,
    start_date_64,
    end_date_64,
    start_date_65,
    end_date_65,
    start_date_21_Prednisolone,
    end_date_21_Prednisolone,
    start_date_22_Prednisolone,
    end_date_22_Prednisolone,
    start_date_23_Prednisolone,
    end_date_23_Prednisolone,
    start_date_24_Prednisolone,
    end_date_24_Prednisolone,
    start_date_25_Prednisolone,
    end_date_25_Prednisolone,
    start_date_26_Prednisolone,
    end_date_26_Prednisolone,
    start_date_27_Prednisolone,
    end_date_27_Prednisolone,
    start_date_28_Prednisolone,
    end_date_28_Prednisolone,
    start_date_29_Prednisolone,
    end_date_29_Prednisolone,
    start_date_30_Prednisolone,
    end_date_30_Prednisolone,
    start_date_21_Deflazacort,
    end_date_21_Deflazacort,
    start_date_22_Deflazacort,
    end_date_22_Deflazacort,
    start_date_23_Deflazacort,
    end_date_23_Deflazacort,
    start_date_24_Deflazacort,
    end_date_24_Deflazacort,
    start_date_25_Deflazacort,
    end_date_25_Deflazacort,
    start_date_26_Deflazacort,
    end_date_26_Deflazacort,
    start_date_27_Deflazacort,
    end_date_27_Deflazacort,
    start_date_28_Deflazacort,
    end_date_28_Deflazacort,
    start_date_29_Deflazacort,
    end_date_29_Deflazacort,
    start_date_30_Deflazacort,
    end_date_30_Deflazacort,
    start_date_21_Dexamethasone,
    end_date_21_Dexamethasone,
    start_date_22_Dexamethasone,
    end_date_22_Dexamethasone,
    start_date_23_Dexamethasone,
    end_date_23_Dexamethasone,
    start_date_24_Dexamethasone,
    end_date_24_Dexamethasone,
    start_date_25_Dexamethasone,
    end_date_25_Dexamethasone,
    start_date_26_Dexamethasone,
    end_date_26_Dexamethasone,
    start_date_27_Dexamethasone,
    end_date_27_Dexamethasone,
    start_date_28_Dexamethasone,
    end_date_28_Dexamethasone,
    start_date_29_Dexamethasone,
    end_date_29_Dexamethasone,
    start_date_30_Dexamethasone,
    end_date_30_Dexamethasone,
    start_date_6_Methylprednisolone,
    end_date_6_Methylprednisolone,
    start_date_7_Methylprednisolone,
    end_date_7_Methylprednisolone,
    start_date_8_Methylprednisolone,
    end_date_8_Methylprednisolone,
    start_date_9_Methylprednisolone,
    end_date_9_Methylprednisolone,
    start_date_10_Methylprednisolone,
    end_date_10_Methylprednisolone,
  }: any) => {
    if (!start_date_1 && !start_date_21 && !start_date_41 && !start_date_46) {
      return;
    }
    return (
      (getPeriod(start_date_1, end_date_1) || 0) +
      (getPeriod(start_date_2, end_date_2) || 0) +
      (getPeriod(start_date_3, end_date_3) || 0) +
      (getPeriod(start_date_4, end_date_4) || 0) +
      (getPeriod(start_date_5, end_date_5) || 0) +
      (getPeriod(start_date_6, end_date_6) || 0) +
      (getPeriod(start_date_7, end_date_7) || 0) +
      (getPeriod(start_date_8, end_date_8) || 0) +
      (getPeriod(start_date_9, end_date_9) || 0) +
      (getPeriod(start_date_10, end_date_10) || 0) +
      (getPeriod(start_date_11, end_date_11) || 0) +
      (getPeriod(start_date_12, end_date_12) || 0) +
      (getPeriod(start_date_13, end_date_13) || 0) +
      (getPeriod(start_date_14, end_date_14) || 0) +
      (getPeriod(start_date_15, end_date_15) || 0) +
      (getPeriod(start_date_16, end_date_16) || 0) +
      (getPeriod(start_date_17, end_date_17) || 0) +
      (getPeriod(start_date_18, end_date_18) || 0) +
      (getPeriod(start_date_19, end_date_19) || 0) +
      (getPeriod(start_date_20, end_date_20) || 0) +
      (getPeriod(start_date_21, end_date_21) || 0) +
      (getPeriod(start_date_22, end_date_22) || 0) +
      (getPeriod(start_date_23, end_date_23) || 0) +
      (getPeriod(start_date_24, end_date_24) || 0) +
      (getPeriod(start_date_25, end_date_25) || 0) +
      (getPeriod(start_date_26, end_date_26) || 0) +
      (getPeriod(start_date_27, end_date_27) || 0) +
      (getPeriod(start_date_28, end_date_28) || 0) +
      (getPeriod(start_date_29, end_date_29) || 0) +
      (getPeriod(start_date_30, end_date_30) || 0) +
      (getPeriod(start_date_31, end_date_31) || 0) +
      (getPeriod(start_date_32, end_date_32) || 0) +
      (getPeriod(start_date_33, end_date_33) || 0) +
      (getPeriod(start_date_34, end_date_34) || 0) +
      (getPeriod(start_date_35, end_date_35) || 0) +
      (getPeriod(start_date_36, end_date_36) || 0) +
      (getPeriod(start_date_37, end_date_37) || 0) +
      (getPeriod(start_date_38, end_date_38) || 0) +
      (getPeriod(start_date_39, end_date_39) || 0) +
      (getPeriod(start_date_40, end_date_40) || 0) +
      (getPeriod(start_date_41, end_date_41) || 0) +
      (getPeriod(start_date_42, end_date_42) || 0) +
      (getPeriod(start_date_43, end_date_43) || 0) +
      (getPeriod(start_date_44, end_date_44) || 0) +
      (getPeriod(start_date_45, end_date_45) || 0) +
      (getPeriod(start_date_46, end_date_46) || 0) +
      (getPeriod(start_date_47, end_date_47) || 0) +
      (getPeriod(start_date_48, end_date_48) || 0) +
      (getPeriod(start_date_49, end_date_49) || 0) +
      (getPeriod(start_date_50, end_date_50) || 0) +
      (getPeriod(start_date_51, end_date_51) || 0) +
      (getPeriod(start_date_52, end_date_52) || 0) +
      (getPeriod(start_date_53, end_date_53) || 0) +
      (getPeriod(start_date_54, end_date_54) || 0) +
      (getPeriod(start_date_55, end_date_55) || 0) +
      (getPeriod(start_date_56, end_date_56) || 0) +
      (getPeriod(start_date_57, end_date_57) || 0) +
      (getPeriod(start_date_58, end_date_58) || 0) +
      (getPeriod(start_date_59, end_date_59) || 0) +
      (getPeriod(start_date_60, end_date_60) || 0) +
      (getPeriod(start_date_61, end_date_61) || 0) +
      (getPeriod(start_date_62, end_date_62) || 0) +
      (getPeriod(start_date_63, end_date_63) || 0) +
      (getPeriod(start_date_64, end_date_64) || 0) +
      (getPeriod(start_date_65, end_date_65) || 0) +
      (getPeriod(start_date_21_Prednisolone, end_date_21_Prednisolone) || 0) +
      (getPeriod(start_date_22_Prednisolone, end_date_22_Prednisolone) || 0) +
      (getPeriod(start_date_23_Prednisolone, end_date_23_Prednisolone) || 0) +
      (getPeriod(start_date_24_Prednisolone, end_date_24_Prednisolone) || 0) +
      (getPeriod(start_date_25_Prednisolone, end_date_25_Prednisolone) || 0) +
      (getPeriod(start_date_26_Prednisolone, end_date_26_Prednisolone) || 0) +
      (getPeriod(start_date_27_Prednisolone, end_date_27_Prednisolone) || 0) +
      (getPeriod(start_date_28_Prednisolone, end_date_28_Prednisolone) || 0) +
      (getPeriod(start_date_29_Prednisolone, end_date_29_Prednisolone) || 0) +
      (getPeriod(start_date_30_Prednisolone, end_date_30_Prednisolone) || 0) +
      (getPeriod(start_date_21_Deflazacort, end_date_21_Deflazacort) || 0) +
      (getPeriod(start_date_22_Deflazacort, end_date_22_Deflazacort) || 0) +
      (getPeriod(start_date_23_Deflazacort, end_date_23_Deflazacort) || 0) +
      (getPeriod(start_date_24_Deflazacort, end_date_24_Deflazacort) || 0) +
      (getPeriod(start_date_25_Deflazacort, end_date_25_Deflazacort) || 0) +
      (getPeriod(start_date_26_Deflazacort, end_date_26_Deflazacort) || 0) +
      (getPeriod(start_date_27_Deflazacort, end_date_27_Deflazacort) || 0) +
      (getPeriod(start_date_28_Deflazacort, end_date_28_Deflazacort) || 0) +
      (getPeriod(start_date_29_Deflazacort, end_date_29_Deflazacort) || 0) +
      (getPeriod(start_date_30_Deflazacort, end_date_30_Deflazacort) || 0) +
      (getPeriod(start_date_21_Dexamethasone, end_date_21_Dexamethasone) || 0) +
      (getPeriod(start_date_22_Dexamethasone, end_date_22_Dexamethasone) || 0) +
      (getPeriod(start_date_23_Dexamethasone, end_date_23_Dexamethasone) || 0) +
      (getPeriod(start_date_24_Dexamethasone, end_date_24_Dexamethasone) || 0) +
      (getPeriod(start_date_25_Dexamethasone, end_date_25_Dexamethasone) || 0) +
      (getPeriod(start_date_26_Dexamethasone, end_date_26_Dexamethasone) || 0) +
      (getPeriod(start_date_27_Dexamethasone, end_date_27_Dexamethasone) || 0) +
      (getPeriod(start_date_28_Dexamethasone, end_date_28_Dexamethasone) || 0) +
      (getPeriod(start_date_29_Dexamethasone, end_date_29_Dexamethasone) || 0) +
      (getPeriod(start_date_30_Dexamethasone, end_date_30_Dexamethasone) || 0) +
      (getPeriod(
        start_date_6_Methylprednisolone,
        end_date_6_Methylprednisolone
      ) || 0) +
      (getPeriod(
        start_date_7_Methylprednisolone,
        end_date_7_Methylprednisolone
      ) || 0) +
      (getPeriod(
        start_date_8_Methylprednisolone,
        end_date_8_Methylprednisolone
      ) || 0) +
      (getPeriod(
        start_date_9_Methylprednisolone,
        end_date_9_Methylprednisolone
      ) || 0) +
      (getPeriod(
        start_date_10_Methylprednisolone,
        end_date_10_Methylprednisolone
      ) || 0)
    );
  },
  help: `Steroid 총 약제 사용일수 계산`,
};

export default totalDaysofUse;
