import instance from ".";

export const putValues = async (data: {
  templateId: string;
  sectionId: string;
  values: any;
}) => {
  const result = await instance.put(`/values/template`, data);
  return result.data;
};
