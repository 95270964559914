const getUsage = (usage: string) => {
  switch (usage) {
    case "Qd":
      return 1;
    case "Qod":
      return 2;
    case "weekly":
      return 7;
    case "biweekly":
      return 14;
    default:
      return 1;
  }
};

export default getUsage;
