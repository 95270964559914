import PatientType from "types/patient";
import { TemplateType } from "types";
import getBMI from "./common/getBMI";
import getLMS from "./common/getLMS";
import getAge from "./common/getAge";

const bmiLMS = {
  id: 3,
  name: "bmiLMS",
  datas: ["height", "weight"],
  formula: (
    { height, weight }: any,
    { sex, birthday }: PatientType,
    { reporting_date }: TemplateType
  ) => {
    if (!height || !weight || !sex || !birthday || !reporting_date) {
      return;
    }
    const age = getAge(birthday, reporting_date);
    const bmi = getBMI({ height, weight });
    const { L, M, S } = getLMS({ type: "bmi", sex, age });
    const result = ((Math.pow(bmi / M, L) - 1) / (L * S)).toFixed(3);
    return parseFloat(result);
  },
  help: `BMI z score (자동계산) = ((Power (BMI/M)^L))-1)/(L*S)`,
};

export default bmiLMS;
