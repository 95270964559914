import { useNavigate } from "react-router-dom";
import styled from "styled-components";
const Empty = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <ErrorMessage>정상적인 주소가 아닙니다.</ErrorMessage>
      <BackBtn onClick={() => navigate("/")}>돌아가기</BackBtn>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
`;
const ErrorMessage = styled.p`
  margin-bottom: 20px;
`;
const BackBtn = styled.button`
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #000;
`;
export default Empty;
