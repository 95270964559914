import { useRecoilValue } from "recoil";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { ItemType, OnChangeType, SectionType } from "types";
import formulas from "datas/formulas";
import { getSections } from "apis/section";
import patientState from "stores/patientState";
import templateState from "stores/templateState";

const Formula = ({
  formulaId,
  formula,
  values,
  view,
  data2,
  onChange,
}: {
  formulaId: number;
  formula: any;
  values: any;
  view?: boolean;
  data2?: any;
  onChange: OnChangeType;
}) => {
  const { id, unit, min, max, is_required } = data2;

  const { projectId = "", templateId = "", sectionId = "" } = useParams();
  const { isLoading, data } = useQuery(
    ["getSections", { projectId, templateId, needValues: true }],
    () => getSections({ projectId, templateId, needValues: true })
  );
  const patient = useRecoilValue(patientState);
  const template = useRecoilValue(templateState);
  const [result, setResult] = useState();

  const items: ItemType[] = data.map(({ items }: SectionType) => items).flat();

  const info = formulas.find(({ id }) => formulaId === id);
  const valueList: { [key: string]: number } = {};
  info?.datas.forEach((data) => {
    const id = formula[data];
    const value = values[id] !== null
      ? values[id] ?? items.find((item) => item.id === id)?.value
      : null;
    const value2 =
      sectionId === "151" &&
      (data2.id === 3964 ||
        data2.id === 3975 ||
        data2.id === 3978 ||
        data2.id === 3981) &&
      items.find((item) => item.id === id)?.type === "radio"
        ? items
            .find((item) => item.id === id)
            ?.options.find(
              (item) => item.id === (value?.length > 0 && value[0])
            )?.value
        : "";
    valueList[data] = value2 || value;
  });

  useEffect(() => {
    setResult(info?.formula(valueList, patient, template));
  });

  useEffect(() => {
    // formula의 item_id에 value 저장
    onChange({ target: { name: data2.id + "", value: result } });
  }, [result]);

  return <>{info?.formula(valueList, patient, template)}</>;
};

export default Formula;
