//계산식 추가
//유도 steroid 최대용량 자동계산 (= 용량 mg / 체중 kg)
const maxCapacity = {
  id: 10,
  name: "maxCapacity",
  datas: ["steroid", "weight"],
  formula: ({ steroid, weight }: any) => {
    if (!steroid || !weight) {
      return;
    }
    return (steroid / weight).toFixed(1);
  },
  help: "mg/kg",
};

export default maxCapacity;
