export type optionsPartType = {
  id: number;
  name: string;
  flag: boolean;
};

export const options_part1 = [
  { id: 0, name: "1세 초과 16세 미만", flag: false },
  { id: 1, name: "새로 진단된 idiopathic nephrotic syndrome", flag: false },
  { id: 2, name: "첫 증상 발현시 부종", flag: false },
  { id: 3, name: "첫 증상 발현시 신증후군 범위의 단백뇨", flag: false },
  { id: 4, name: "첫 증상 발현시 혈중 알부민 < 3.0g/dL", flag: false },
  { id: 5, name: "4주간 경구 스테로이드 사용 후 관해에 도달함", flag: false },
];

export const options_part2 = [
  {
    id: 0,
    name: "2차성 신증후군 (e.g. lupus, vasculitis, HSP, malignancy etc)",
    flag: false,
  },
  {
    id: 1,
    name: "low C3 (C3 <60mg/dL)",
    flag: false,
  },
  {
    id: 2,
    name: "육안적 혈뇨",
    flag: false,
  },
  {
    id: 3,
    name: "신기능의 감소 (Renal insufficiency)",
    flag: false,
  },
  {
    id: 4,
    name: "명확한 고혈압",
    flag: false,
  },
  {
    id: 5,
    name: "extrarenal symptom (neurologic, GI, cardiac, hematologic, lung, musculoskeletal, rheumatologic, endocrinologic, eye, ear disorder)",
    flag: false,
  },
  {
    id: 6,
    name: "신장 이식을 받은 환자",
    flag: false,
  },
];

// SRNS
export const options_part3 = [
  {
    id: 0,
    name: "2세 이상 16세 미만",
    flag: false,
  },
  {
    id: 1,
    name: "경구 스테로이드 4주간 사용에도 관해에 도달하지 못함",
    flag: false,
  },
  {
    id: 2,
    name: "Calcineurin inhibitors (사이클로스포린, 타크로리무스) 3개월간 사용에도 관해에 도달하지 못함",
    flag: false,
  },
];

export const options_part4 = [
  {
    id: 0,
    name: "2차성 신증후군 (e.g. lupus, vasculitis, HSP, malignancy etc)",
    flag: false,
  },
  {
    id: 1,
    name: "low C3 (< 60mg/dL)",
    flag: false,
  },
  {
    id: 2,
    name: "최근 4주 이내에 2차 약제를 사용한 적이 있음 (cyclophosphamide, MMF, rituximab, levamisole, mizoribinen)",
    flag: false,
  },
  {
    id: 3,
    name: "신증후군/단백뇨와 관련된 유전자 변이가 확인됨",
    flag: false,
  },
];
