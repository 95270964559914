import { Outlet } from 'react-router-dom';

import styled from 'styled-components';

import { PageContainer, PageLayout } from 'components/atoms/page';

import { ReactComponent as ProjectTitleLogo } from 'assets/icons/ProjectTitleLogo.svg';

const SampleManagement = () => {
  return <PageLayout>
    <Header>
      <ProjectTitle>
        <ProjectTitleLogo />
        샘플 재고 관리
      </ProjectTitle>
    </Header>
    <PageContainer>
      <Outlet />
    </PageContainer>
  </PageLayout>;
};

const Header = styled.header`
  display: flex;
`;

const ProjectTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  align-items: center;
  flex: 1;
  svg { margin-right: 10px; }
`;

export default SampleManagement;
