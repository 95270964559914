import { getProjects } from "apis/project";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";

const Main = () => {
  const { data, isLoading } = useQuery(["getProjects"], () => getProjects());
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading) {
      navigate(`/project/${data[0]?.id}`);
    }
  }, [data]);
  return <></>;
};

export default Main;
