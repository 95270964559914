import { ChangeEvent, useRef, FormEvent } from "react";
import { useMutation } from "react-query";
import styled, { css } from "styled-components";

import { removeAttachment, uploadAttachment } from "apis/upload";

import { NoticeType, BoardType } from "types";
import { ReactComponent as ImgDelete } from "assets/icons/delete.svg";

const Attachment = ({
  state,
  setState,
  isCurrentUserAuthor,
}: {
  state: NoticeType;
  setState: (newState: NoticeType) => void;
  isCurrentUserAuthor: boolean;
}) => {
  console.log("Attachment2 state: ", state);

  const newFiles: { file_name: string; file_url: string }[] = [];
  // console.log("newFiles: ", newFiles);
  const mutation = useMutation(async (file: File) => uploadAttachment(file), {
    onSuccess: (data) => {
      //한글 encode/decode
      newFiles.push({
        file_name: data.name,
        file_url: data.url,
      });
      console.log("newFiles: ", newFiles);
      let newState = {
        ...state,
        attachments: newFiles,
      };
      setState(newState);
    },
  });

  const fileEl = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileEl.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log("handleFileChange e.target.files: ", e.target.files);
    const newFileList: File[] = [];

    if (mutation.isLoading) return;
    if (e.target.files) {
      Array.from(e.target.files).forEach((file: any) => {
        console.log("file: ", file);
        newFileList.push(file);
      });

      console.log("newFileList: ", newFileList);

      newFileList.map((file: any) => {
        mutation.mutate(file);
      });
      e.target.value = "";
    }
  };

  const mutationDelete = useMutation(
    async (url: any) => removeAttachment(url),
    {
      onSuccess: (data, url) => {
        if (!window.confirm("정말 삭제하시겠습니까?")) return;
        alert("파일이 삭제 되었습니다.");
        const updatedFiles = state.attachments?.filter(
          (file) => file.file_url !== url
        );
        console.log("updatedFiles: ", updatedFiles);
        let newState = {
          ...state,
          attachments: updatedFiles,
        };
        setState(newState);
      },
      onError: (err) => {
        alert("삭제에 실패했습니다");
      },
    }
  );

  const handleDelete = async (e: any, url: string) => {
    e.preventDefault();
    if (mutationDelete.isLoading) return;
    if (url) mutationDelete.mutate(url);
    // console.log("handleDelete value: ", url);
  };

  const isEmptyAttachment = () => {
    if (
      !state.attachments ||
      state.attachments.length === 0 ||
      state.attachments[0].file_name === null
    ) {
      return true;
    }
  };

  return (
    <>
      {isCurrentUserAuthor ? (
        <div>
          <BtnGray type="button" onClick={handleClick}>
            파일선택
          </BtnGray>
        </div>
      ) : (
        <></>
      )}
      <ColumnDiv>
        {isEmptyAttachment() ? (
          <></>
        ) : (
          state.attachments?.map((file: any, index: number) => (
            <AttachmentDiv key={index}>
              <A href={file.file_url !== "" ? file.file_url : ""}>
                {file.file_name}
              </A>
              {isCurrentUserAuthor ? (
                <Btn
                  type="button"
                  onClick={(e) => handleDelete(e, file.file_url)}
                  style={{ padding: "5px 5px 0px 5px" }}
                >
                  <ImgDelete />
                </Btn>
              ) : (
                <></>
              )}
            </AttachmentDiv>
          ))
        )}
      </ColumnDiv>
      {state.attachments?.length === 0 && <Space>선택된 파일 없음</Space>}
      <InputHide
        type="file"
        multiple
        onChange={handleFileChange}
        ref={fileEl}
      />
    </>
  );
};

export const Btn = styled.button.attrs(({ type }) => ({
  type: type ?? "button",
}))`
  border: 1px solid transparent;
  background: none;
  cursor: pointer;
  cursor: ${({ disabled }) => disabled && "default"};
  border-radius: 2px;
  padding: 12px 15px;
  font-weight: 500;
  font-size: 14px;
`;

const InputHide = styled.input`
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  outline: 0;
  opacity: 0;
`;

const A = styled.a`
  margin-left: 10px;
  color: blue;
`;

const Space = styled.span`
  margin-left: 10px;
`;

const BtnGray = styled(Btn)`
  background: #f0f0f2;
`;

const ColumnDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  // padding: 12px;
  // flex: 1;
  font-size: 14px;
  position: relative;
`;

const AttachmentDiv = styled.div`
  display: flex;
  // justify-content: center;
  align-items: center;
  // padding: 12px;
  // flex: 1;
  // font-size: 14px;
  // position: relative;
`;

export default Attachment;
