import instance from ".";

export const getPatientTypes = async (params: { projectId: string }) => {
  const result = await instance({
    method: "GET",
    url: `/patient-types`,
    params,
  });
  return result.data;
};

export const createPatientType = async (data: {
  projectId: string;
  name: string;
  code: string;
}) => {
  const result = await instance({
    method: "POST",
    url: `/patient-types`,
    data,
  });
  return result.data;
};

export const editPatientType = async (data: {
  id: number;
  name: string;
  code: string;
}) => {
  const result = await instance({
    method: "PUT",
    url: `/patient-types/${data.id}`,
    data,
  });
  return result.data;
};

export const deletePatientType = async (id: number) => {
  const result = await instance({
    method: "DELETE",
    url: `/patient-types/${id}`,
  });
  return result.data;
};

export const getPatientTypeTemplates = async (id: number) => {
  const result = await instance({
    method: "GET",
    url: `/patient-types/${id}/templates`,
  });
  return result.data;
};

export const editPatientTypeTemplate = async (
  id: number,
  templates: number[]
) => {
  const result = await instance({
    method: "PUT",
    url: `/patient-types/${id}/templates`,
    data: { templates },
  });
  return result.data;
};
