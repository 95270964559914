import PatientType from "types/patient";
import { TemplateType } from "types";
import getBMI from "./common/getBMI";
import getLMS from "./common/getLMS";
import getAge from "./common/getAge";
import { NORMSDIST } from "@formulajs/formulajs";

const bmiPercentile = {
  id: 21,
  name: "bmiPercentile",
  datas: ["height", "weight"],
  formula: (
    { height, weight }: any,
    { sex, birthday }: PatientType,
    { reporting_date }: TemplateType
  ) => {
    if (!height || !weight || !sex || !birthday || !reporting_date) {
      return;
    }
    const age = getAge(birthday, reporting_date);
    const bmi = getBMI({ height, weight });
    const { L, M, S } = getLMS({ type: "bmi", sex, age });
    return (
      NORMSDIST((Math.pow(bmi / M, L) - 1) / (L * S), true) * 100
    ).toFixed(1);
  },
  help: `NORMSDIST((((BMI/M)^L)-1)/(L*S))*100`,
};

export default bmiPercentile;
