import { useMutation, useQueryClient } from "react-query";
import axios, { AxiosError } from "axios";
import { editPatient } from "apis/patient";
import useInputs from "hooks/useInputs";
import { getLocaleDate } from "utils/time";

import PatientForm from "components/organisms/PatientForm";
import PatientType from "types/patient";
import { BtnWhite } from "components/atoms/button";

import { useParams } from "react-router-dom";

const PatientEdit = ({
  data,
  onClose,
}: {
  data: PatientType;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const { projectId = "" } = useParams();
  const [state, onChange] = useInputs({
    ...data,
    birthday: getLocaleDate(data.birthday),
    initial_diagnosis_date: getLocaleDate(data.initial_diagnosis_date),
  });
  const mutation = useMutation(
    (values) => editPatient({ ...state, values, projectId }),
    {
      onSuccess: () => {
        alert("저장 완료");
        queryClient.invalidateQueries(["getPatient"]);
        onClose();
      },
      onError: (err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          const msg = err.response?.data as { message: string };
          alert(msg.message);
        } else {
          alert(err);
        }
      },
    }
  );

  const handleSubmit = async (values: any) => {
    if (mutation.isLoading) return;
    if (!state.name) return alert("이름을 입력하세요");
    mutation.mutate(values);
  };

  const btnLeft = () => {
    return <BtnWhite onClick={onClose}>취소</BtnWhite>;
  };

  return (
    <PatientForm
      state={state}
      onChange={onChange}
      items={data.items}
      onSubmit={handleSubmit}
      btnLeft={btnLeft}
    />
  );
};

export default PatientEdit;
