import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  getPatientTypeTemplates,
  editPatientTypeTemplate,
} from "apis/patientType";

import ModalOneToMany from "components/organisms/ModalOneToMany";

const ModalTemplateDefaultWrapper = ({
  id,
  onClose,
}: {
  id: number;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery(
    ["getPatientTypeTemplates", { id }],
    () => getPatientTypeTemplates(id)
  );
  const mutation = useMutation(
    (templates: number[]) => editPatientTypeTemplate(id, templates),
    {
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries(["getPatientTypeTemplates"]);
      },
    }
  );

  const handleSubmit = (templates: number[]) => {
    if (!window.confirm("수정하시겠습니까?")) return;
    mutation.mutate(templates);
  };

  if (isLoading) return <></>;

  const ids = data.map(({ id }: { id: number }) => id);
  return (
    <ModalOneToMany
      type="type"
      onSubmit={handleSubmit}
      data={ids}
      onClose={onClose}
    />
  );
};

export default ModalTemplateDefaultWrapper;
