//{(Ht(cm) x Bwt(kg))/3600}1/2
const bsa = {
  id: 18,
  name: "bsa",
  datas: ["height", "weight"],
  formula: ({ height, weight }: any) => {
    if (!height || !weight) {
      return;
    }
    return Math.sqrt((height * weight) / 3600).toFixed(1);
  },
  help: "{(키(cm) x 체중(kg))/3600}1/2",
};

export default bsa;
