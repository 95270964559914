const getAge = (birthday: string, reporting_date: string) => {
  const Measurement = new Date(reporting_date);
  const MeasurementYear = Measurement.getFullYear();
  const MeasurementMonth = Measurement.getMonth() + 1;
  const MeasurementDay = Measurement.getDate();
  const DateOfBirth = new Date(birthday);
  const DateOfBirthYear = DateOfBirth.getFullYear();
  const DateOfBirthMonth = DateOfBirth.getMonth() + 1;
  const DateOfBirthDay = DateOfBirth.getDate();
  return Math.floor(
    (MeasurementYear - DateOfBirthYear) * 12 +
      (MeasurementMonth - DateOfBirthMonth) +
      (MeasurementDay - DateOfBirthDay) / 30.4
  );
};

export default getAge;
