import { getUserToken } from "apis/user";

export const getTokenPayload = () => {
  const token = getUserToken();
  const payload = JSON.parse(window.atob(token.split(".")[1]));
  return payload;
};

export const getUserRank = () => {
  const { isSuper, isProjectSuper } = getTokenPayload();
  if (isSuper) return "슈퍼관리자";
  if (isProjectSuper) return "프로젝트관리자";
  return "연구원";
};
