import PatientType from "types/patient";
import { TemplateType } from "types";
import getAge from "./common/getAge";
import getLMS from "./common/getLMS";
import { NORMSDIST } from "@formulajs/formulajs";
import getBloodPressurePercentile, {
  getBpStdAge,
} from "./common/getBpPercentile";

const dbpPercentile = {
  id: 23,
  name: "Blood Pressure Percentile (DBP)",
  datas: ["height", "DBP"],
  formula: (
    { height, DBP }: any,
    { sex, birthday }: PatientType,
    { reporting_date }: TemplateType
  ) => {
    if (!height || !DBP || !sex || !birthday || !reporting_date) {
      return;
    }

    const isSBP = false;
    if (DBP == null || height == null) {
      return 0;
    }

    const age = getAge(birthday, reporting_date);

    // n 개월 -> k.5 세로 변환
    const stdAge = getBpStdAge(age);

    if (stdAge > 18.5) {
      return;
    }

    // 체고백분위
    const { L, M, S } = getLMS({ type: "height", sex, age });
    const heightPercentile = parseFloat(
      (NORMSDIST((Math.pow(height / M, L) - 1) / (L * S), true) * 100).toFixed(
        1
      )
    );

    // DBP Percentile
    return getBloodPressurePercentile(
      sex,
      DBP,
      stdAge,
      heightPercentile,
      isSBP
    );
  },
  help: `DBP 백분위수`,
};

export default dbpPercentile;
