import lmsData from "datas/formulas/common/lmsData";

const getLMS = ({
  type,
  sex,
  age,
}: {
  type: string;
  sex: string;
  age: number;
}) => {
  age = age > 227 ? 227 : age;
  return lmsData[type][sex][age] || { L: 0, M: 0, S: 0 };
};

export default getLMS;
