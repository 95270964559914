import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useSearchParams } from "react-router-dom";

import { getQuerys, getQueryCount } from "apis/query";
import QueryType from "types/query";

import { getTokenPayload } from "utils/user";
import { getLocaleDate } from "utils/time";

import styled from "styled-components";
import { SpaceBetween } from "components/atoms/common";
import { Table, TR, TH, TD } from "components/atoms/table";
import { PageContainer, PageLayout } from "components/atoms/page";

import Pagination from "components/organisms/Pagination";
import Modal from "components/organisms/Modal";

import { ReactComponent as Search } from "assets/icons/Search.svg";

import ModalQuery from "components/templates/Query/Modal";
import { Header, Title, Count, Footer, Container, Name, Red, Blue } from "components/atoms/query";

const display = 15;

const THs = [
  "순번",
  "대상자명",
  "담당자명",
  "템플릿명",
  "섹션명",
  "항목명",
  "생성 일자",
  "상태",
  "쿼리 보기",
];

const Query = () => {
  const { isSuper, isProjectSuper, adminId, projectId } = getTokenPayload();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const patientId = searchParams.get('patient_id');
  const templateId = searchParams.get('template_id');

  const params: any = { projectId, page, display, isSuper, isProjectSuper, adminId, patientId, templateId };

  const { data: query_data, isLoading: isQueryLoading } = useQuery(
    ["getQueryCount", params],
    () => getQueryCount(params)
    , { refetchOnWindowFocus: true }
  );
  const { data, isLoading } = useQuery(
    ["getQuerys", params],
    () => getQuerys(params),
    { refetchOnWindowFocus: true, keepPreviousData: true }
  );

  if (isLoading || isQueryLoading) return <></>;
  const { total, items } = data;
    
  return (
    <PageLayout>
      <Header>
        <Title>
          오토쿼리
        </Title>
      </Header>
      <PageContainer>
        <Container>
          <SpaceBetween>
            <Count>총 { total }건 ( <Red>오픈 : { query_data.state1_cnt }건</Red>, &nbsp; 클로즈 : { query_data.state3_cnt }건 )</Count>
          </SpaceBetween>
          <Table>
            <thead>
              <TR>
                {THs.map((th, i: number) => (
                  <TH key={i}>{th}</TH>
                ))}
              </TR>
            </thead>
            <tbody>
              {items.map(
                ( query: QueryType, i: number ) => {
                  const {
                    id,
                    patient_id,
                    item_id,
                    template_id,
                    state_code,
                    patient_name,
                    admin_name,
                    template_name,
                    section_name,
                    item_name,
                    state_name,
                    create_date,
                    section_id,
                    // admin_id,
                    // group_id,
                    // content,
                  } = query;
                  return (
                    <TR key={id}>
                      <TD>{total - ((page - 1) * display + i)}</TD>
                      <TD>
                        <Link to={`/project/${projectId}/patient/${patient_id}/template/${template_id}/section/${section_id}/#${item_id}`}>
                            <Name>{patient_name}</Name>
                        </Link>
                      </TD>
                      <TD>{admin_name}</TD>
                      <QueryTD>{template_name}</QueryTD>
                      <QueryTD>{section_name}</QueryTD>
                      <QueryTD>{item_name}</QueryTD>
                      <TD>{getLocaleDate(create_date)}</TD>
                      <TD>
                        {state_code === 1 && (<Red>{state_name}</Red>)}
                        {state_code === 2 && (<Blue>{state_name}</Blue>)}
                        {state_code === 3 && state_name}
                      </TD>
                      <TD><Modal
                              activator={(onOpen) => <Search onClick={onOpen} />}
                              content={(onClose) => <ModalQuery onClose={onClose} data={query} />}
                            />
                      </TD>
                    </TR>
                  )
                }
              )}
            </tbody>
          </Table>
          <Footer>
            <Pagination
              total={total}
              display={display}
              page={page}
              onChange={(page: number) => setPage(page)}
            />
          </Footer>
        </Container>
      </PageContainer>
    </PageLayout>
  );
};

export const QueryTD = styled.td`
  font-weight: 400;
  font-size: 14px;
  height: 60px;
  width: 20%;
  text-align: ${({ left }: { left?: boolean }) => (left ? "left" : "center")};
`;

export default Query;
