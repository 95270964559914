import PatientType from "types/patient";
import getAge from "./common/getAge";

const fuAge = {
  id: 12,
  name: "fuAge",
  datas: ["fu_date"],
  formula: ({ fu_date }: any, { birthday }: PatientType) => {
    if (!fu_date || !birthday) {
      return;
    }
    return getAge(birthday, fu_date);
  },
  help: `만나이(개월) = ((측정년도-출생년도)×12))+(측정월-출생월)+((측정일-출생일)÷30.4))`,
};

export default fuAge;
