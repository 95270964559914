import styled from "styled-components";

import { ReactComponent as Pencil } from "assets/icons/pencil.svg";

const BtnPencil = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Btn onClick={onClick}>
      <Pencil />
    </Btn>
  );
};

const Btn = styled.button`
  border: none;
  background: none;
  display: inline-flex;
  align-items: center;
`;

export default BtnPencil;
