import { ReactNode, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useDisableScroll from "hooks/useDisableScroll";

import { getSections, getSection } from "apis/section";
import { getTemplate } from "apis/template";
import { createQuerys } from "apis/query";
import { putValues } from "apis/values";

import ItemForm from "components/organisms/item/Form";
import { Backdrop, ModalContainer } from "components/atoms/modal";
import { SpaceBetween, TemplateHeader } from "components/atoms/common";
import { BtnBlack, BtnGray, BtnWhite } from "components/atoms/button";

import { getLocaleDate } from "utils/time";

const DataEdit = () => {
  const queryClient = useQueryClient();
  const wrapperEl = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const {
    projectId = "",
    templateId = "",
    sectionId = "",
    patientId = "",
  } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const reportingDT = searchParams.get("reportingDT");

  const { data, isFetching } = useQuery(
    ["getSection", { sectionId, templateId, patientId }],
    () => getSection({ sectionId, templateId, patientId }),
    {
      onSuccess: () => {
        wrapperEl.current?.getElementsByTagName("input")[0]?.focus();
      },
    }
  );
  const { data: sections, isLoading: isLoadingSection } = useQuery(
    ["getSections", { projectId, templateId, patientId }],
    () => getSections({ projectId, templateId, patientId })
  );
  const { isLoading: isLoadingTemplate, data: template } = useQuery(
    ["getTemplate", { templateId }],
    () => getTemplate({ templateId })
  );
  useDisableScroll();

  const mutation = useMutation(
    async (values) => putValues({ templateId, sectionId, values }),
    {
      onSuccess: () => {
        checkQuery();
      },
    }
  );

  //시작 20230228 오토쿼리 관련 추가 by 장희준
  const mutation_query = useMutation(
    (inputs: {
      projectId: String;
      patientId: string;
      templateId: string;
      sectionId: string;
    }) => createQuerys(inputs)
  );

  const checkQuery = () => {
    const mutationQuery = {
      projectId: projectId,
      patientId: patientId,
      templateId: templateId,
      sectionId: sectionId,
    };

    mutation_query.mutate(mutationQuery, {
      onSuccess: () => {
        console.log("쿼리검출 완료");

        const nextSection = sections[sectionIndex + 1];

        if (nextSection) {
          if (window.confirm(`저장 성공. 다음 섹션으로 이동하시겠습니까?`)) {
            return navigate(
              `../../section/${nextSection.id}/edit?reportingDT=${reportingDT}`
            );
          }
        } else {
          alert("저장 성공");
        }
        queryClient.invalidateQueries(["getSection"]);
        queryClient.invalidateQueries(["getSections"]);
        navigate(`..`);
      },
      onError: (err) => {
        console.log(`ERR Query ${err}`);
      },
    });
  };
  //끝 20230228 오토쿼리 관련 추가 by 장희준

  if (isLoadingSection || isLoadingTemplate) return <></>;
  const sectionIndex = sections.findIndex(
    (s: { id: number }) => s.id + "" === sectionId
  );
  const section = sections[sectionIndex];
  const prevSection = sections[sectionIndex - 1];
  const nextSection = sections[sectionIndex + 1];

  const { id, name } = template;
  const handleClose = () => {
    if (!window.confirm("수정을 취소하시겠습니까?")) return;

    if (projectId === "674" && name !== "진단" && reportingDT) {
      navigate(`..?reportingDT=${reportingDT}`);
    } else if (projectId === "674" && reportingDT === "null") {
      navigate(`..`);
    } else {
      navigate(`..`);
    }
  };

  const handleSubmit = async (values: any) => {
    if (values.diffDate) return alert("날짜를 확인해주세요.");
    if (mutation.isLoading) return;
    mutation.mutate(values);
  };

  const handleSection = ({ id, target }: { id: number; target: string }) => {
    if (!window.confirm(`${target} 섹션으로 이동하시겠습니까?`)) return;
    if (projectId === "674" && name !== "진단" && reportingDT) {
      navigate(`../../section/${id}/edit?reportingDT=${reportingDT}`);
    } else if (projectId === "674" && reportingDT === "null") {
      navigate(`../../section/${id}/edit`);
    } else {
      navigate(`../../section/${id}/edit`);
    }
  };

  const BtnSection = ({
    disabled,
    children,
    onClick,
  }: {
    disabled: boolean;
    children: ReactNode;
    onClick: () => void;
  }) => {
    return disabled ? (
      <BtnGray disabled>{children}</BtnGray>
    ) : (
      <BtnWhite onClick={onClick}>{children}</BtnWhite>
    );
  };

  return (
    <Backdrop ref={wrapperEl}>
      <ModalContainer large>
        <SpaceBetween noMargin>
          {projectId == "674" && name !== "진단" && reportingDT ? (
            <h3>
              {template.name} / 이전 방문일: {reportingDT}
            </h3>
          ) : projectId == "674" && reportingDT === "null" ? (
            <h3>
              {template.name} / {getLocaleDate(template.reporting_date)}
            </h3>
          ) : (
            <h3>
              {template.name} / {getLocaleDate(template.reporting_date)}
            </h3>
          )}
          <div>
            <BtnSection
              disabled={!prevSection}
              onClick={() =>
                handleSection({ id: prevSection.id, target: "이전" })
              }
            >
              이전
            </BtnSection>
            <BtnSection
              disabled={!nextSection}
              onClick={() =>
                handleSection({ id: nextSection.id, target: "다음" })
              }
            >
              다음
            </BtnSection>
            <BtnBlack onClick={handleClose}>닫기</BtnBlack>
          </div>
        </SpaceBetween>
        <TemplateHeader>{section?.name}</TemplateHeader>
        {isFetching ? (
          "로딩중"
        ) : (
          <ItemForm data={data.items} onSubmit={handleSubmit} />
        )}
      </ModalContainer>
    </Backdrop>
  );
};

export default DataEdit;
