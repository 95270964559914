import { atom } from "recoil";
import { TemplateType } from "types";

const templateState = atom<TemplateType>({
  key: "templateState",
  default: {
    id: 0,
    name: "",
    items: [],
    sectionName: "",
    created_at: "",
    reporting_date: "",
    scheduled_date: "",
    is_default: 0,
    query_count: 0,
  },
});
export default templateState;
