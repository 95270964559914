import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { getLocaleDate } from 'utils/time';
import { getTokenPayload } from 'utils/user';

import sampleRequestTypes from 'datas/sampleRequestTypes';

import { SampleRequestSearchType } from 'types';

import sampleRequestSearchStateInit from 'stores/sampleRequestSearchState';

import { getSampleRequests } from 'apis/sampleRequest';

import styled from 'styled-components';

import { SpaceBetween } from 'components/atoms/common';
import { Table, TR, TD } from 'components/atoms/table';
import { BtnBlue, BtnPink, BtnSalmon } from 'components/atoms/button';

import Pagination from 'components/organisms/Pagination';


const display = 15;

const SampleRequestList = () => {
  const { isProjectSuper, isSuper } = getTokenPayload();

  const [sampleRequestSearchState, setSampleRequestSearchState] = useRecoilState<SampleRequestSearchType>(sampleRequestSearchStateInit);

  const params: any = {
    tab: sampleRequestSearchState.tab,
    study: sampleRequestSearchState.study,
    startDate: sampleRequestSearchState.startDate,
    endDate: sampleRequestSearchState.endDate,
    status: sampleRequestSearchState.status,
    page: sampleRequestSearchState.page
  };

  const { isLoading, data } = useQuery(
    ['getSampleRequests', params],
    () => getSampleRequests(params)
  );

  let location = useLocation();
  const locationObj = JSON.parse(JSON.stringify(location));

  const [searchParam, setSearchParam] = useSearchParams();
  const defaultState: any = {
    tab: searchParam.get('tab') ?? 'request',
    study: searchParam.get('study') ?? '',
    startDate: searchParam.get('startDate') ?? '',
    endDate: searchParam.get('endDate') ?? '',
    status: searchParam.get('status') ?? '',
    page: parseInt(searchParam.get('page') ?? '1')
  };
  const [state, setState] = useState(defaultState);

  const resetState: any = {
    tab: 'request',
    study: '',
    startDate: '',
    endDate: '',
    status: '',
    page: 1
  };

  const navigate = useNavigate();

  const onChange = ({ target }: { target: any; }) => {
    setState({ ...state, [target.name]: target.value });
  };

  const onKeyUp = ({ key }: { key: any; }) => {
    if (key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setSearchParam({ ...state, page: 1 });
    setSampleRequestSearchState({ ...state, page: 1 });
    setState({ ...state, page: 1 });
  };

  const handleReset = () => {
    setSearchParam(resetState);
    setSampleRequestSearchState(resetState);
    setState(resetState);
  };

  const onChangePagination = (page: number) => {
    setSearchParam({ ...sampleRequestSearchState, page: page + '' });
    setSampleRequestSearchState({ ...sampleRequestSearchState, page: page });
    setState({ ...sampleRequestSearchState, page: page });
  };

  useEffect(() => {
    if (!locationObj.search) {
      setSampleRequestSearchState(resetState);
      setState(resetState);
    }
  }, [locationObj.search]);

  useEffect(() => {
    setSampleRequestSearchState({ ...defaultState });
    setState({ ...defaultState });
  }, [
    defaultState.tab,
    defaultState.study,
    defaultState.startDate,
    defaultState.endDate,
    defaultState.status,
    defaultState.page
  ]);

  if (isLoading) {
    return <></>;
  }

  const { total, requests } = data;

  return <Container>
    <SearchTable>
      <tbody>
        <tr>
          <SearchTH>등록일</SearchTH>
          <SearchTD width="289px" left>
            <InputText type="date" name="startDate" value={state.startDate} onChange={onChange} max="2999-12-31" width="120px" />
            <Space />~<Space />
            <InputText type="date" name="endDate" value={state.endDate} onChange={onChange} max="2999-12-31" width="120px" />
          </SearchTD>
          <SearchTH>상태</SearchTH>
          <SearchTD left>
            <Select name="status" value={state.status} onChange={onChange}>
              <option value="">-</option>
              {sampleRequestTypes.filter(({ code }) => (isProjectSuper === 1 || isSuper === 1) && code != 'TEMP').map(({ code, name }) =>
                <option key={`key-request-type-${code}`} value={code}>{name}</option>
              )}
            </Select>
          </SearchTD>
          <SearchTD float="right">
            <BtnBlue small onClick={handleSearch}>Search</BtnBlue>
            <LeftMargin />
            <BtnSalmon small onClick={handleReset}>Reset</BtnSalmon>
            {isProjectSuper == 0 && isSuper == 0
              && <>
                <LeftMargin />
                <BtnPink small onClick={() => navigate('../request')}>샘플 요청</BtnPink>
              </>
            }
          </SearchTD>
        </tr>
      </tbody>
    </SearchTable>
    <SearchTable width="591px">
      <tbody>
        <tr>
          <SearchTH>과제명</SearchTH>
          <SearchTD left>
            <InputText name="study" value={state.study} onChange={onChange} onKeyUp={onKeyUp} />
          </SearchTD>
        </tr>
      </tbody>
    </SearchTable>
    <SpaceBetween>
      <Title>총 {total}건</Title>
    </SpaceBetween>
    <Table>
      <thead>
        <TR>
          <TH>번호</TH>
          <TH>요청자</TH>
          <TH>등록일</TH>
          <TH width="70%">과제명</TH>
          <TH>상태</TH>
        </TR>
      </thead>
      <tbody>
        {requests.map((request: any, index: number) =>
          <TR key={`key-request-tbody-${index}`}>
            <TD>{total - ((state.page - 1) * display + index)}</TD>
            <TD>{request.admin_name}</TD>
            <TD>{getLocaleDate(request.created_at)}</TD>
            <TD>
              <Link to={`../request/${request.id}`}>
                <Underline>{request.study}</Underline>
              </Link>
            </TD>
            <TD>{sampleRequestTypes.find(({ code }) => code === request.status)?.name}</TD>
          </TR>
        )}
      </tbody>
    </Table>
    <Footer>
      <Pagination total={total} display={display} page={state.page} onChange={onChangePagination} />
    </Footer>
  </Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 10px;
`;

const SearchTable = styled.table<{
  width?: string;
}>`
  width: ${({ width }) => width ?? '100%'};
  border-collapse: collapse;
`;

const SearchTH = styled.th`
  font-style: Arial;
  text-align: center;
  color: #0043b7;
  height: 40px;
  font-size: 14px;
  width: 100px;
`;

const SearchTD = styled.td<{
  float?: string;
  width?: string;
  left?: boolean;
}>`
  font-style: Arial;
  font-weight: 400;
  background: white;
  font-size: 12px;
  height: 40px;
  float: ${({ float }) => float};
  width: ${({ width }) => width};
  text-align: ${({ left = false }) => (left ? 'left' : 'center')};
`;

const InputText = styled.input<{
  width?: string;
}>`
  padding: 10px;
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  height: 30px;
  width: ${({ width }) => width ?? '100%'};
`;

const Select = styled.select`
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  height: 30px;
  width: 100px;
`;

const Space = styled.span`
  margin-left: 10px;
`;

const LeftMargin = styled.span`
  margin-left: 10px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  flex: 1;
  margin-left: 10px;
`;

const TH = styled.th<{
  width?: string;
}>`
  text-align: center;
  color: #989899;
  background: #fafafc;
  border-top: 1px solid #000;
  height: 45px;
  font-size: 14px;
  width: ${({ width }) => width};
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const Footer = styled.footer`
  margin: 30px auto;
  display: flex;
  justify-content: center;
`;

export default SampleRequestList;
