import getPeriod from "./common/dateCalculator";

const periodOfUse10 = {
  id: 26,
  name: "period of use (10)",
  datas: [
    "start_date_1",
    "end_date_1",
    "start_date_2",
    "end_date_2",
    "start_date_3",
    "end_date_3",
    "start_date_4",
    "end_date_4",
    "start_date_5",
    "end_date_5",
    "start_date_6",
    "end_date_6",
    "start_date_7",
    "end_date_7",
    "start_date_8",
    "end_date_8",
    "start_date_9",
    "end_date_9",
    "start_date_10",
    "end_date_10",
  ],
  formula: ({
    start_date_1,
    start_date_2,
    start_date_3,
    start_date_4,
    start_date_5,
    start_date_6,
    start_date_7,
    start_date_8,
    start_date_9,
    start_date_10,
    end_date_1,
    end_date_2,
    end_date_3,
    end_date_4,
    end_date_5,
    end_date_6,
    end_date_7,
    end_date_8,
    end_date_9,
    end_date_10,
  }: any) => {
    return (
      (getPeriod(start_date_1, end_date_1) || 0) +
      (getPeriod(start_date_2, end_date_2) || 0) +
      (getPeriod(start_date_3, end_date_3) || 0) +
      (getPeriod(start_date_4, end_date_4) || 0) +
      (getPeriod(start_date_5, end_date_5) || 0) +
      (getPeriod(start_date_6, end_date_6) || 0) +
      (getPeriod(start_date_7, end_date_7) || 0) +
      (getPeriod(start_date_8, end_date_8) || 0) +
      (getPeriod(start_date_9, end_date_9) || 0) +
      (getPeriod(start_date_10, end_date_10) || 0)
    );
  },
  help: `총 약제 사용일`,
};

export default periodOfUse10;
