import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";

import { getPatients } from "apis/patient";
import { getTemplateDefaults } from "apis/templateDefault";
import { getMyInfo } from "apis/user";
import { createProjectExcel } from "apis/project";

import axios from "axios";
import { read, utils } from 'xlsx';
import { saveAs } from 'file-saver';

import styled from "styled-components";
import { Select } from "components/atoms/input";
import { BtnBlue, BtnGray } from "components/atoms/button";

import TemplateDefaultType from "types/templateDefault";

// CSV 기능 추가 순서
//
// 1) npm install xlsx file-saver @types/file-saver
// 2) 생성된 aws s3에서 excel 파일 read
// 3) 읽어온 excel 을 csv 로 변경
// 4) '생성' '다운로드' 'CSV다운로드' 
//
const Reporting = () => {
  const { projectId = "" } = useParams();

  const btnEl = useRef<HTMLAnchorElement>(null);
  const [templateDefaultId, setTemplateDefaultId] = useState(0);
  const [type, setType] = useState("All");
  const [link, setLink] = useState("");
  const [isDone, setIsDone] = useState(false);

  const { data: patients, isLoading: isPatientsLoading } = useQuery(
    ["getPatients", { projectId }],
    () => getPatients({ projectId, searchCondition: {} })
  );

  const { data: templateDefaults, isLoading: isTemplateDefaultsLoading } =
    useQuery<TemplateDefaultType[]>(
      ["getTemplateDefaults", { projectId }],
      () => getTemplateDefaults({ projectId })
    );

  const { data: myInfo, isLoading: isLoadingMyInfo } = useQuery(
    ["getMyInfo"],
    getMyInfo
  );

  const hospitalId = myInfo.hospital_id;

  const mutation = useMutation(
    () => createProjectExcel({ projectId, templateDefaultId, type, hospitalId }),
    {
      onSuccess: (data) => {
        setLink(data);
      },
      onError: () => {
        alert("생성 실패");
      },
    }
  );

  const intervalId = useRef<string | NodeJS.Timer>("");
  useEffect(() => {
    if (link) {
      intervalId.current = setInterval(() => {
        checkDownloadAvailable();
      }, 2000);
      return () => {
        clearInterval(intervalId.current);
      };
    }
  }, [link]);

  const checkDownloadAvailable = async () => {
    try {
      await axios.get(link);
      alert("생성 완료");
      setIsDone(true);
    } catch (e) { }
  };

  useEffect(() => {
    if (isDone) {
      btnEl.current?.click();
      clearInterval(intervalId.current);
    }
  }, [isDone]);

  const isLoading = isPatientsLoading || isTemplateDefaultsLoading || isLoadingMyInfo;
  if (isLoading) return null;

  const handleDownload = () => {
    if (!window.confirm("파일을 생성 하시겠습니까?")) return;
    mutation.mutate();
    setLink("");
    setIsDone(false);
  };

  const excelToCsv = async () => {
    try {
      if (link) {
        // link : https://.u...aws..s3(projectId)제목.xlsx
        // 엑셀 읽어오기
        const response = await fetch(link);

        // CSV 저장
        // 파일을 읽어온다. 
        const blob = await response.blob();
        const reader = new FileReader();

        // 엑셀을 읽어오는데  binary 데이터를 parse해서 엑셀을 생성하고 한줄씩 읽어와서 실제데이터를  얻는다. 
        reader.onload = (e) => {
          const fileData = e.target?.result;
          const workbook = read(fileData, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const csv = utils.sheet_to_csv(worksheet, { FS: ',', RS: '\n' }); // FS 가 콤마 RS가 한줄로 해서  CSV로 변환 

          // csv 파일명 수정 : 앞단에 https//...아마존 영제거 
          // 링크 자체가 이미 엑셀형식이라 CSV로 변환이 되는데 정말 CSV 파일 형식으로 변환이 되어있다. 
          const index = link.indexOf(projectId + "");
          const filename = link.substring(index).replaceAll(".xlsx", ".csv");
          // Unit8Array ... : 한글 오류 수정 옵션
          saveAs(new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], {
            type: 'text/csv;charset=utf-8'
          }), filename);
        };
        reader.readAsBinaryString(blob);
      }

    } catch (error) {
      console.log('Error convert csv', error);
    }
  }
  return (
    <>
      <h3>리포트 출력</h3>총 환자 수 : {patients.length}
      <Main>
        {projectId === "675" &&
          <>
            타입 선택
            <SelectTypeWrapper>
              <Select onChange={(e) => setType(e.target.value)}>
                <option value={"All"}>전체</option>
                <option value={"A"}>전향군</option>
                <option value={"B"}>후향군</option>
              </Select>
            </SelectTypeWrapper>
          </>
        }
        템플릿 선택
        <SelectWrapper>
          <Select onChange={(e) => setTemplateDefaultId(+e.target.value)}>
            <option value={0}>전체</option>
            {templateDefaults?.map((t) => (
              <option key={t.id} value={t.id}>
                {t.name}
              </option>
            ))}
          </Select>
        </SelectWrapper>
        {!mutation.isLoading && (
          <BtnBlue onClick={handleDownload}>생성하기</BtnBlue>
        )}
        {link && (
          <>
            <a href={link} ref={btnEl} download target="_self">
              {isDone ? (
                <BtnBlue>다운로드</BtnBlue>
              ) : (
                <BtnGray disabled>생성 중</BtnGray>
              )}
            </a>

            {projectId === "674" &&
              <a onClick={excelToCsv} download target="_self">
                {
                  isDone && (
                    <BtnBlue > CSV 다운로드</BtnBlue>
                  )
                }
              </a>
            }
          </>
        )}
      </Main>
      {projectId === "674" &&
        <h5>
          데이터가 많을 경우 5분이상 걸릴 수 있습니다. 페이지를 이동하면 다운로드
          받을 수 없습니다. 팝업 제한을 해제하고 다운로드 해주세요.<br />
          생성하기 버튼을 누르면 엑셀과 CSV를 동시에 다운로드 받을 수 있습니다.
        </h5>}
      {projectId !== "674" &&
        <h5>
          데이터가 많을 경우 5분이상 걸릴 수 있습니다. 페이지를 이동하면 다운로드
          받을 수 없습니다. 팝업 제한을 해제하고 다운로드 해주세요.<br />
        </h5>}
    </>
  );
};

const Main = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`;
const SelectWrapper = styled.div`
  width: 300px;
`;
const SelectTypeWrapper = styled.div`
  width: 150px;
`;

export default Reporting;
