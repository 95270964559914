import { useParams } from "react-router";
import { useQuery } from "react-query";
import { useState } from "react";
import styled from "styled-components";

import { BtnPink } from "components/atoms/button";
import PatientEdit from "components/templates/PatientInfo/Edit";
import PatientView from "components/templates/PatientInfo/View";
import { getPatient } from "apis/patient";

const PatientInfo = () => {
  const { patientId = "" } = useParams();
  const { isLoading, data } = useQuery(["getPatient", patientId], () =>
    getPatient(patientId)
  );
  const [isEdit, setIsEdit] = useState(false);
  if (isLoading) return <></>;
  return (
    <Container>
      {isEdit ? (
        <PatientEdit data={data} onClose={() => setIsEdit(false)} />
      ) : (
        <PatientView data={data} />
      )}
      <ButtonWrapper>
        {!isEdit && (
          <BtnPink onClick={() => setIsEdit(true)} large>
            수정하기
          </BtnPink>
        )}
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 30px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export default PatientInfo;
