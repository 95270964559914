import moment from "moment";

export const getLocaleDate = (time: string) => {
  const date = moment(time);
  return date.isValid() ? date.format("YYYY-MM-DD") : "";
};

export const getLocaleDateTime = (time: string) => {
  const date = moment(time);
  return date.isValid() ? date.format("YYYY-MM-DD  HH:mm:ss") : "";
};