import { ReactNode, useEffect, useRef, useState, FormEvent } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  useNavigate,
  useParams,
  Link,
  Outlet,
  useLocation,
} from "react-router-dom";

import { getSections, getSection } from "apis/section";
import { putValues } from "apis/values";
import useDisableScroll from "hooks/useDisableScroll";

import ItemForm from "components/organisms/item/Form";
// import { Backdrop, ModalContainer } from "components/atoms/modal";
import { SpaceBetween, TemplateHeader } from "components/atoms/common";
import { BtnBlack, BtnGray, BtnWhite, BtnPink } from "components/atoms/button";
import { getTemplate } from "apis/template";
import { getLocaleDate } from "utils/time";

import { createQuerys } from "apis/query";

import styled from "styled-components";
import InputItem from "components/molecules/InputItem";
import { Items, Value, Required } from "components/atoms/item";
// import { Item, Items, Name, Value, Required } from "components/atoms/item";
import { Input, Textarea } from "components/atoms/input";
// import { Input, Select, Textarea } from "components/atoms/input";

import { log } from "console";
import BoardForm from "./BoardForm";
import BoardDetail from "./BoardDetail";
import { getTokenPayload } from "utils/user";
import { getBoard } from "apis/board";

const BoardWrap = () => {
  const { isSuper, isProjectSuper, adminId } = getTokenPayload();
  const queryClient = useQueryClient();
  const wrapperEl = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location:", location);
  const {
    projectId = "",
    templateId = "",
    sectionId = "",
    patientId = "",
    boardId = "",
  } = useParams();
  console.log("BoardWrap boardId:", boardId);

  const { isLoading, data } = useQuery(["getBoard", boardId], () =>
    getBoard(boardId)
  );
  console.log("BoardWrap data:", data);

  const queryData = data === "" || boardId === "" ? undefined : data;

  const newOrEdit = () => {
    if (
      location.pathname.includes("new") ||
      location.pathname.includes("edit")
    ) {
      return true;
    }
  };

  if (isLoading) return <></>;

  return location.pathname.includes("new") ||
    location.pathname.includes("edit") ? (
    <BoardForm data={queryData} />
  ) : (
    <BoardDetail data={queryData} />
  );
};

export const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const ModalContainer = styled.div<{ large?: boolean }>`
  background: #fff;
  padding: 30px 40px;
  width: 90%;
  max-height: 95vh;
  max-width: ${({ large }) => !large && "750px"};
  overflow: auto;
`;

const Layout = styled.div``;

const Container = styled.div`
  // position: fixed;
  top: 0;
  left: 0;
  flex-shrink: 0;
  width: 260px;
  // height: 100vh;
  // overflow: auto;
  display: flex;
  background: #fff;
  flex-direction: column;
  border-right: 1px solid #e4e6e6;
`;

const MainItem = styled.div<{ top?: boolean }>`
  padding: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid #e4e6e6;
  border-top: ${({ top }) => top && "1px solid #e4e6e6"};
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Main = styled.main`
  margin-left: 260px;
  // @media (max-width: 1315px) {
  //   margin-left: 0px;
  // }
`;

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 7px;
  }
`;

export const Item = styled.div`
  display: flex;
  width: ${({ size = 1 }: { size?: number }) => `${100 / size}%`};
  border-top: 1px solid #dfe0e0;
  min-height: 60px;
`;

export const ValueDown = styled.div<{
  borderLeft?: boolean;
}>`
  border-left: ${({ borderLeft }) => borderLeft && "1px solid #dfe0e0;"};
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  position: relative;
`;

export const Select = styled.select<{
  width?: string;
  disabled?: boolean;
}>`
  width: ${({ width }) => width ?? "70px"};
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  height: 30px;
  background: ${({ disabled }) => disabled && "LightGray"};
`;

export const Name = styled.div<{ division?: boolean; isSection151?: boolean }>`
  background: #fafafc;
  // border-left: 1px solid #dfe0e0;
  width: 140px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

// const Item = styled.div<{ isActive: boolean }>`
//   padding: 20px;
//   font-size: 14px;
//   line-height: 20px;
//   border-bottom: 1px solid #e4e6e6;
//   margin: 0 18px;
//   a {
//     color: ${({ isActive }) => (isActive ? "#000" : "#989899")};
//     font-weight: ${({ isActive }) => (isActive ? "500" : "400")};
//   }
// `;

export const Form = styled.form``;

export default BoardWrap;
