import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Modal from "components/organisms/Modal";
import ModalSection from "./ModalSection";
import { deleteSection } from "apis/section";
import { useMutation, useQueryClient } from "react-query";
import { SectionType } from "types";

const MenuSection = ({ data }: { data: SectionType }) => {
  const queryClient = useQueryClient();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const mutation = useMutation((id: number) => deleteSection(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getSections"]);
      navigate(`/admin/project/${projectId}/section`);
    },
  });
  const handleDelete = async (id: number) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      mutation.mutate(id);
    }
  };
  return (
    <Menu>
      <Modal
        activator={(onOpen) => <Item onClick={onOpen}>섹션 수정</Item>}
        content={(onClose) => <ModalSection data={data} onClose={onClose} />}
      />
      <Item red onClick={() => handleDelete(data.id)}>
        섹션 삭제
      </Item>
    </Menu>
  );
};

const Menu = styled.div`
  width: 128px;
  background: #fff;
  padding: 5px 0;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.1);
`;
const Item = styled.div`
  padding: 12px;
  color: ${({ red }: { red?: boolean }) => red && "#ff0000"};
  font-size: 14px;
  :hover {
    background: #efefef;
  }
`;

export default MenuSection;
