import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";

import { getProject } from "apis/project";
import useInputs from "hooks/useInputs";

import { PageLayout, PageContainer } from "components/atoms/page";

import BtnLeft from "components/molecules/BtnLeft";
import { getMyInfo } from "apis/user";
import { useEffect, useState } from "react";
import { TemplateHeader, SpaceBetween } from "components/atoms/common";
import { Items, Item, Name, Required } from "components/atoms/item";
import { Input, Textarea } from "components/atoms/input";
import File from "components/molecules/InputItem/Attachment";
import { ChangeEvent, useRef, FormEvent } from "react";
import { BtnBlue, BtnSalmon } from "components/atoms/button";
import { getTokenPayload } from "utils/user";
import { NoticeType } from "types";
import { createNotice, deleteNotice, editNotice } from "apis/notice";
import { getLocaleDateTime } from "utils/time";
import { removeAttachment } from "apis/upload";

const NoticeDetail = ({ data }: { data: NoticeType }) => {
  const { isSuper, isProjectSuper, adminId } = getTokenPayload();
  console.log("isProjectSuper:", isProjectSuper);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId = "", noticeId = "" } = useParams();
  console.log("noticeId:", noticeId);

  const defaultState = data ?? {
    admin_id: adminId,
    title: "",
    content: "",
    important: 0,
    attachments: [],
  };

  const [state, setState] = useState(defaultState);

  console.log("state: ", state);

  const [content, setContent] = useState("");

  const contentInput = useRef<any>();

  const { isLoading: isLoadingMyInfo, data: myInfo } = useQuery(
    ["getMyInfo"],
    getMyInfo
  );

  const mutation = useMutation((id: number) => deleteNotice(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getNotices"]);
    },
  });

  const mutationDelete = useMutation(
    async (url: any) => removeAttachment(url),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getNotices"]);
      },
      onError: (err) => {
        alert("삭제에 실패했습니다");
      },
    }
  );

  const handleDelete = (e: any, id: number) => {
    if (!window.confirm("삭제하시겠습니까?")) return;

    e.preventDefault();

    if (mutationDelete.isLoading) return;

    data.attachments?.map((file: any) => {
      mutationDelete.mutate(file.file_url);
    });

    mutation.mutate(id);

    window.location.replace("/notices");
  };

  const onChangeInputText = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const showEditDeleteBtn = () => {
    if (data.admin_id === adminId || adminId === 564 || adminId === 392) {
      return (
        <SpaceBetween>
          <div></div>
          <div>
            <Link to={`/notices/edit/${noticeId}`}>
              <BtnBlue style={{ marginRight: "10px" }}>수정</BtnBlue>
            </Link>
            <BtnSalmon onClick={(e) => handleDelete(e, parseInt(noticeId))}>
              삭제
            </BtnSalmon>
          </div>
        </SpaceBetween>
      );
    }
  };

  if (isLoadingMyInfo) return null;

  return (
    <PageLayout>
      <Title>
        <Link to={`/notices`}>
          <BtnLeft />
        </Link>
        돌아가기
      </Title>
      <PageContainer>
        <TemplateHeader>공지사항</TemplateHeader>
        <Items>
          <Item size={1}>
            <Name>제목</Name>
            <Value>{state.title}</Value>
          </Item>
          <Item size={2}>
            <Name>작성자</Name>
            <Value>{data.name}</Value>
          </Item>
          <Item size={2}>
            <Name>작성일</Name>
            <Value>{getLocaleDateTime(data.created_at)}</Value>
          </Item>
          <Item size={1}>
            <Name>내용</Name>
            <Value>
              {/* {state.content} */}
              <div
                dangerouslySetInnerHTML={{ __html: state.content }}
                style={{ width: "100%", minHeight: "300px" }}
              ></div>
            </Value>
          </Item>
          <Item size={1}>
            <Name>첨부파일</Name>
            <Value2>
              <File
                state={state}
                setState={setState}
                isCurrentUserAuthor={false}
              />
            </Value2>
          </Item>
        </Items>
        {showEditDeleteBtn()}
      </PageContainer>
    </PageLayout>
  );
};

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  align-items: center;
  flex: 1;
  svg {
    margin-right: 10px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #989899;
    margin-left: 10px;
  }
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  flex: 1;
  font-size: 14px;
  border-left: 1px solid #dfe0e0;
  position: relative;
  white-space: pre-wrap;
`;

const Value2 = styled.div<{ isSection151?: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  flex: 1;
  font-size: 14px;
  border-left: 1px solid #dfe0e0;
  position: relative;
`;

export default NoticeDetail;
