import { FormEvent, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router";

import { createHospital, editHospital } from "apis/hospital";
import HospitalType from "types/hospital";

import { BtnPink, BtnWhite } from "components/atoms/button";
import { SpaceBetween, TemplateHeader } from "components/atoms/common";
import { Input } from "components/atoms/input";
import { Item, Items, Name, Value } from "components/atoms/item";
import { ModalContainer } from "components/atoms/modal";

const ModalHospital = ({
  onClose,
  data,
}: {
  onClose: () => void;
  data?: HospitalType;
}) => {
  const queryClient = useQueryClient();
  const { projectId = "" } = useParams();
  const [name, setName] = useState(data?.name || "");
  const [code, setCode] = useState(data?.code || "");

  const action = data
    ? () => editHospital({ id: data.id, name, code })
    : () => createHospital({ projectId, name, code });
  const mutation = useMutation(action, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getHospitals"]);
      onClose();
    },
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (mutation.isLoading) return;
    if (!window.confirm("저장하시겠습니까?")) return;
    mutation.mutate();
  };

  return (
    <ModalContainer>
      <form onSubmit={handleSubmit}>
        <TemplateHeader>병원 {data ? "수정" : "추가"}</TemplateHeader>
        <Items>
          <Item>
            <Name>이름</Name>
            <Value>
              <Input
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
                middle
              />
            </Value>
          </Item>
          <Item>
            <Name>고유번호</Name>
            <Value>
              <Input
                onChange={(e) => setCode(e.target.value)}
                value={code}
                required
                small
              />
            </Value>
          </Item>
        </Items>
        <SpaceBetween>
          <BtnWhite onClick={onClose}>닫기</BtnWhite>
          <BtnPink type="submit">저장</BtnPink>
        </SpaceBetween>
      </form>
    </ModalContainer>
  );
};

export default ModalHospital;
